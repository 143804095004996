import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import { useParams, Params } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { ButtonFABMenu, TextField, Switch } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';
import UnidadeMedidaAPI from '../resources/unidademedida';

/**
 * Tipo dos valores do formik
 */
export type EditarUnidadeMedidaFormikValuesType = {
  idUnidadeMedida?: string;
  dsUnidadeMedida: string;
  sgUnidadeMedida: string;
  stUnidadeMedida: SituacaoEnum;
};

export type EditarUnidadeMedidaPropType = {};

/**
 * View de edição de unidade de medida
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param {EditarUnidadeMedidaPropType} props
 */
function EditarUnidadesMedida(props: EditarUnidadeMedidaPropType): JSX.Element {
  const classes = useStyles();
  const history = useNavigate();
  const { id }: Readonly<Params<string>> = useParams();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

  const api = new UnidadeMedidaAPI();

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] =
    useState<EditarUnidadeMedidaFormikValuesType>({
      dsUnidadeMedida: '',
      sgUnidadeMedida: '',
      stUnidadeMedida: 1
    } as EditarUnidadeMedidaFormikValuesType);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, dirty } =
    useFormik<EditarUnidadeMedidaFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: true,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        stUnidadeMedida: Yup.boolean().default(true).required('Campo obrigatório'),
        dsUnidadeMedida: Yup.string().required('Campo obrigatório'),
        sgUnidadeMedida: Yup.string().required('Campo obrigatório'),
      }),
      onSubmit: handleSubmitFormik,
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(id ? 'Edição de Unidade de Medida' : 'Cadastro de Unidade de Medida');

    if (id) {
      setEnableReinitialize(true);
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api.findById(id).then(res => {
      setInitialValues(res.data);
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  useVerificaDados({
    funcaoPrincipalProps: { dirty },
    handleSubmit,
    ignoreTabChange: true
  });

  return (
    <main style={{ height: '100%' }}>
      <section id='titulo-pagina'>
        <div className={classes.titleContainer}>Unidade de Medida</div>
      </section>

      <section id='formulario'>
        <Grid container className={classes.content}>
          <Grid item xs={6} className={classes.gridCell}>
            <TextField
              label='Descrição'
              name='dsUnidadeMedida'
              inputProps={{ maxLength: 999 }}
              value={values.dsUnidadeMedida}
              error={touched.dsUnidadeMedida && errors.dsUnidadeMedida !== undefined}
              helperText={touched.dsUnidadeMedida && errors.dsUnidadeMedida !== undefined ? `${errors.dsUnidadeMedida}` : ''}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          </Grid>

          <Grid item xs={2} className={classes.gridCell}>
            <TextField
              label='Sigla'
              name='sgUnidadeMedida'
              inputProps={{ maxLength: 10 }}
              value={values.sgUnidadeMedida}
              error={touched.sgUnidadeMedida && errors.sgUnidadeMedida !== undefined}
              helperText={touched.sgUnidadeMedida && errors.sgUnidadeMedida !== undefined ? `${errors.sgUnidadeMedida}` : ''}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          </Grid>

          <Grid item className={classes.switchContainer}>
            <Switch
              label={'Ativo'}
              checked={values.stUnidadeMedida ? true : false}
              defaultChecked
              onBlur={handleBlur}
              onChange={() => setFieldValue('stUnidadeMedida', values.stUnidadeMedida ? 0 : 1)}
            />
          </Grid>
        </Grid>
      </section>
      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
        }}
        secondaryAction={{
          onClick: () => history('/cadastros/unidade-medida'),
          iconProps: { color: 'inherit' },
        }}
      />
    </main>
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarUnidadeMedidaFormikValuesType} values - Valores do submit
   */
  async function handleSubmitFormik(values: EditarUnidadeMedidaFormikValuesType): Promise<void> {
    LoadingSwal({ text: 'Carregando' });
    let error = false;

    if (!id) {
      await api.save(values).catch(() => error = true);
    } else {
      await api.update(values).catch(() => error = true);
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success',
      });

      history('/cadastros/unidade-medida');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error',
      });
    }
  }
}

export default EditarUnidadesMedida;
