/**
 * Enum de tipos de usuário
 *
 * @author davi takayama
 */
enum TipoUsuarioEnum {
  REPRESENTANTE = 0,
  GERENTE = 1,
  TECNICO = 2,
  PRODUTOR = 3,
}

export default TipoUsuarioEnum;
