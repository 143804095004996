import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Add } from '@mui/icons-material';
import { useStyles } from 'root-views/app.styles';
import TipoEtapaEnum from 'root-enumerations/tipo-etapa-enum';
import { FormFieldTypeEnum } from 'root-components/form/form';
import { Grid, IconButton, Tooltip, useTheme } from '@mui/material';
import { Modal, useVerificaDados, Form, Swal } from 'root-components';
import { AutoComplete, DataTable, GroupButtonGrid, Button } from '@bubotech/sumora-react-components';

// Models
import Etapa from '../../produto/model/etapa';
import Produto from '../../produto/model/produto';
import ItemComposicao from '../models/itemcomposicao';
import EtapaFichaTecnica from '../models/etapafichatecnica';

// APIs
import EtapaAPI from 'root-cadastros/etapa/resource/etapa';
import ProdutoAPI from 'root-cadastros/produto/resource/produto';
import UnidadeMedidaAPI from 'root-cadastros/unidademedida/resources/unidademedida';

interface EditarComposicaoFichaTecnicaPropType {
  initialValues?: EtapaFichaTecnica;
  etapas: EtapaFichaTecnica[];
  handleCancel: () => void;
  onSubmit: (values: EtapaFichaTecnica) => void;
}

/**
 * View de edição de uma composição
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {EditarComposicaoFichaTecnicaPropType} props
 */
function EditarComposicaoFichaTecnica(props: EditarComposicaoFichaTecnicaPropType): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const buttonSaveRef = useRef<HTMLButtonElement>(null);
  const { initialValues, etapas, handleCancel, onSubmit } = props;

  const etapaApi = new EtapaAPI();
  const produtoApi = new ProdutoAPI();
  const unidadeMedidaApi = new UnidadeMedidaAPI();

  const [key, setKey] = useState<number>(0);
  const [etapaPredecessora, setEtapaPredecessora] = useState<EtapaFichaTecnica>();
  const [selectedComposicao, setSelectedComposicao] = useState<ItemComposicao>();
  const [showDeleteComposicao, setShowDeleteComposicao] = useState<boolean>(false);
  const [showDeletePredecessora, setShowDeletePredecessora] = useState<boolean>(false);
  const [initialValuesComposicao, setInitialValuesComposicao] = useState<ItemComposicao>();
  const [selectedPredecessora, setSelectedPredecessora] = useState<Etapa | EtapaFichaTecnica>();

  const form = useFormik<EtapaFichaTecnica>({
    initialValues: initialValues ?? {
      produtoComposicoes: [],
      dsEtapa: '',
      etapa: undefined,
      produtoEtapasPredecessoras: [],
      fichaTecnica: null,
      dsModoPreparo: '',
      nrOrdem: etapas.length + 1,
      nmEtapa: '',
      nrQtdeProdutiva: '',
      stRegistro: 0,
      tpEtapa: !etapas.find(etapa => etapa.tpEtapa === TipoEtapaEnum.INICIAL) ? TipoEtapaEnum.INICIAL : TipoEtapaEnum.SEQUENCIAL,
    } as EtapaFichaTecnica,
    validationSchema: Yup.object({
      etapa: Yup.object().required('Campo obrigatório').nullable(),
      dsEtapa: Yup.string().optional().nullable(),
      tpEtapa: Yup.number().required('Campo obrigatório'),
      dsModoPreparo: Yup.string().required('Campo obrigatório').nullable(),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: () => {
      if (values.tpEtapa !== TipoEtapaEnum.INICIAL && !values.produtoEtapasPredecessoras.length) {
        Swal({
          showConfirmButton: true,
          title: 'Erro',
          text: 'Necessário adicionar uma etapa predecessora',
          icon: 'error',
        })
        return;
      } 
  
      onSubmit(values);
    }
  })

  const { values, dirty, setFieldValue, handleSubmit } = form;

  const formComposicao = useFormik<ItemComposicao>({
    initialValues: initialValuesComposicao ?? {
      nrQuantidade: '',
      produto: undefined,
      unidadeMedida: undefined,
      stRegistro: 0,
      dsProdutoComposicao: '',
    },
    validationSchema: Yup.object({
      dsProdutoComposicao: Yup.string().optional().nullable(),
      nrQuantidade: Yup.string().required('Campo obrigatório'),
      produto: Yup.object().required('Campo obrigatório'),
      unidadeMedida: Yup.object().required('Campo obrigatório')
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: handleSubmitComposicao
  })

  useVerificaDados({ funcaoPrincipalProps: { dirty }, handleSubmit })


  function handleDeletePredecessora() {
    let novaLista: EtapaFichaTecnica[] = [];
    values.produtoEtapasPredecessoras.forEach(etapa => {
      if (JSON.stringify(etapa) !== JSON.stringify(selectedPredecessora)) novaLista.push(etapa as EtapaFichaTecnica);
    })

    setFieldValue('produtoEtapasPredecessoras', novaLista);
    setShowDeletePredecessora(false);
  }

  function handleEditComposicao() {
    if (!selectedComposicao) return;

    setInitialValuesComposicao(selectedComposicao);
  }

  function handleDeleteComposicao() {
    const novaLista: ItemComposicao[] = [];
    values.produtoComposicoes.forEach(composicao => {
      if (JSON.stringify(composicao) !== JSON.stringify(selectedComposicao)) novaLista.push(composicao as ItemComposicao);
    })

    setFieldValue('produtoComposicoes', novaLista);
    setShowDeleteComposicao(false);
  }

  function handleSubmitPredecessora() {
    setFieldValue('produtoEtapasPredecessoras', [...values.produtoEtapasPredecessoras, etapaPredecessora]);
    setEtapaPredecessora(undefined);
    setKey(Math.random());
  }

  function handleSubmitComposicao() {
    let composicao = [...values.produtoComposicoes ?? []];

    if (!initialValuesComposicao) setFieldValue('produtoComposicoes', [...composicao, formComposicao.values])
    else {
      const index = values.produtoComposicoes?.findIndex(composicao => JSON.stringify(composicao) === JSON.stringify(initialValuesComposicao)) ?? -1;

      if (index > -1) composicao[index] = formComposicao.values;
      setFieldValue('produtoComposicoes', composicao);
    }

    setInitialValuesComposicao(undefined);
    formComposicao.resetForm();
  }

  function handleChangeProduto(value: Produto | null) {
    if (!value) {
      formComposicao.setFieldValue('produto', undefined);
      formComposicao.setFieldValue('unidadeMedida', undefined);

      return;
    };

    formComposicao.setFieldValue('produto', value);
    value.unidadeMedidaVenda && formComposicao.setFieldValue('unidadeMedida', value.unidadeMedidaVenda);
  }

  return (
    <section id='editar-composição' className={classes.tabContent} style={{ overflowY: 'auto' }}>
      <Form<EtapaFichaTecnica>
        form={form}
        onCancelEdit={handleCancel}
        saveOnEnter={false}
        fields={[
          {
            fieldName: 'tpEtapa',
            label: 'Tipo de Etapa',
            fieldType: FormFieldTypeEnum.SELECT,
            fieldSize: 2,
            selectProps: {
              getOptionLabel: opt => opt.label,
              getOptionValue: opt => opt.value,
              placeholder: 'Selecione...',
              options: [
                { label: 'Inicial', value: TipoEtapaEnum.INICIAL },
                { label: 'Sequencial', value: TipoEtapaEnum.SEQUENCIAL },
                { label: 'Concorrente', value: TipoEtapaEnum.CONCORRENTE },
                { label: 'Final', value: TipoEtapaEnum.FINAL },
              ],
              disableOptionIf: opt => etapas.find(etapa => etapa.tpEtapa === TipoEtapaEnum.FINAL && etapa.stRegistro !== 2 && opt.value === 3) !== undefined
            }
          },
          {
            fieldName: 'etapa',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            label: 'Etapa do Processo',
            fieldSize: 3,
            autoCompleteProps: {
              genericApi: etapaApi,
              searchField: 'nmEtapa',
              orderField: 'nmEtapa',
              staticSearchParams: ',stEtapa:1',
              getLabel: opt => opt.nmEtapa,
              getValue: opt => opt.idEtapa,
            }
          },
          {
            fieldName: 'dsEtapa',
            label: 'Descrição',
            fieldType: FormFieldTypeEnum.TEXTFIELD,
            fieldSize: 7
          },
          {
            fieldName: 'dsModoPreparo',
            label: 'Modo de Preparo',
            fieldType: FormFieldTypeEnum.MULTILINE,
            fieldSize: 12
          },
        ]}
      />

      <Grid className={classes.container}>
        Composição
      </Grid>
      <Form<ItemComposicao>
        form={formComposicao}
        showAdd
        saveOnEnter
        showEdit={Boolean(initialValuesComposicao)}
        onCancelEdit={() => setInitialValuesComposicao(undefined)}
        fields={[
          {
            fieldName: 'produto',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            label: 'Cód. Produto',
            fieldSize: 2,
            autoCompleteProps: {
              getLabel: opt => opt.cdProduto,
              getValue: opt => opt.idProduto,
              genericApi: produtoApi,
              searchField: 'cdProduto',
              orderField: 'cdProduto',
              staticSearchParams: ',stProduto:1,tpItem:1,tpItem:2',
              PaperSuggestionsProps: { className: 'suggestions-fieldSize-1' },
              onChangeValue: handleChangeProduto,
            }
          },
          {
            fieldName: 'produto',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            label: 'Produto',
            fieldSize: 6,
            autoCompleteProps: {
              getLabel: opt => opt.nmProduto,
              getValue: opt => opt.idProduto,
              genericApi: produtoApi,
              searchField: 'nmProduto',
              orderField: 'nmProduto',
              staticSearchParams: ',stProduto:1,tpItem:1,tpItem:2',
              PaperSuggestionsProps: { className: 'suggestions-fieldSize-3' },
              onChangeValue: handleChangeProduto,
            }
          },
          {
            fieldName: 'nrQuantidade',
            fieldType: FormFieldTypeEnum.MASKED_TEXTFIELD,
            label: 'Quantidade',
            maskedTextFieldProps: {
              decimalScale: 8
            },
            fieldSize: 2,
          },
          {
            fieldName: 'unidadeMedida',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            label: 'Unidade de Medida',
            fieldSize: 2,
            autoCompleteProps: {
              getLabel: opt => opt.sgUnidadeMedida,
              getValue: opt => opt.idUnidadeMedida,
              genericApi: unidadeMedidaApi,
              searchField: 'sgUnidadeMedida',
              orderField: 'sgUnidadeMedida',
              staticSearchParams: ',stUnidadeMedida:1',
              PaperSuggestionsProps: { className: 'suggestions-fieldSize-1' }
            }
          },
          {
            fieldName: 'dsProdutoComposicao',
            fieldType: FormFieldTypeEnum.TEXTFIELD,
            label: 'Descrição',
            fieldSize: 11
          }
        ]}
      />

      <section>
        <GroupButtonGrid 
          showAdd={false}
          buttonDeleteProps={{ disabled: !selectedComposicao, disabledColor: theme.palette.grey['100'] }}
          buttonEditProps={{ disabled: !selectedComposicao, backgroundColor: theme.palette.primary.main, disabledColor: theme.palette.grey['100'] }}
          onClickEdit={handleEditComposicao}
          onClickDelete={() => setShowDeleteComposicao(true)}
        />
        <div className={`${classes.containerDataTable} tabela sem-bordas sem-border-bottom`}>
          <DataTable<ItemComposicao> 
            columns={[
              {
                field: 'produto.cdProduto',
                headerName: 'Cód. Produto',
                col: 2
              },
              {
                field: 'produto.nmProduto',
                headerName: 'Produto',
                col: 4
              },
              {
                field: 'nrQuantidade',
                headerName: 'Qtde.',
                col: 1,
                valueGetter: item => item.data?.nrQuantidade ?? '-'
              },
              {
                field: 'unidadeMedida.sgUnidadeMedida',
                headerName: 'Un. Medida',
                col: 1
              },
              {
                field: 'dsProdutoComposicao',
                headerName: 'Descrição',
                col: 4
              },
            ]}
            data={values.produtoComposicoes ?? []}
            onSelectRow={setSelectedComposicao}
            showPagination={false}
          />
        </div>
      </section>

      <Grid className={classes.container}>
        Predecessoras
      </Grid>
      <Grid container>
        <Grid item xs={5} className={classes.gridCell}>
          <AutoComplete<EtapaFichaTecnica>
            key={key}
            label='Etapa Anterior'
            name='etapaAnterior'
            disabled={form.values.tpEtapa === TipoEtapaEnum.INICIAL}
            value={etapaPredecessora}
            getLabel={opt => opt.etapa?.nmEtapa ?? ''}
            getValue={opt => opt.idProdutoEtapa}
            suggestions={form.values.tpEtapa === TipoEtapaEnum.INICIAL ? [] : etapas.filter(etapa => JSON.stringify(etapa) !== JSON.stringify(values) && etapa.tpEtapa !== TipoEtapaEnum.FINAL) ?? []}
            onChangeValue={e => setEtapaPredecessora(e ?? undefined)}
          />
        </Grid>
        <Grid item xs={1} className={classes.switchContainer}>
          <Tooltip title='Adicionar'>
            <span>
              <IconButton disabled={Boolean(!etapaPredecessora || (values.produtoEtapasPredecessoras.findIndex(etapa => etapa.idProdutoEtapa === etapaPredecessora.idProdutoEtapa && etapa.stRegistro !== 2) !== -1))} color='primary' type='submit' role='submit' onClick={handleSubmitPredecessora}>
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <section>
        <GroupButtonGrid 
          showAdd={false}
          showEdit={false}
          buttonDeleteProps={{ disabled: !selectedPredecessora, disabledColor: theme.palette.grey['100'] }}
          onClickDelete={() => setShowDeletePredecessora(true)}
        />

        <div className={`${classes.smallDataTable} tabela predecessoras sem-bordas`}>
          <DataTable<Etapa | EtapaFichaTecnica>
            columns={[
              {
                field: 'etapa.nmEtapa',
                headerName: 'Etapa',
                col: 4
              },
              {
                field: 'dsEtapa',
                headerName: 'Descrição',
                col: 8,
              },
            ]}
            data={values.produtoEtapasPredecessoras}
            onSelectRow={setSelectedPredecessora}
            showPagination={false}
          />
        </div>
      </section>

      <section className={classes.buttonContainer}>
        <Button style={{ margin: '0px 10px', background: theme.palette.grey['300'] }} onClick={handleCancel}>CANCELAR</Button>
        <Button ref={buttonSaveRef} color='primary' style={{ marginLeft: '10px' }} onClick={() => handleSubmit()}>SALVAR</Button>
      </section>

      <Modal 
        open={showDeleteComposicao || showDeletePredecessora}
        title='Clicando em EXCLUIR você estará removendo esse item da lista.'
        message='Tem certeza que deseja realizar esta ação?'
        onFinishLabel='EXCLUIR'
        onCloseLabel='CANCELAR'
        onClose={() => {
          setShowDeleteComposicao(false);
          setShowDeletePredecessora(false);
        }}
        onFinish={() => {
          showDeletePredecessora && handleDeletePredecessora();
          showDeleteComposicao && handleDeleteComposicao();
        }}
      />
    </section>
  );
}

export default EditarComposicaoFichaTecnica;