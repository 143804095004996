import React from 'react';
import { HighlightOff } from '@mui/icons-material';
import { useStyles } from './editabletable.styles';
import { Grid, TextFieldProps } from '@mui/material';
import { SwitchPropTypes } from '@bubotech/sumora-react-components/lib/switch';
import { AutoCompletePropType } from '@bubotech/sumora-react-components/lib/autocomplete';
import { AutoComplete, DatePicker, TextField, MaskedTextField, Switch } from '@bubotech/sumora-react-components';
import { MaskTypeEnum, MaskedTextFieldPropType } from '@bubotech/sumora-react-components/lib/maskedtextfield';

type EditType = "input" | "date" | "autocomplete" | "masked" | "switch";

interface AutoCompleteProp extends Omit<AutoCompletePropType<any>, 'staticSearchParams'> {
  staticSearchParams?: string | ((filter: number) => string);
  key?: number;
}

export interface AutoCompleteSuggestions {
  [key: number]: any[];
}

interface SwitchProp extends SwitchPropTypes {
  value: (rowIndex: number) => boolean;
  onChangeValue: (rowIndex: number) => void;
  xs?: number; 
  header?: string;
}

export interface ColumnDefinition<T> {
  header: string; // título da coluna
  xs: number; // largura da coluna
  editable?: EditType; // tipo de componente de edição
  autocompleteProps?: AutoCompleteProp; // propriedades do autocomplete
  inputProps?: TextFieldProps; // propriedades do campo de texto
  maskedProps?: MaskedTextFieldPropType; // propriedades do campo com máscara (default: money)
  // lista de sugestões para um autocomplete (usar quando as sugestões da coluna dependerem do valor de outra coluna)
  autocompleteSuggestions?: AutoCompleteSuggestions;
  disableDate?: boolean;
  removableColumn?: boolean;
  disabledRow?: (rowIndex: number) => boolean; 
  onRemoveColumn?: (columnIndex: number, columns: ColumnDefinition<T>[]) => void; 
  value: (node: T, rowIndex: number) => any;
  onChangeItem?: (value: any, index: number, item: any) => void;
  onClickRow?: (index: number) => void;
}

interface EditableTablePropType<T> {
  cols: ColumnDefinition<T>[];
  data: T[];
  selectedIndex: number;
  showSwitch?: boolean;
  switchProps?: SwitchProp;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
}

/**
 * Componenente de tabela editável
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {EditableTablePropType} props
 */
function EditableTable<T>(props: EditableTablePropType<T>): JSX.Element {
  const classes = useStyles();
  const { cols, data, selectedIndex, showSwitch, switchProps, setSelectedIndex } = props;

  const removeColumnError = cols.find(column => column.removableColumn && !column.onRemoveColumn)
  if (removeColumnError)
    console.error('Necessário enviar um callback para remover uma coluna', `coluna: ${removeColumnError?.header}`)

  function getRowStyle(index: number) {
    if (index === selectedIndex) return classes.selectedRow;

    if (index % 2 === 0) return classes.evenRow;
    else return classes.oddRow;
  }

  function getStaticParams(filter: number, props?: AutoCompleteProp) {
    if (!props || !props.staticSearchParams) return '';

    if (typeof props.staticSearchParams === 'string') return props.staticSearchParams;
    else return props.staticSearchParams(filter);
  }

  function showEditComponent(
    type: EditType,
    rowIndex: number,
    value?: any,
    item?: any,
    switchProps?: SwitchProp,
    disableRow?: boolean,
    autoCompleteProps?: AutoCompleteProp,
    handleChange?: (value: any, rowIndex: number, item: any) => void,
    autoCompleteSuggestions?: AutoCompleteSuggestions,
    inputProps?: TextFieldProps,
    maskedProps?: MaskedTextFieldPropType,
    disableDate?: boolean,
  ) {
    switch (type) {
      case 'input':
        return (
          <TextField
            variant='standard'
            value={value}
            onChange={e => handleChange && handleChange(e.target.value, rowIndex, item)}
            {...inputProps}
            disabled={disableRow}
          />
        )
      case 'autocomplete':
        return (
          <AutoComplete
            PaperSuggestionsProps={{ className: 'suggestions' }}
            getLabel={autoCompleteProps?.getLabel ?? ''}
            getValue={autoCompleteProps?.getLabel ?? ''}
            onChangeValue={(e) => handleChange && handleChange(e, rowIndex, item)}
            suggestions={autoCompleteSuggestions && autoCompleteSuggestions[rowIndex] ? autoCompleteSuggestions[rowIndex] : []}
            {...autoCompleteProps}
            staticSearchParams={getStaticParams(rowIndex, autoCompleteProps)}
            value={value ?? undefined}
            disabled={disableRow}
          />
        )
      case 'date':
        return (
          <DatePicker
            className={classes.input}
            value={value ?? null}
            onChange={(e: Date | null) => handleChange && handleChange(e, rowIndex, item)}
            disabled={disableDate || disableRow}
            
          />
        )
      case 'masked':
        return (
          <MaskedTextField
            typeMask={MaskTypeEnum.MONEY}
            className={classes.input}
            value={value as string}
            onChange={e => handleChange && handleChange(e.target.value, rowIndex, item)}
            {...maskedProps}
            disabled={disableRow}
          />
        )
      case 'switch':
        return (
          <Switch
            defaultChecked
            checked={switchProps ? switchProps.value(rowIndex) : true}
            onChange={() => {
              switchProps && switchProps.onChangeValue(rowIndex);
            }}
            {...switchProps}
          />
        )
    }
  }
  function calculateWithCol() {
    const totalWidth = cols.length;
    const switchDivWidth = 11 - totalWidth
    return switchDivWidth;
  }
  return (
    <section className={classes.container}>
      <Grid container className={classes.headerRow}>
        {cols.map((column, colIndex) => 
          <Grid item xs={column.xs} className={classes.headerCell}>
            {column.header}
            {column.removableColumn && <HighlightOff className={classes.removeIcon} onClick={() => column.onRemoveColumn && column.onRemoveColumn(colIndex, cols)}/>}
          </Grid>
        )}
        {showSwitch && 
          <Grid item xs={switchProps?.xs} className={classes.headerCellSwitch}>
            {switchProps?.header}
          </Grid>}
      </Grid>
      {data?.map((item, rowIndex) =>
        <Grid container onClick={() => setSelectedIndex(rowIndex)} className={`${classes.tableRow} ${getRowStyle(rowIndex)}`}>
          {cols.map((column) => {
            const { xs, editable, autocompleteProps, autocompleteSuggestions, inputProps, maskedProps, disableDate, value, onChangeItem, onClickRow, disabledRow } = column;
            return (
              <Grid item xs={xs} className={classes.cell} onClick={() => onClickRow && onClickRow(rowIndex)}>
                {!editable ? value(item, rowIndex) :
                  showEditComponent(
                    editable,
                    rowIndex,
                    value(item, rowIndex),
                    item,
                    undefined,
                    disabledRow ? disabledRow(rowIndex) : false,
                    autocompleteProps,
                    onChangeItem,
                    autocompleteSuggestions,
                    inputProps,
                    maskedProps,
                    disableDate,
                  )
                }
              </Grid>
            )
          })}
          {showSwitch && <Grid item xs={switchProps?.xs ? switchProps?.xs : calculateWithCol()} className={classes.cellSwitch}>
            <Grid className={classes.switchContainer}>
              {showEditComponent(
                'switch',
                rowIndex,
                switchProps?.value(rowIndex),
                item,
                switchProps,
              )}
            </Grid>
          </Grid>}
        </Grid>
      )}
    </section>
  )
}

export default EditableTable;