import URL from 'root-resources/URL';
import GenericApi from 'root-resources/generic-api';


/**
 * Classes para buscar informações referentes ao Módulo Fiscal
 *
 * @author davi takayama
 */
export default class FiscalAPI<E> extends GenericApi<E> {
  /**
   * Injeta as configurações da api
   */
  public constructor(value?: string) {
    super({ baseURL: `${URL.FISCALAPI + (value ?? '')}` });
  }
}
