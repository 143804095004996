import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActionTypes from 'root-states/actiontypes/app-layout-action-types';
import RootReducer from 'root-states/root-reducer';
import Empresa from 'root-models/empresa';
import Usuario from 'root-models/configuracoesdeacesso/usuario';


/**
 * Modelo do estado deste Reducer
 */
export interface AppLayoutStateType {
  menuOpen: boolean;
  titleToolbar: string;
  mainLoading: boolean;
  company?: Empresa;
  standardCompany?: Empresa;
  currentUser?: Usuario;
  calculating: boolean;
  filiais: boolean;
}

/**
 * Reducer do Layout do App
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @class AppLayoutReducer
 * @extends {RootReducer<AppLayoutStateType>}
 */
class AppLayoutReducer extends RootReducer<AppLayoutStateType> {
  /**
   * Cria uma instância do Reducer e injeta o estado inicial
   */
  public constructor() {
    super({
      menuOpen: false,
      titleToolbar: '',
      mainLoading: false,
      calculating: false,
      filiais: false
    });
  }

  /**
   * Retorna a função Reducer do Redux
   *
   * @returns {(state: AppLayoutStateType, action: DispatchAction) => AppLayoutStateType}
   */
  public getReducer(): (state: AppLayoutStateType, action: DispatchAction) => AppLayoutStateType {
    return (state: AppLayoutStateType = this.initialState, action: DispatchAction) => {
      switch (action.type) {
        case AppLayoutActionTypes.MENU_IS_OPEN:
          return { ...state, menuOpen: action.payload ?? !state.menuOpen };

        case AppLayoutActionTypes.IS_LOADING:
          return { ...state, mainLoading: action.payload ?? !state.mainLoading };

        case AppLayoutActionTypes.IS_CALCULATING:
          return { ...state, calculating: action.payload ?? !state.calculating };

        case AppLayoutActionTypes.CHANGE_TITLE_TOOLBAR:
          return { ...state, titleToolbar: action.payload };

        case AppLayoutActionTypes.CHANGE_COMPANY:
          return { ...state, company: action.payload };

        case AppLayoutActionTypes.CHANGE_STANDARD_COMPANY:
          return { ...state, standardCompany: action.payload };

        case AppLayoutActionTypes.CHANGE_USUARIO:
          return { ...state, currentUser: action.payload };

        case AppLayoutActionTypes.UPDATE_FILIAIS:
          return { ...state, filiais: action.payload };
        default:
          return state;
      }
    };
  }
}

export default new AppLayoutReducer().getReducer();
