/**
 * Enum de situação de importação
 *
 * @author Felipe Shimada
 */
enum SituacaoImportacaoEnum {
    IMPORTADO,
  
    ATUALIZADO
  }
  
  export default SituacaoImportacaoEnum;
  