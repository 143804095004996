/**
 * Enum de tipos de itens
 *
 * @author davi takayama
 */
 enum TipoItemEnum {
  MERCADORIA_PARA_REVENDA = 0,
  MATERIA_PRIMA = 1,
  EMBALAGEM = 2,
  PRODUTO_EM_PROCESSO = 3,
  PRODUTO_ACABADO = 4,
  SUBPRODUTO = 5,
  PRODUTO_INTERMEDIARIO = 6,
  MATERIAL_DE_USO_E_CONSUMO = 7,
  ATIVO_IMOBILIZADO = 8,
  SERVICOS = 9,
  OUTROS_INSUMOS = 10,
  OUTRAS = 99
}

export default TipoItemEnum;
