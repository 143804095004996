import React, { useState, useRef } from 'react';
import Aro from '../model/aro';
import Swal from 'sweetalert2';
import { Dispatch } from 'redux';
import DiametroAPI from '../resource/aro';
import { MainStateType } from 'root-states';
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import { useStyles } from 'root-views/app.styles';
import { Swal as RootSwal } from 'root-components';
import { buildQuery } from 'root-utils/build-query';
import { useDispatch, useSelector } from 'react-redux';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import CardFilters from 'root-components/cardfilters/card-filters';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import TipoDiametroEnum from 'root-enumerations/tipo-diametro-enum';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import EditableTable from 'root-components/editabletable/editable-table';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

export type AroPropType = {};

/**
 * Tela de listagem de Aro
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @param {AroPropType} props
 */
function Aros(props: AroPropType): JSX.Element {
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const classes = useStyles();

    const api = new DiametroAPI();
    const [pageData, setPageData] = useState<Resposta>();
    const params = useRef<Busca>({ page: 1, orderField: 'tpDiametro', orderType: 'ASC' });
    const [dataFiltered, setDataFiltered] = useState<Aro[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Aro');
        if (ScreensKeysEnum.ARO in filtros) {
            const staticFields = buildQuery(filtros[ScreensKeysEnum.ARO].filters, ['stDiametro', 'tpDiametro'], []);
            params.current = { ...params.current, staticParams: staticFields, page: 1 };
        }
        loadData();
    });

    function getSwitchChecked(rowIndex: number) {
        return Boolean(dataFiltered[rowIndex].stDiametro);
    }

    function handleChangeSwitch(rowIndex: number) {
        LoadingSwal({ text: 'Carregando' });
        appLayoutActions.setLoading(true);
        let newAro = [...dataFiltered];
        const newValue = newAro[rowIndex].stDiametro ? 0 : 1;
        newAro[rowIndex].stDiametro = newValue;
        let error = false;
        api.update(newAro[rowIndex])
            .catch(() => {
                error = true;
                setDataFiltered([]);
                RootSwal({
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Ok',
                    title: 'Ocorreu um erro',
                    text: 'Erro ao editar',
                    icon: 'error',
                });
            })
            .finally(() => {
                appLayoutActions.setLoading(false);
                if (!error) {
                    Swal.close();
                }
            });
    }

    function handleChangeFilters(data: any[]) {
        const staticFields = buildQuery(data, ['stDiametro', 'tpDiametro'], []);
        params.current = { ...params.current, staticParams: staticFields, page: 1 };
        loadData();
    }

    function loadData() {
        appLayoutActions.setLoading(true);
        if (params.current.staticParams === '' || params.current.staticParams === undefined) {
            api.findAllDiametro().then(res => {
                const data = res.data as Aro[];
                const dataSorted = data.sort((a, b) => a.tpDiametro - b.tpDiametro);
                setDataFiltered(dataSorted)
            }).catch(() => {
                setDataFiltered([]);
                RootSwal({
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Ok',
                    title: 'Ocorreu um erro',
                    text: 'Falha ao carregar dados',
                    icon: 'error',
                });
            })
                .finally(() => appLayoutActions.setLoading(false));
        } else {

            api.findByPage(params.current)
                .then(res => {
                    setPageData(res.data);
                    setDataFiltered(res.data.data as Aro[]);
                })
                .catch(() => {
                    setDataFiltered([]);
                    RootSwal({
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Ok',
                        title: 'Ocorreu um erro',
                        text: 'Falha ao carregar dados',
                        icon: 'error',
                    });
                })
                .finally(() => appLayoutActions.setLoading(false));
        }
    }

    function getDiametro(tipo: TipoDiametroEnum) {
        switch (tipo) {
            case TipoDiametroEnum.CORACAO:
                return 'Coração';
            case TipoDiametroEnum.ESTRELA:
                return 'Estrela';
            case TipoDiametroEnum.LUA:
                return 'Lua';
            case TipoDiametroEnum.OVAL:
                return 'Oval';
            case TipoDiametroEnum.QUADRADA:
                return 'Quadrada';
            case TipoDiametroEnum.REDONDA:
                return 'Redonda';
            case TipoDiametroEnum.RETANGULAR:
                return 'Retangular';
            default:
                return 'Valor inválido';
        }
    }

    return (
        <main className={classes.rootListagem}>
            <section id='filtros'>
                <CardFilters
                    screenKey={ScreensKeysEnum.ARO}
                    data={pageData?.data ?? []}
                    onLoadData={loadData}
                    changeFilters={handleChangeFilters}
                    onRefreshFilters={setDataFiltered}
                    filtersDefinitions={[
                        {
                            field: 'nrDiametro',
                            label: 'Diâmetro',
                            type: TipoFiltroEnum.NUMBER,
                        },
                        {
                            field: 'dsDiametro',
                            label: 'Descrição',
                            type: TipoFiltroEnum.STRING,
                        },
                        {
                            field: 'nrFatias',
                            label: 'Qtde. Fatias',
                            type: TipoFiltroEnum.NUMBER,
                        },
                        {
                            field: 'stDiametro',
                            label: 'Status',
                            type: TipoFiltroEnum.ENUM,
                            enumOptions: [
                                {
                                    label: 'Ativo',
                                    value: SituacaoEnum.ATIVO,
                                },
                                {
                                    label: 'Inativo',
                                    value: SituacaoEnum.INATIVO,
                                },
                            ],
                        },
                        {
                            field: 'tpDiametro',
                            label: 'Tipo',
                            type: TipoFiltroEnum.ENUM,
                            enumOptions: [
                                {
                                    label: 'Redonda',
                                    value: TipoDiametroEnum.REDONDA,
                                },
                                {
                                    label: 'Quadrada',
                                    value: TipoDiametroEnum.QUADRADA,
                                },
                                {
                                    label: 'Retangular',
                                    value: TipoDiametroEnum.RETANGULAR,
                                },
                                {
                                    label: 'Coração',
                                    value: TipoDiametroEnum.CORACAO,
                                },
                                {
                                    label: 'Estrela',
                                    value: TipoDiametroEnum.ESTRELA,
                                },
                                {
                                    label: 'Lua',
                                    value: TipoDiametroEnum.LUA,
                                },
                                {
                                    label: 'Oval',
                                    value: TipoDiametroEnum.OVAL,
                                }
                            ],
                        }
                    ]}
                />
            </section>

            <section className={classes.sectionTable}>

                <div className={`${classes.containerDataTable} tabela`} style={{ padding: '25px 21px 5px' }} >
                    <EditableTable<Aro>
                        cols={[
                            {
                                value: (node) => node.nrDiametro,
                                header: 'Diâmetro',
                                xs: 1,
                            },
                            {
                                value: (node) => getDiametro(node.tpDiametro),
                                header: 'Tipo',
                                xs: 1
                            },
                            {
                                value: (node) => node.dsDiametro,
                                header: 'Descrição',
                                xs: 6,
                            },
                            {
                                value: (node) => node.nrFatias,
                                header: 'Qtde. Fatias',
                                xs: 3,
                            },
                        ]}
                        data={dataFiltered}
                        selectedIndex={selectedIndex}
                        showSwitch
                        switchProps={{
                            label: '',
                            value: getSwitchChecked,
                            onChangeValue: handleChangeSwitch,
                            xs: 1,
                            header: 'Situação'
                        }}
                        setSelectedIndex={setSelectedIndex}
                    />
                </div>
            </section>
        </main>
    );
}

export default Aros;
