import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { MainStateType } from 'root-states';
import AlergenicosAPI from '../resource/api';
import Swal from 'root-components/swal/swal';
import Alergenicos from '../model/alergenos';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import { useParams, Params } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { ButtonFABMenu, TextField, Switch } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

export interface EditarAlergenicosFormikValuesType extends Alergenicos { };

export type EditarAlergenicosPropType = {};

/**
 * View de edição Alergênicos/ Alérgenos
 *
 * @author Carlos Bageston <carlos.bageston@kepha.com.br>
 * @param {EditarAlergenicosPropType} props
 */
function EditarAlergenos(props: EditarAlergenicosFormikValuesType): JSX.Element {
    const classes = useStyles();
    const history = useNavigate();
    const api = new AlergenicosAPI();
    const { id }: Readonly<Params<string>> = useParams();
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

    const [enableReinitialize, setEnableReinitialize] = useState(false);
    const [initialValues, setInitialValues] =
        useState<EditarAlergenicosFormikValuesType>({
            dsAlergenico: '',
            stAlergenico: 1
        } as EditarAlergenicosFormikValuesType
        );

    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik<EditarAlergenicosFormikValuesType>({
        validateOnBlur: true,
        enableReinitialize,
        initialValues,
        validationSchema: Yup.object().shape({
            dsAlergenico: Yup.string().required('Campo obrigatório'),
            stAlergenico: Yup.number().required('Campo obrigatório').default(1)
        }),
        onSubmit: handleSubmitFormik,
    });

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Alérgenos');

        if (id) setEnableReinitialize(true);
    })

    //carregando dados por id
    useEffect(() => {
        if (!enableReinitialize) return;
        appLayoutActions.setLoading(true);

        api.findById(id).then(res => {
            setInitialValues(res.data);
            setEnableReinitialize(false);
            appLayoutActions.setLoading(false);
        }).catch(() => {
            Swal({
                showConfirmButton: true,
                title: 'Erro',
                text: 'Alérgenos não encontrado',
                icon: 'error',
            });

            history('/cadastros/alergenos');
        });


        // eslint-disable-next-line
    }, [enableReinitialize])
    return (
        <main style={{ height: '100%' }}>
            <section id='titulo-pagina'>
                <div className={classes.titleContainer}>Alérgenos</div>
            </section>

            <section id='formulario'>
                <Grid container className={classes.content}>
                    <Grid item xs={6} className={classes.gridCell}>
                        <TextField
                            name='dsAlergenico'
                            label='Descrição'
                            inputProps={{ maxLength: 100 }}
                            value={values.dsAlergenico}
                            error={errors.dsAlergenico !== undefined && touched.dsAlergenico !== undefined}
                            helperText={errors.dsAlergenico !== undefined && touched.dsAlergenico !== undefined ? `${errors.dsAlergenico}` : ''}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={1} className={classes.switchContainer}>
                        <Switch
                            label='Ativo'
                            checked={Boolean(values.stAlergenico)}
                            onBlur={handleBlur}
                            onChange={() => {
                                if (values.stAlergenico) setFieldValue('stAlergenico', 0)
                                else setFieldValue('stAlergenico', 1)
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} />
                </Grid>
            </section>

            <ButtonFABMenu
                disabled={isLoading}
                primaryAction={{
                    onClick: (e: any) => handleSubmit(e),
                    iconProps: { color: 'inherit' },
                }}
                secondaryAction={{
                    onClick: () => history('/cadastros/alergenos'),
                    iconProps: { color: 'inherit' },
                }}
            />
        </main>
    )

    /**
       * Manipula o evento de submit do Formik
       *
       * @param {Alergenicos} values - Valores do submit
       * @param {FormikHelpers<Alergenicos>} formikHelpers - Auxiliares
       */
    async function handleSubmitFormik(values: Alergenicos): Promise<void> {
        LoadingSwal({ text: 'Carregando' });
        let metodo = id ? api.update(values) : api.save(values)
        await metodo.then(() => {
            Swal({
                showConfirmButton: true,
                title: 'Sucesso',
                text: id ? 'Editado com sucesso' : 'Cadastrado com sucesso',
                icon: 'success'
            });
            history('/cadastros/alergenos');
        }).catch(() => {
            Swal({
                showConfirmButton: true,
                title: 'Erro',
                text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
                icon: 'error',
            })
        })
    }
}

export default EditarAlergenos