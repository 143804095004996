import React, { useState } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useStyles } from './login.styles';
import Swal from 'root-components/swal/swal';
import { useNavigate } from 'react-router-dom';
//import Admin from 'root-resources/oauth/admin';
import ExceptionEnum from 'root-resources/exception-enum';
import InputAdornment from '@mui/material/InputAdornment';
import Cabecalho from 'root-components/cabecalho/cabecalho';
import { DispatchAction } from 'root-states/root-dispatcher';
import { Email, EnhancedEncryption } from '@mui/icons-material';
import Authentication from 'root-resources/oauth/authentication';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { Button, TextField, Card } from '@bubotech/sumora-react-components';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';

import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';

export type LoginPropType = {};

/**
 * View Login
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 */
function Login(props: LoginPropType): JSX.Element {
  const oauth = new Authentication();

  const [statusLogin, setStatusLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const appLayoutActions = new AppLayoutActions(
    useDispatch<Dispatch<DispatchAction>>()
  );

  const classes = useStyles(props);
  const formatMessage = useMessages({
    'pt-BR': ptBR,
    'en-US': enUS,
    'es-ES': esES,
  });
  const history = useNavigate();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email(formatMessage('login.emailInvalido'))
          .required(formatMessage('login.campoObrigatorio')),
        password: statusLogin
          ? Yup.string()
            .min(6, formatMessage('login.minimoSenha'))
            .required(formatMessage('login.campoObrigatorio'))
          : Yup.string(),
      }),
      onSubmit: statusLogin ? submitLogin : submitResetPassword,
    });

  // add an event listener on the key enter
  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <div className={classes.background}>
      <Cabecalho />

      <Card className={classes.card}>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12} className={classes.textField}>
            <TextField
              variant='standard'
              type='email'
              name='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email !== undefined}
              helperText={
                touched.email && errors.email !== '' ? errors.email : null
              }
              label='E-mail *'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Email className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <div
            className={classes.textPassword}
            style={{ maxHeight: statusLogin ? 110 : 0 }}>
            <Grid item xs={12} className={classes.textField}>
              <TextField
                variant='standard'
                name='password'
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password !== undefined}
                helperText={
                  touched.password && errors.password !== ''
                    ? errors.password
                    : null
                }
                value={values.password}
                label={formatMessage('login.senha')}
                id='adornment-password'
                type='password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <EnhancedEncryption className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </div>

          <div className={classes.esqueceuSenha}>
            <div style={{ display: 'flex', alignItems: 'center' }} />
            {statusLogin ? (
              <label
                className={classes.label}
                onClick={forgetPassword}
                style={{ fontWeight: 500 }}>
                Esqueceu sua senha?
              </label>
            ) : (
              <label className={classes.label} onClick={backLogin}>
                {formatMessage('login.retornarLogin')}
              </label>
            )}
          </div>

          <div className={classes.button}>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              loading={loading}
              disabled={loading}
              style={{ padding: loading ? 0 : '8px 16px' }}
              onClick={() => {
                handleSubmit();
              }}>
              {statusLogin
                ? formatMessage('login.login')
                : formatMessage('login.enviar')}
            </Button>
          </div>
        </Grid>
      </Card>
    </div>
  );

  /**
   * Realiza a validação do login
   *
   */
  function submitLogin() {
    setLoading(true);

    oauth
      .login(values.email, values.password)
      .then(() => {

        oauth
          .findUsuarioEmpresaLogado()
          .then((result) => {
            appLayoutActions.setCompany(result.data.empresa);
            history('/');
            setLoading(false);
          })
          .catch(() => setLoading(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.falhaUsuarioInativo'),
            icon: 'error',
          });
          setLoading(false);
          return;
        }

        if (err.response?.status === 400) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.usuarioSenhaIncorreto'),
            icon: 'error',
          });
          setLoading(false);
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRealizarLogin'),
          text: formatMessage('login.falhaLoginMessage'),
          icon: 'error',
        });
        setLoading(false);
      });
  }

  /**
   * Realiza a validação do login
   *
   * @param values
   */
  function submitResetPassword(values: { email: string; }) {
    setLoading(true);

    oauth
      .recuperarSenha(values.email)
      .then(() => {
        Swal({
          showConfirmButton: true,
          showCancelButton: false,
          title: formatMessage('login.emailEnviadoMessage') + values.email,
          text: formatMessage('login.emailEnviado'),
          icon: 'success',
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        if (
          err.response?.data.codigo === ExceptionEnum.USUARIO_NAO_ENCONTRADO
        ) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRecuperarSenha'),
            text: formatMessage('login.usuarioNaoEncontrado'),
            icon: 'error',
          });
          setLoading(false);
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRecuperarSenha'),
          text: formatMessage('Falha enviar e-mail'),
          icon: 'error',
        });
        setLoading(false);
      });
  }

  /**
   * Realiza o efeito de aumentar o card
   *
   * @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} event
   */
  function backLogin(event: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    setStatusLogin(true);
  }

  /**
   * Realiza o efeito de diminuir o card
   *
   * @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} event
   */
  function forgetPassword(
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) {
    setStatusLogin(false);
  }
}

export default Login;
