/**
 * Enumeração para os tipos de diâmetro
 * 
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 */
enum TipoDiametroEnum {
    REDONDA,
    QUADRADA,
    RETANGULAR,
    CORACAO,
    ESTRELA,
    LUA,
    OVAL
}

export default TipoDiametroEnum;