import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../assets/styles/upload-produto.styles';
import { useParams, Params } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import { ButtonFABMenu, DataTable, UploadFiles } from '@bubotech/sumora-react-components/lib';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import UploadProduto, { Base64, DetalhesUploadProduto } from '../model/uploadproduto';
import { tiposItem } from 'root-utils/tipo-item';
import { Modal } from 'root-components';
import FileUploadIcon from 'root-icons/upload-solid.svg';
import Arquivo from '../../produto/model/arquivo';
import ProdutoAPI from '../../produto/resource/produto';
import MensagemErro from 'root-models/error';
import SweetAlert from 'sweetalert2';
import { theme } from 'root-utils/constants';

export type EditarUploadProdutoPropType = {};

/**
 * View de edição de Upload Produto
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @param {EditarUploadProdutoPropType} props
 */
function EditarUploadProduto(props: EditarUploadProdutoPropType): JSX.Element {
    const classes = useStyles();
    const history = useNavigate();
    const { id }: Readonly<Params<string>> = useParams();
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);
    const [showModalLoading, setShowModalLoading] = useState<boolean>(false);
    const [arquivo, setArquivo] = useState<Arquivo>();
    const api = new ProdutoAPI();

    const [enableReinitialize, setEnableReinitialize] = useState(false);
    const [initialValues, setInitialValues] = useState<UploadProduto>({
        nmArquivo: '',
        dhImportacaoInicio: new Date(),
        produtoList: [],
        stUpload: 0,
        bucketS3: ''
    });

    const [base64, setBase64] = useState<Base64>();
    const [count, setCount] = useState(1);

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Upload - Cadastro de Produto');

        if (id) setEnableReinitialize(true);
    });

    useEffect(() => {
        if (!enableReinitialize) return;
        appLayoutActions.setLoading(true);

        setTimeout(() => {
            setEnableReinitialize(false);
            appLayoutActions.setLoading(false);
        }, 3000);

        // eslint-disable-next-line
    }, [enableReinitialize]);

    async function handleChangeFile() {

        let error = { mensagem: '', titulo: '', nome_exception: '', error: false, details: "" };
        appLayoutActions.setLoading(true);

        if (base64) {
            await api.getFileExcel(base64).then((res) => {
                setInitialValues({ ...initialValues, produtoList: res.data.produtoList, bucketS3: res.data.bucketS3 });
            }).catch((e) => {
                setArquivo(undefined);
                setInitialValues({ ...initialValues });
                error = e.response ? { ...e.response.data } : { mensagem: 'Erro ao buscar arquivo', titulo: 'Erro', nome_exception: '', error: true };
                
                const getMensagem = () => {
                    if (error['mensagem'])
                        return error['mensagem'].replaceAll("quebralinha", "<br>");
                    else return "";
                };

                const mensagem = getMensagem();
                mensagem === "" ?
                    Swal({
                        showConfirmButton: true,
                        title: error['titulo'] ?? 'Erro',
                        text:
                            error['mensagem'] ?? `Erro ao ${id ? 'editar' : 'salvar'} produto.`
                        ,
                        icon: 'error',
                    })
                    :
                    SweetAlert.fire({
                        allowEscapeKey: false,
                        confirmButtonColor: theme.palette.primary.main,
                        cancelButtonColor: theme.palette.error.main,
                        title: 'Códigos duplicados',
                        html: mensagem,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        showConfirmButton: true,
                    });

                setCount(count + 1);
            }).finally(() => {
                appLayoutActions.setLoading(false);
                setShowModalLoading(false);
            });
        }

    }

    return (
        <main style={{ height: '93%' }}>
            <section id='titulo-pagina'>
                <div className={classes.titleContainer}>Upload</div>
            </section>

            <section id='editar-upload-produto' className={classes.sectionTable}>

                <section id='upload-arquivo'>
                    <div className={classes.simpleSection}>
                        <UploadFiles
                            disabled={isLoading || id !== undefined}
                            propsInput={{
                                accept: '.xlsx, .xls'
                            }}
                            multiUploads={false}
                            key={count}
                            addNewFiles={(fileList: Arquivo[]) => {
                                if (fileList[0].dsFileName?.endsWith('.xlsx') || fileList[0].dsFileName?.endsWith('.xls')) {
                                    setArquivo(fileList[0]);
                                    setBase64({ base64: fileList[0].dsBase64 });

                                    setShowModalLoading(true);
                                } else {
                                    Swal({
                                        showConfirmButton: true,
                                        title: 'Erro',
                                        text: 'Arquivo inválido',
                                        icon: 'error',
                                    });
                                }
                            }}
                            tratarError={() => { }}
                        >
                            <div className={classes.chooseImage}>
                                Escolher arquivo
                            </div>
                        </UploadFiles>

                        <div className={classes.fileName} style={{ paddingLeft: '1rem', marginTop: '2.2rem' }}>{initialValues.nmArquivo}</div>
                    </div>
                </section>

                <section id='tabela' className={classes.sectionTable} style={{ marginTop: 10 }}>
                    <div className={`${classes.containerDataTable} tabela`}>
                        <DataTable<DetalhesUploadProduto>
                            columns={[
                                {
                                    pinned: true,
                                    suppressSizeToFit: true,
                                    field: 'stImportado',
                                    headerName: 'Importação',
                                    col: 2,
                                    cellClassRules: {
                                        'orange-text': (params) => params.value === "Atualizado",
                                        'green-text': (params) => params.value === 'Importado'
                                    },
                                },
                                {
                                    pinned: true,
                                    suppressSizeToFit: true,
                                    field: 'cdProduto',
                                    headerName: 'Código',
                                    col: 1
                                },
                                {
                                    pinned: true,
                                    suppressSizeToFit: true,
                                    field: 'nmProduto',
                                    headerName: 'Nome',
                                    col: 4,
                                },
                                {
                                    pinned: true,
                                    suppressSizeToFit: true,
                                    field: 'tpItem',
                                    headerName: 'Tipo Item',
                                    col: 2,
                                    valueGetter: (node) => tiposItem.find(item => item.codigo === node.data.tpItem)?.descricao ?? '-'
                                },
                                {
                                    pinned: true,
                                    suppressSizeToFit: true,
                                    field: 'dsProduto',
                                    headerName: 'Descrição Técnica',
                                    col: 3,
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'unidadeMedidaVenda.dsUnidadeMedida',
                                    headerName: 'Un. Venda',
                                    col: 1
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'nrPesoBruto',
                                    headerName: 'Peso Bruto',
                                    col: 1,
                                    valueFormatter: function (params) {
                                        return formatarNumeroComVirgula(params.value);
                                    }
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'nrPesoLiquido',
                                    headerName: 'Peso Líquido',
                                    col: 1,
                                    valueFormatter: function (params) {
                                        return formatarNumeroComVirgula(params.value);
                                    }
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'nrAltura',
                                    headerName: 'Altura (mm)',
                                    col: 1,
                                    valueFormatter: function (params) {
                                        return formatarNumeroComVirgula(params.value);
                                    }
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'nrLargura',
                                    headerName: 'Largura (mm)',
                                    col: 1,
                                    valueFormatter: function (params) {
                                        return formatarNumeroComVirgula(params.value);
                                    }
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'nrComprimento',
                                    headerName: 'Comp. (mm)',
                                    col: 1,
                                    valueFormatter: function (params) {
                                        return formatarNumeroComVirgula(params.value);
                                    }
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'stProduto',
                                    headerName: 'Status',
                                    col: 1,
                                    valueGetter: (node) => ['Inativo', 'Ativo'][node.data.stProduto],
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'marca.nmMarca',
                                    headerName: 'Marca',
                                    col: 1
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'stMarca',
                                    headerName: 'Status Marca',
                                    col: 1,
                                    cellClassRules: {
                                        'orange-text': (params) => params.value === "Atualizado",
                                        'green-text': (params) => params.value === 'Importado'
                                    },
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'dsModelo',
                                    headerName: 'Modelo',
                                    col: 1
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'corProduto.nmCorProduto',
                                    headerName: 'Cor',
                                    col: 1
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'nrTamanho',
                                    headerName: 'Tamanho',
                                    col: 1
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'dsObservacoes',
                                    headerName: 'Observações',
                                    col: 1
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'blMovimentaEstoque',
                                    headerName: 'Mov. Estoque',
                                    col: 1,
                                    valueGetter: (node) => node.data.blMovimentaEstoque === true ? 'Sim' : 'Não',
                                },
                                {
                                    suppressSizeToFit: true,
                                    field: 'blNotaFiscal',
                                    headerName: 'Nota Fiscal',
                                    col: 1,
                                    valueGetter: (node) => node.data.blNotaFiscal === true ? 'Sim' : 'Não',
                                },

                            ]}
                            data={initialValues.produtoList}
                            rowsPerPageEnabled={false}
                            showPagination={false}
                        />
                    </div>

                    <ButtonFABMenu
                        disabled={isLoading}
                        primaryAction={{ onClick: (e: any) => handleSubmit(), iconProps: { color: 'inherit' }, disabled: !(initialValues.produtoList.length > 0) }}
                        secondaryAction={{ onClick: () => history(-1), iconProps: { color: 'inherit' } }}
                    />
                </section>

            </section>

            <Modal
                title='Está sendo realizado o upload do arquivo: '
                message={`${arquivo?.dsFileName}`}
                optionsDescription='Deseja confimar a ação?'
                open={showModalLoading}
                onFinishLabel='Confirmar'
                onCloseLabel='CANCELAR'
                onClose={() => {
                    setShowModalLoading(false);
                    setArquivo(undefined);
                    setBase64(undefined);
                    setCount(count + 1);
                }}
                onFinish={() => {
                    setInitialValues({ ...initialValues });
                    handleChangeFile();
                }}
                customWidth={600}
                customIcon={FileUploadIcon}
            />
        </main>
    );

    /**
     * Manipula o evento de submit do Formik
     *
     * @param {UploadProduto} values - Valores do submit
     */
    async function handleSubmit() {
        LoadingSwal({ text: 'Carregando' });
        let error: MensagemErro = { mensagem: '', titulo: '', nome_exception: '', error: false };

        await api.saveFileExcel(initialValues).catch(e => {
            error = { ...e.response?.data as MensagemErro };
            error.error = true;
        });

        if (!error.error) {
            Swal({
                showConfirmButton: true,
                title: 'Sucesso',
                text: 'Importado com sucesso',
                icon: 'success',
            });

            history(-1);
        } else {

            if (error.mensagem && error['mensagem'].includes('cdProduto')) {
                error.mensagem = error['mensagem'].replace('cdProduto', 'código item');
                error.mensagem += ' no arquivo ' + (arquivo?.dsFileName || 'nome do arquivo desconhecido');
            }

            Swal({
                showConfirmButton: true,
                title: error['titulo'] ?? 'Erro',
                text: error['mensagem'] ?? `Erro ao upload de produto.`,
                icon: 'error',
            });
        }
    }

    function formatarNumeroComVirgula(numero: string) {
        if (numero != null) {
            var numeroFormatado = parseFloat(numero).toFixed(2);
            return numeroFormatado.replace('.', ',');
        }
        return numero;
    }
}

export default EditarUploadProduto;
