import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente de listagem
 */
const useStyles = makeStyles((theme: any) => ({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTable: {
    flex: 10,
    height: '100%'
  },
  containerDataTable: {
    display: 'flex',
    flex: 1,
  },
}));

export { useStyles };
