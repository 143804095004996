import moment from 'moment';
import ModelUploadProduto from '../model/uploadproduto';
import IconDownload from 'root-icons/download-solid.svg';
import { ListingScreen, Swal as SwalModal } from 'root-components';
import UploadFichaTecnicaApi from 'root-cadastros/uploadFichaTecnica/resources/api';

// enums
import StatusUploadEnum from '../enumerations/status-upload-enum';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

/**
 * Listagem de Uploads de Produto
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>  
 */
function UploadProduto(): JSX.Element {
  const api = new UploadFichaTecnicaApi();

  async function onClickDownload(selected?: ModelUploadProduto) {
    if (!selected) return;

    try {
      const anchor = document.createElement('a');
      anchor.href = selected.dsBucket || '';
      anchor.download = 'upload';
      anchor.click();

      SwalModal({
        cancelButtonText: 'Ok',
        title: 'Download Realizado!',
        text: 'Download realizado com sucesso!',
        icon: 'success',
      });
    } catch {
      SwalModal({
        cancelButtonText: 'Ok',
        title: 'Erro',
        text: 'Erro ao fazer o download!',
        icon: 'error',
      });
    }
  }
  return (
    <ListingScreen<ModelUploadProduto>
      title='Upload - Cadastro de Produto'
      dateFilters={[]}
      screenStaticParams='tpImportacao:0'
      enumFilters={['stImportacao']}
      filtersDefinitions={[
        {
          field: 'dhImportacaoInicio',
          label: 'Início de Importação',
          tableColumnSize: 2,
          type: TipoFiltroEnum.DATE,
          hideFilter: true,
          otherColProps: {
            valueFormatter: (node) => node.data.dhImportacaoInicio ? moment(node.data.dhImportacaoInicio).format('DD/MM/YYYY - HH:mm') : '-'
          }
        },
        {
          field: 'dhImportacaoTermino',
          label: 'Término de Importação',
          tableColumnSize: 9,
          type: TipoFiltroEnum.DATE,
          hideFilter: true,
          otherColProps: {
            valueFormatter: (node) => node.data.dhImportacaoTermino ? moment(node.data.dhImportacaoTermino).format('DD/MM/YYYY - HH:mm') : '-'
          }
        },
        {
          field: 'stImportacao',
          label: 'Status',
          tableColumnSize: 1,
          type: TipoFiltroEnum.ENUM,
          enumOptions: [
            { label: 'Em importação', value: StatusUploadEnum.EM_IMPORTACAO },
            { label: 'Finalizado', value: StatusUploadEnum.FINALIZDO },
            { label: 'Finalizado com Erro', value: StatusUploadEnum.FINALIZADO_ERRO },
          ]
        },
      ]}
      api={api}
      formScreenUrl='/novo'
      orderField='stImportacao'
      id='idImportacao'
      screenKey={ScreensKeysEnum.UPLOAD_PRODUTO}
      tableProps={{
        showDelete: false,
        showEdit: false,
        customButtons: [{
          enableWithSelected: true,
          icon: <img src={IconDownload} alt='download' style={{ height: 21, width: 21, color: '#FFF !important' }} />,
          onClick: onClickDownload
        }]
      }}
    />
  );
};

export default UploadProduto;