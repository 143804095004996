import { DispatchAction } from 'root-states/root-dispatcher';

/**
 * Modelo de uma função Reducer do Redux
 */
export type ReducerFunctionType<S> = (state: S, action: DispatchAction) => S;

/**
 * Classe modelo para ser extendida pelos Reducers do Redux
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @abstract - Não pode ser instânciada, apenas extendida
 * @template S - Tipo do State do Reducer
 */
abstract class RootReducer<S> {
  /**
   * Estado inicial do Reducer
   *
   * @protected - Apenas esta classe e classes que a extendem podem acessá-la
   * @type {S}
   */
  protected readonly initialState: S;

  /**
   * Cria uma instância do Reducer e injeta o estado inicial
   *
   * @param {S} initialState - Estado inicial que vai ser injetado
   */
  protected constructor(initialState: S) {
    this.initialState = initialState;
  }

  /**
   * Função abstrada que retorna a função do Reducer
   *
   * @abstract - Deve ser declarada pela classe que extender esta
   * @returns {ReducerFunctionType<S>} Função Reducer do Redux
   */
  public abstract getReducer(): ReducerFunctionType<S>;
}

export default RootReducer;
