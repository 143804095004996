import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import DataTable from '@bubotech/sumora-react-components/lib/datatable';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import Busca from 'root-models/parametrosbusca';
import Resposta from 'root-models/respostaAPI';
import { TableOrder } from 'root-models/table-order';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { buildQuery } from 'root-utils/build-query';
import { useStyles } from 'root-views/app.styles';
import Marca from '../model/marca';
import MarcaApi from '../resource/marca';

export type MarcasPropType = {};

/**
 * Tela de listagem de Marcas
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {MarcasPropType} props
 */
function Marcas(props: MarcasPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();

  const api = new MarcaApi();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'nmMarca', orderType: 'ASC' });
  const [dataFiltered, setDataFiltered] = useState<Marca[]>([]);
  const [selected, setSelected] = useState<Marca | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Cadastro de Marca');
    if (ScreensKeysEnum.MARCA in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.MARCA].filters, ['stMarca'], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData();
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'nmMarca', orderType: 'ASC' };

    loadData();
  }

  function handleChangeFilters(data: any[]) {
    const staticFields = buildQuery(data, ['stMarca'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }

  function loadData() {
    appLayoutActions.setLoading(true);
    api.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Marca[]);
      })
      .catch(() => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.MARCA}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={handleChangeFilters}
          filtersDefinitions={[
            {
              field: 'nmMarca',
              label: 'Nome',
              type: TipoFiltroEnum.STRING,
            },
            {
              field: 'stMarca',
              label: 'Situação',
              type: TipoFiltroEnum.ENUM,
              enumOptions: [
                {
                  label: 'Ativo',
                  value: SituacaoEnum.ATIVO,
                },
                {
                  label: 'Inativo',
                  value: SituacaoEnum.INATIVO,
                },
              ],
            },
          ]}
        />
      </section>

      <section className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          disableEdit={!selected}
          buttonEditProps={{ disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
          onClickEdit={() => {
            if (!selected) return;
            history(`/cadastros/marca/editar/${selected.idMarca}`);
          }}
        />

        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<Marca>
            columns={[
              {
                field: 'nmMarca',
                headerName: 'Nome',
                col: 11,
              },
              {
                valueGetter: (node) => node.data.stMarca === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo',
                col: 1,
                headerName: 'Situação',
                field: 'stMarca',
              },
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handleChangePage}
            onOrderChange={handleChangeOrder}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/marca/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  );
}

export default Marcas;
