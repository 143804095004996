import { makeStyles } from '@mui/styles';

/**
 * Estilos do diagrama de etapas
 **/
const useStyles = makeStyles((theme: any) => ({
  content: {
    borderRadius: 10,
    padding: 12,
    backgroundColor: '#FFF',
    position: 'absolute',
    top: '10%',
    left: '25%',
    width: '50vw',
    height: '80vh',
    overflow: 'hidden'
  },
  header: {
    padding: '7px 0 12px 15px',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  diagram: {
    display: 'flex', 
    justifyContent: 'center', 
    paddingBottom: '50px',
  }
}));

export { useStyles };
