import GenericApi from 'root-resources/generic-api';
import { ImportacaoFichaTecnica } from '../model/upload-ficha-tecnica';
import FichaTecnicaAPI from 'root-cadastros/fichatecnica/resources/api';

/**
 * API de Upload de Ficha Técnica
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @extends {RootApi}
 */
class UploadFichaTecnicaApi extends GenericApi<FichaTecnicaAPI> {
    /**
    * Injeta a instância do axios com o endpoint base referente a API de Upload de Ficha Técnica
    */
    public constructor() {
        super({ baseEndpoint: 'ficha-tecnica/importacao' });
    }

    /**
     * Retorna a visualização da produto importado
     * 
     * @param arquivo base64 do arquivo xls
     */
    public async getFileExcel(fichaTecnicaBase64: string) {
        return this.api.post('/importar', { fichaTecnicaBase64 });
    }

    /**
     * Salva Dados da planilha excel de Produto
     * 
     * @param uploadProdutoDtoList lista de produtos da planilha excel
     */
    public async saveFileExcel(uploadProdutoDtoList: ImportacaoFichaTecnica) {
        return this.api.post(`/salvar`, uploadProdutoDtoList);
    }

    /**
   * Retorna a visualização da ficha técnica importada
   * 
   * @param idFichaTecnica id da ficha técnica
   * @returns visualização da ficha técnica importada
   */
    public async getImportacao(idFichaTecnica: string) {
        return this.api.get(`/importar/${idFichaTecnica}`);
    }
}

export default UploadFichaTecnicaApi;
