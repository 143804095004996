import { makeStyles } from '@mui/styles';

/**
 * Estilos do formulário de ficha técnica
 **/
const useStyles = makeStyles((theme: any) => ({
  diagram: {
    maxWidth: 101,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  diagramLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Roboto',
    margin: '0 5px',
    color: theme.palette.text.secondary
  },
  icone: {
    height: 21,
    width: 21,
    filter: 'invert(40%) sepia(12%) saturate(733%) hue-rotate(183deg) brightness(91%) contrast(89%)'
  },
  iconeSelecionado: {
    filter: 'invert(17%) sepia(32%) saturate(2955%) hue-rotate(205deg) brightness(95%) contrast(92%)'
  },
  iconDisabled: {
    height: 21,
    width: 21,
    filter: 'brightness(0) saturate(100%) invert(73%) sepia(8%) saturate(22%) hue-rotate(350deg) brightness(95%) contrast(80%)'
  },
}));

export { useStyles };
