import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Table, { TablePropType } from '../table/table';
import { Column } from '@bubotech/sumora-react-components/lib/datatable';
import SituacaoRegistroEnum from 'root-enumerations/situacao-registro';
import Form, { Field, FormFieldTypeEnum, FormPropType } from '../form/form';

interface ListItem {
  stRegistro: SituacaoRegistroEnum;
}

interface TableFormProps<E> extends Omit<TablePropType<E>, 'onClickDelete' | 'onClickEdit' | 'onClickAdd' | 'data' | 'columns'> {
  onClickAdd?: () => void;
  onClickEdit?: (selected: E, selectedIndex: number) => void;
  onClickDelete?: (selected: E, selectedIndex: number) => void;
  columns?: Column[];
}

interface FormTableFields extends Field {
  showInTable?: number;
  customTableField?: string;
}

interface FormProps<E> extends Omit<FormPropType<E>, 'fields'> {
  fields: FormTableFields[];
}

interface FormWithTablePropType<E> {
  list: E[];
  listField: string;
  listItemIdField: string;
  listForm:  FormProps<E>;
  tableProps?: TableFormProps<E>;
  onClickSave?: () => void;
  handleMainForm: (field: string, value: any, shouldValidate?: boolean) => void;
}

interface FormWithTableState {
  showEdit?: boolean;
}

/**
 * Formulário para listas
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {E[]} list Lista para apresentar na tabela
 * @param {string} listField Nome da lista no formulário principal
 * @param {string} listItemIdField id dos objetos da lista
 * @param {FormPropType<E>} listForm Propriedades do formulário
 * @param {TableFormProps<E>} tableProps Propriedades da tabela
 * @param handleMainForm Função para atualizar o formulário principal
 * @param onClickSave Função para controlar o submit
 */
function FormWithTable<E extends ListItem>(props: FormWithTablePropType<E>): JSX.Element {
  const [formWithTableState, setFormWithTableState] = useState<FormWithTableState>({
    showEdit: false,
  });
  const initialValues = useRef<E>();
  const { listForm, listField, tableProps, list, listItemIdField, handleMainForm, onClickSave } = props;

  const activeItens = useMemo(() => list.filter(item => item.stRegistro !== SituacaoRegistroEnum.DELETE), [list]);

  const tableColumns: Column[] = useMemo(() => {
    const columns = listForm.fields.filter(field => field.showInTable !== undefined).map(tableColumn => ({
      field: tableColumn.fieldName,
      headerName: tableColumn.label,
      col: tableColumn.showInTable,
      valueGetter: (node: any) => itemValueFormater(tableColumn, node.data, tableColumn.customTableField ?? tableColumn.fieldName)
    }))

    if (columns.length) return columns as Column[];
    else return tableProps?.columns ?? [];
  }
  // eslint-disable-next-line
  , [listForm.fields, tableProps?.columns])

  function itemValueFormater(column: FormTableFields, value: any, field: string): string {
    if (column.fieldType === FormFieldTypeEnum.DATE)
      return moment(value[field]).format('DD/MM/YYYY');

    if (column.fieldType === FormFieldTypeEnum.SELECT && column.selectProps)
      return column.selectProps.options.find(option => option.value === value).label ?? value;

    else return getItemValue(field, value);
  }

  function getItemValue(fieldName: string, item: E) {
    const fields = fieldName.split('.');

    let current: any = item;
    while (fields.length) {
      if (typeof current !== 'object' || !current) return undefined;
      else current = current[fields.shift() as keyof E || '' as keyof E];
    }

    return current;
  }

  /**
   * Checa se o item é válido
   * 
   * @param item Item de entrada
   * @returns 
   */
  function allowItem(item: E) {
    const { errors } = listForm.form;
    const stRegistro = 'stRegistro' in item;
    const hasValidId = Boolean(item[listItemIdField as keyof E] !== undefined);

    let isValidItem = item && typeof item === "object";

    if (!stRegistro)  {
      console.error('O item não possui stRegistro!');
      return false;
    }

    if (!hasValidId) {
      console.error(`O item não possui um '${listItemIdField}'`);
      return false;
    }
    
    let info = '';
    Object.keys(errors).forEach(key => {
      if (!item[key as keyof E]) isValidItem = false;
      info += `Campo: ${key},\tErro: ${errors[key as keyof typeof errors]},\tValor recebido: ${item[key as keyof E]}\n`;
    });

    if (!listForm.form.isValid || !isValidItem) {
      console.error(`O item não é permitido!\n${info}`);
      return false;
    }

    return true;
  }

  /**
   * Checa se pode realizar a operação com o item escolhido
   * @param selected item escolhido
   * @param selectedIndex index do item escolhido
   * @returns 
   */
  function allowOperation(selected: E) {
    const index = list.findIndex(item => JSON.stringify(item) === JSON.stringify(selected));

    if (index === -1) {
      console.error('Indice inválido');
      return false;
    }

    //if (!allowItem(selected)) return false;

    setFormWithTableState({ showEdit: false });
    return true;
  }

  function getItemIndex(selected?: E): number {
    if (!selected) return -1;

    return list.findIndex(item => JSON.stringify(item) === JSON.stringify(selected));
  }

  /**
   * Checa se o item pode ser editado e configura o formulário
   * @param selected item escolhido
   * @param selectedIndex item do item escolhido
   * @returns 
   */
  function handleClickEdit(selected: E) {
    if (allowOperation(selected)) {
      const itemIndex = getItemIndex(selected);
      initialValues.current = selected;
      if (tableProps?.onClickEdit) tableProps.onClickEdit(selected, itemIndex);
      else {
        listForm.form.resetForm();
        listForm.form.setFormikState(prev => ({
          ...prev,
          values: selected
        }));
      }

      setFormWithTableState({ showEdit: true });
    }
  }

  /**
   * Cancela a edição do formulário
   */
  function handleCancelEdit() {
    setFormWithTableState({showEdit: false});
    listForm.form.resetForm();
    initialValues.current = undefined;
  }

  /**
   * Controla o submit do formulário
   */
  function handleSave() {
    const { form } = listForm;
    
    if (allowItem(form.values)) {
      let editIndex = getItemIndex(initialValues.current);
      if (!onClickSave) {
        if (editIndex === -1) {
          const newUUID = uuidv4();
          form.setFieldValue(listItemIdField, newUUID);
          handleMainForm(listField, [...list, form.values]);
        }
        else {
          let newList = [...list];
          newList[editIndex] = {...form.values};

          if (form.values[listItemIdField as keyof E]) 
            newList[editIndex].stRegistro = SituacaoRegistroEnum.UPDATE;

          handleMainForm(listField, newList);
        }

        form.resetForm();
      } else onClickSave();

      setFormWithTableState({ showEdit: false });
    } else {
      let touched: any = {};

      Object.keys(form.errors).forEach(key => {
        touched[key] = true;
      });
      form.setTouched(touched);
    }
  }

  /**
   * Remove um item da lista
   */
  function handleClickDelete(selected: E) {
    if (allowOperation(selected)) {
      const itemIndex = getItemIndex(selected);
      if (tableProps?.onClickDelete) tableProps.onClickDelete(selected, itemIndex);
      else {
        let newData = [...list];
        newData[itemIndex].stRegistro = SituacaoRegistroEnum.DELETE;
        handleMainForm(listField, newData.filter(item => {
          if (!item[listItemIdField as keyof E] && item.stRegistro === SituacaoRegistroEnum.DELETE) return false;
          else return true;
        }))
      }      
    }
  }

  return (
    <>
      <Form<E> 
        showAdd
        saveOnEnter
        showEdit={formWithTableState.showEdit}
        onCancelEdit={handleCancelEdit}
        customSave={handleSave}
        onFinishSave={() => setFormWithTableState({showEdit: false })}
        {...listForm}
      />
      <Table<E>
        data={activeItens}
        columns={tableColumns}
        className='sem-bordas'
        {...tableProps}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
    </>
  )
};

export default FormWithTable;