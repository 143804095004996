import { useEffect, useState } from "react";
import * as Yup from 'yup';
import moment from "moment";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { MainStateType } from "root-states";
import { FormikProps, useFormik } from "formik";
import { useStyles } from "root-views/app.styles";
import UploadFichaTecnicaApi from "../resources/api";
import { useDispatch, useSelector } from "react-redux";
import Arquivo from "root-cadastros/produto/model/arquivo";
import infoIcon from "root-icons/triangle-exclamation.svg";
import UploadIcon from "../assets/icons/upload-solid 1.svg";
import { DispatchAction } from "root-states/root-dispatcher";
import { FormFieldTypeEnum } from "root-components/form/form";
import TipoEtapaEnum from "root-enumerations/tipo-etapa-enum";
import { Params, useNavigate, useParams } from "react-router-dom";
import ImportacaoEnum from "root-enumerations/situacao-importacao";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import SituacaoProdutoEnum from "root-enumerations/situacao-produto-enum";
import EtapaFichaTecnicaUpload from "../model/etapa-ficha-tecnica-upload";
import { Form, LoadingSwal, Modal, Swal, useVerificaDados } from "root-components";
import UploadFicha, { ImportacaoFichaTecnica } from "../model/upload-ficha-tecnica";
import { useComponentDidMount } from "@bubotech/sumora-react-components/lib/utils/hooks";
import { ButtonFABMenu, DataTable, UploadFiles } from "@bubotech/sumora-react-components";
import StatusUploadEnum from "root-cadastros/uploadproduto/enumerations/status-upload-enum";

export type UploadFichaTecnicaPropType = {};

/**
 * View de upload de ficha técnica
 * 
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 * @param {UploadFichaTecnicaPropType} props
 * @returns {JSX.Element}
 */
export default function UploadFichaTecnica(props: UploadFichaTecnicaPropType): JSX.Element {
    const classes = useStyles();
    const history = useNavigate();
    const { id }: Readonly<Params<string>> = useParams();
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

    const [key, setKey] = useState<number>(Math.random());
    const [verifyFile, setVerifyFile] = useState<boolean>(false);
    const [verifyState, setVerifyState] = useState<boolean | undefined>(false);
    const [arquivo, setArquivo] = useState<Arquivo | undefined>(undefined);

    const [base64, setBase64] = useState<string | undefined>('');

    const api = new UploadFichaTecnicaApi();

    const [initialValues] =
        useState<ImportacaoFichaTecnica>({
            bucketS3: '',
            dhImportacaoInicio: '',
            fichaTecnica: {
                idFichaTecnica: undefined,
                fichaTecnicaBase64: '',
                dtVigencia: undefined,
                versao: undefined,
                cdItem: '',
                nmItem: '',
                stImportacao: ImportacaoEnum.IMPORTADO,
                etapaList: [],
            },
            stUpload: StatusUploadEnum.EM_IMPORTACAO,
            nmArquivo: '',
            idImportacao: undefined,
        });

    const { values, handleSubmit, setFieldValue, dirty, isValid, resetForm, setValues } =
        useFormik<ImportacaoFichaTecnica>({
            validateOnBlur: true,
            validateOnChange: true,
            enableReinitialize: true,
            initialValues,
            validationSchema: Yup.object().shape({
                fichaTecnicaBase64: Yup.string().optional(),
                etapaList: Yup.array().min(1, 'O arquivo deve conter ao menos uma etapa'),
            }),
            onSubmit: handleSubmitFormik,
            onReset: () => {
                setArquivo(undefined);
                setValues(initialValues);
            }
        });

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Upload - Ficha Técnica');

        if (id) loadData(id);
    });

    useVerificaDados({
        funcaoPrincipalProps: { dirty },
        handleSubmit,
        ignoreTabChange: true
    });

    async function loadData(id?: string): Promise<void> {
        appLayoutActions.setLoading(true);
        if (!id) {
            if (base64) {
                await api.getFileExcel(base64).then((res) => {
                    const status = res.data.fichaTecnica.stItem;
                    if (status === SituacaoProdutoEnum.ATIVO || verifyState) {
                        setVerifyState(false)
                        setValues({ ...initialValues, fichaTecnica: res.data.fichaTecnica, bucketS3: res.data.bucketS3 });
                    } else {
                        setValues({ ...initialValues, fichaTecnica: res.data.fichaTecnica });
                        setValues({
                            ...initialValues,
                            fichaTecnica: {
                                ...initialValues.fichaTecnica,
                                idFichaTecnica: res.data.fichaTecnica.idFichaTecnica,
                                cdItem: res.data.fichaTecnica.cdItem,
                                nmItem: res.data.fichaTecnica.nmItem,
                                stItem: res.data.fichaTecnica.stItem
                            },
                        });
                        setVerifyState(true)
                    }
                }).catch((error) => {
                    setArquivo(undefined);
                    setFieldValue('fichaTecnicaBase64', '');
                    Swal({
                        showConfirmButton: true,
                        title: 'Erro',
                        text: error.response?.data?.mensagem ?? 'Erro no servidor',
                        icon: 'error',
                    });
                }).finally(() => {
                    appLayoutActions.setLoading(false);
                });
            }
        } else {
            await api.getImportacao(id).then((response) => {
                setValues(response.data);
            }).catch((error) => {
                setArquivo(undefined);
                setFieldValue('fichaTecnicaBase64', '');
                Swal({
                    showConfirmButton: true,
                    title: 'Erro',
                    text: error.response?.data?.mensagem ?? 'Erro no servidor',
                    icon: 'error',
                });
            }).finally(() => {
                appLayoutActions.setLoading(false);
            });
        }
    }

    function getTpEtapa(tpEtapa: TipoEtapaEnum) {
        switch (tpEtapa) {
            case TipoEtapaEnum.INICIAL:
                return 'Inicial';
            case TipoEtapaEnum.SEQUENCIAL:
                return 'Sequencial';
            case TipoEtapaEnum.CONCORRENTE:
                return 'Concorrente';
            case TipoEtapaEnum.FINAL:
                return 'Final';
            default:
                return 'Valor inexperado';
        }
    }

    useEffect(() => {
        if (arquivo) {
            setFieldValue('fichaTecnicaBase64', arquivo.dsBase64);
        }
    }, [arquivo, setFieldValue]);
    return (
        <main className={classes.contentContainer}>
            <section id='titulo-pagina'>
                <div className={classes.titleContainer}>Upload</div>
            </section>

            <section id='upload-arquivo'>
                <div className={classes.simpleSection}>
                    <UploadFiles
                        disabled={isLoading || id !== undefined}
                        propsInput={{
                            accept: '.xlsx'
                        }}
                        multiUploads={false}
                        key={key}
                        addNewFiles={(fileList: Arquivo[]) => {
                            if (fileList[0].dsFileName?.endsWith('.xlsx')) {
                                resetForm();
                                setArquivo(fileList[0]);
                                setBase64(fileList[0].dsBase64);
                                setVerifyFile(true);
                            } else {
                                Swal({
                                    showConfirmButton: true,
                                    title: 'Erro',
                                    text: 'Arquivo inválido',
                                    icon: 'error',
                                });
                            }
                            setKey(Math.random());
                        }}
                        tratarError={() => { }}
                    >
                        <div className={classes.chooseImage}>
                            Escolher arquivo
                        </div>
                    </UploadFiles>

                    <div className={classes.fileName}>{arquivo?.dsFileName}</div>
                </div>
            </section>

            <section id='conteudo-ficha-tecnica' className={classes.sectionTable} style={{ height: '69vh' }}>
                {values?.fichaTecnica.fichaTecnicaBase64 !== '' &&
                    <div style={{ padding: '0 25px' }}>
                        <Form
                            form={{ values: values.fichaTecnica } as FormikProps<UploadFicha>}
                            fields={[
                                {
                                    fieldName: 'versao',
                                    fieldType: FormFieldTypeEnum.READ_ONLY,
                                    fieldSize: 1,
                                    label: 'Versão Vigente'
                                },
                                {
                                    fieldName: 'dtVigencia',
                                    fieldType: FormFieldTypeEnum.READ_ONLY,
                                    fieldSize: 2,
                                    label: 'Ultima Atualização',
                                    value: values.fichaTecnica.dtVigencia ? moment(values.fichaTecnica.dtVigencia).parseZone().format('DD/MM/YYYY') : '-'
                                },
                                {
                                    fieldName: 'cdItem',
                                    fieldType: FormFieldTypeEnum.READ_ONLY,
                                    fieldSize: 2,
                                    label: 'Cód. Produto'
                                },
                                {
                                    fieldName: 'nmItem',
                                    fieldType: FormFieldTypeEnum.READ_ONLY,
                                    fieldSize: 4,
                                    label: 'Produto'
                                },
                                {
                                    fieldName: 'stImportacao',
                                    fieldType: FormFieldTypeEnum.READ_ONLY,
                                    fieldSize: 2,
                                    label: 'Status',
                                    value: values.fichaTecnica.stImportacao === ImportacaoEnum.IMPORTADO ?
                                        'Importado' : values.fichaTecnica.stImportacao === ImportacaoEnum.ATUALIZADO ?
                                            'Atualizado' : 'Erro'
                                }
                            ]}
                        />
                    </div>
                }
                <div className={`${classes.containerDataTable} tabela`}>
                    <DataTable<EtapaFichaTecnicaUpload>
                        columns={[
                            {
                                field: 'tpEtapa',
                                headerName: 'Tipo Etapa',
                                pinned: 'left',
                                suppressSizeToFit: true,
                                col: 2,
                                valueGetter: (node) => getTpEtapa(node.data.tpEtapa)
                            },
                            {
                                field: 'etapaProcesso',
                                headerName: 'Etapa do Processo',
                                pinned: 'left',
                                suppressSizeToFit: true,
                                col: 3
                            },
                            {
                                field: 'dsEtapa',
                                headerName: 'Descrição',
                                pinned: 'left',
                                suppressSizeToFit: true,
                                col: 3,
                                valueGetter: (node) => node.data.dsEtapa ? node.data.dsEtapa : '-'
                            },
                            {
                                field: 'modoPreparo',
                                headerName: 'Modo de Preparo',
                                pinned: 'left',
                                suppressSizeToFit: true,
                                col: 4
                            },
                            {
                                field: 'cdInsumo',
                                headerName: 'Cód. Insumo',
                                suppressSizeToFit: true,
                                col: 2,
                                valueGetter: (node) => node.data.cdInsumo ? node.data.cdInsumo : '-'
                            },
                            {
                                field: 'nmInsumo',
                                headerName: 'Nome Insumo',
                                suppressSizeToFit: true,
                                col: 2,
                                valueGetter: (node) => node.data.nmInsumo ? node.data.nmInsumo : '-'
                            },
                            {
                                field: 'quantidade',
                                headerName: 'Qtde',
                                suppressSizeToFit: true,
                                col: 1,
                                valueGetter: (node) => node.data.quantidade ?
                                    new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(node.data.quantidade)
                                    : '-'
                            },
                            {
                                field: 'sgUnidadeMedida',
                                headerName: 'Un. Medida',
                                suppressSizeToFit: true,
                                col: 1,
                                valueGetter: (node) => node.data.sgUnidadeMedida ? node.data.sgUnidadeMedida : '-'
                            },
                            {
                                field: 'descricao',
                                headerName: 'Descrição',
                                suppressSizeToFit: true,
                                col: 3,
                                valueGetter: (node) => node.data.descricao ? node.data.descricao : '-'
                            },
                            {
                                field: 'etapaAnterior',
                                headerName: 'Etapa Anterior',
                                suppressSizeToFit: true,
                                col: 3,
                                valueGetter: (node) => node.data.etapaAnterior ? node.data.etapaAnterior : '-'
                            }
                        ]}
                        data={values.fichaTecnica.etapaList}
                        rowsPerPageEnabled={false}
                        showPagination={false}
                    />
                </div>
            </section>

            <ButtonFABMenu
                disabled={isLoading}
                primaryAction={{
                    disabled: !isValid || id !== undefined || !dirty,
                    onClick: (e: any) => handleSubmit(e),
                    iconProps: { color: 'inherit' },
                }}
                secondaryAction={{
                    onClick: () => history('/cadastros/upload-ficha-tecnica'),
                    iconProps: { color: 'inherit' },
                }}
            />

            <Modal
                open={verifyFile}
                title={'Está sendo realizado o upload do arquivo: '}
                optionsDescription='Deseja confirmar a ação?'
                message={`${arquivo?.dsFileName}`}
                customWidth={600}
                customIcon={UploadIcon}
                onFinishLabel='Confirmar'
                onCloseLabel='Cancelar'
                onClose={() => {
                    resetForm();
                    setArquivo(undefined);
                    setVerifyFile(false);
                    setBase64(undefined);
                }}
                onFinish={() => {
                    resetForm();
                    loadData();
                    setVerifyFile(false);
                }}
            />
            <Modal
                open={verifyState ? true : false}
                title={
                    <p style={{ fontWeight: '500' }}>
                        O produto "<b>{values?.fichaTecnica.nmItem}</b>" código
                        "<b>{values?.fichaTecnica.cdItem}</b>"
                        {values?.fichaTecnica.stItem === SituacaoProdutoEnum.INATIVO ? " Está Inativo no " : " Foi Descontinuado do "}
                        sistema PIANI.
                    </p>
                }
                optionsDescription='Deseja continuar a importação?'
                customWidth={600}
                customIcon={infoIcon}
                onFinishLabel='Continuar'
                onCloseLabel='Cancelar'
                onClose={() => {
                    resetForm();
                    setArquivo(undefined);
                    setVerifyState(false);
                    setBase64(undefined);
                }}
                onFinish={() => {
                    loadData();
                    resetForm();
                    setVerifyState(undefined);
                }}
            />
        </main>
    );

    /**
     * Manipula o evento de submit do Formik
     *
     * @param {ImportacaoFichaTecnica} values - Valores do submit
     */
    async function handleSubmitFormik(values: ImportacaoFichaTecnica): Promise<void> {
        LoadingSwal({ text: 'Carregando' });
        let error = '';
        const ficha: Omit<UploadFicha, 'fichaTecnicaBase64'> = {
            idFichaTecnica: values.fichaTecnica.idFichaTecnica,
            dtVigencia: values.fichaTecnica.dtVigencia,
            versao: values.fichaTecnica.versao,
            cdItem: values.fichaTecnica.cdItem,
            nmItem: values.fichaTecnica.nmItem,
            stImportacao: values.fichaTecnica.stImportacao,
            etapaList: values.fichaTecnica.etapaList,
        };

        const data: ImportacaoFichaTecnica = {
            bucketS3: values.bucketS3,
            dhImportacaoInicio: values.dhImportacaoInicio,
            fichaTecnica: ficha,
            stUpload: values.stUpload,
            nmArquivo: values.nmArquivo,
            idImportacao: values.idImportacao,
        };

        let response: AxiosResponse<Omit<UploadFicha, 'fichaTecnicaBase64'>> | undefined = undefined;
        try {
            response = await Promise.race([
                api.saveFileExcel(data),
                new Promise<never>((_, reject) =>
                    setTimeout(() => reject(new Error('Timeout')), 10000)
                ),
            ]);
        } catch (e: any) {
            error = e.response?.data?.mensagem ?? 'Erro no servidor ';
        }

        if (error === '') {
            Swal({
                showConfirmButton: true,
                title: 'Sucesso',
                text: 'Importado com sucesso',
                icon: 'success',
            });

            history(`/cadastros/ficha-tecnica/editar/${response?.data?.idFichaTecnica}`);
        } else {
            Swal({
                showConfirmButton: true,
                title: 'Erro',
                text: error,
                icon: 'error',
            });
        }
    }
}