import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import URL from 'root-resources/URL';
import TokenController from './oauth/token-controller';


/**
 * Modelo de configurações da instância do Axios
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @extends {AxiosRequestConfig}
 */
export interface RootApiConfigs extends AxiosRequestConfig {
  baseEndpoint?: string;
}

/**
 * Classe base para ser extendida pelas classes que fazem comunicações com uma API
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @abstract Não pode ser instânciada, apenas extendida
 */
abstract class RootApi {
  /**
   * Memória da instância do Axios
   *
   * @protected - Apenas esta classe e classes que a extendem podem acessá-la
   * @type {AxiosInstance}
   */
  protected readonly api: AxiosInstance;

  /**
   * Construtor protegido que injeta a instância do Axios
   *
   * @param {RootApiConfigs} [configs={}] - Configurações para criação da instância do axios
   */
  protected constructor(configs: RootApiConfigs = {}) {
    const baseURL = `${configs.baseURL ?? URL.API}${configs.baseEndpoint ?? ''}`;
    this.api = axios.create({ ...configs, baseURL });
    this.api.interceptors.request.use(async (query) => {
      const result = TokenController.getToken();

      if (result && query.headers) {
        query.headers.Authorization = `Bearer ${result.access_token}`;
      }

      return query;
    },
      (err) => {
        if (process.env.REACT_APP_IN_DEV) {
          let errInJson;
          try {
            errInJson = JSON.stringify(err, null, 2);
          } catch (stringifyErr) {
            errInJson = null;
          }
          console.error('> Ocorreu um erro em uma request: \n', err, '\n', errInJson ?? '');
        }
        return Promise.reject(err);
      });
  }
}

export default RootApi;
