import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { Form, Swal } from 'root-components';
import FichaTecnicaAPI from '../resources/api';
import Aro from 'root-cadastros/aro/model/aro';
import { useStyles } from 'root-views/app.styles';
import FichaTecnica from '../models/fichatecnica';
import DiametroAPI from 'root-cadastros/aro/resource/aro';
import Produto from 'root-cadastros/produto/model/produto';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { FormFieldTypeEnum } from 'root-components/form/form';
import ProdutoAPI from 'root-cadastros/produto/resource/produto';
import TipoDiametroEnum from 'root-enumerations/tipo-diametro-enum';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import UnidadeMedidaAPI from '../../unidademedida/resources/unidademedida';

interface EditarPrincipalFichaTecnica {
  form: FormikProps<FichaTecnica>;
}

function PrincipalFichaTecnica(props: EditarPrincipalFichaTecnica): JSX.Element {
  const { form } = props;
  const classes = useStyles();

  const [key, setKey] = useState<number>(Math.random());

  const api = new FichaTecnicaAPI();
  const produtoApi = new ProdutoAPI();
  const diametroApi = new DiametroAPI();
  const unidadeMedidaApi = new UnidadeMedidaAPI();

  function handleChangeProduto(e: Produto | null) {
    if (!e) return;

    api.checkProduto(e.idProduto ?? '')
      .then(res => {
        form.setFieldValue('produto', e);
      })
      .catch(error => {
        const { titulo, mensagem } = error.response.data;
        form.setFieldError('produto', 'Campo duplicado');
        form.setFieldTouched('produto', true);

        Swal({
          showConfirmButton: true,
          title: titulo ?? 'Erro',
          text: mensagem ?? 'Produto já possui ficha técnica',
          icon: 'error',
        });
      })
      .finally(() => setKey(Math.random()));
  }
  const [dadosFiltrados, setDadosFiltrados] = useState<Aro[]>([]);
  const [todosDados, setTodosDados] = useState<Aro[]>([]);

  const fixedOptions = [
    { label: 'Redonda', value: TipoDiametroEnum.REDONDA },
    { label: 'Quadrada', value: TipoDiametroEnum.QUADRADA },
    { label: 'Retangular', value: TipoDiametroEnum.RETANGULAR },
    { label: 'Coração', value: TipoDiametroEnum.CORACAO },
    { label: 'Estrela', value: TipoDiametroEnum.ESTRELA },
    { label: 'Lua', value: TipoDiametroEnum.LUA },
    { label: 'Oval', value: TipoDiametroEnum.OVAL },
  ];
  const [options, setOptions] = useState(fixedOptions);

  useComponentDidMount(() => {
    diametroApi.findAllDiametro().then(res => {
      setTodosDados(res.data as Aro[]);
    });
  });

  useEffect(() => {
    if (todosDados) {
      const valoresOptions = todosDados
        .filter(option => option.stDiametro === 1)
        .map(option => option.tpDiametro);

      const optionsAtivas = fixedOptions.filter(option => valoresOptions.some(valor => valor === option.value));

      setOptions(optionsAtivas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todosDados])

  function handleChangeDiametro(e: Aro | null) {
    if (!e) return;

    const diametro = form.values.diametro;
    if (diametro?.tpDiametro !== undefined && !(diametro.nrFatias !== undefined && diametro.dsDiametro !== undefined)) {
      const objetoList = todosDados.filter((aro) => aro.tpDiametro === form.values.diametro?.tpDiametro && aro.nrDiametro === e.nrDiametro);
      if (objetoList.length !== 0) {
        const objeto = objetoList[0];
        form.setFieldValue('diametro', objeto);
      }
    } else if (diametro?.nrFatias !== undefined && diametro?.dsDiametro !== undefined) {
      form.setFieldValue('diametro', e);
    }

    form.setFieldValue('diametro.nrDiametro', e.nrDiametro);

  }

  function handleChangeTpDiametro(e: any) {
    const diametro = form.values.diametro;
    if (form.values.diametro?.nrDiametro !== undefined && !(diametro?.nrFatias !== undefined && diametro.dsDiametro !== undefined)) {
      const objetoList = todosDados.filter((aro) => aro.tpDiametro === e.value && aro.nrDiametro === form.values.diametro?.nrDiametro);
      if (objetoList.length !== 0) {
        const objeto = objetoList[0];
        form.setFieldValue('diametro', objeto);

      }
    } else if (diametro?.nrFatias !== undefined && diametro?.dsDiametro !== undefined) {
      form.setFieldValue('diametro', e);
    }
    form.setFieldValue('diametro.tpDiametro', e.value);
    form.setFieldValue('diametro.nrDiametro', '');

    const arosSelecionados = todosDados.filter((aro) => aro.tpDiametro === e.value && aro.stDiametro === SituacaoEnum.ATIVO);
    setDadosFiltrados(arosSelecionados);


  }
  return (
    <section id='principal' className={classes.tabContent}>
      <Form<FichaTecnica>
        key={key}
        form={form}
        verificaDados
        //keepHistory
        nextTab
        fields={[
          {
            fieldName: 'nrVersao',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            label: 'Versão',
            fieldSize: 1
          },
          {
            fieldName: 'produto',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            fieldSize: 2,
            label: 'Cód. Produto',
            autoCompleteProps: {
              getLabel: opt => opt.cdProduto,
              getValue: opt => opt.idProduto,
              genericApi: produtoApi,
              searchField: 'cdProduto',
              orderField: 'cdProduto',
              staticSearchParams: ',stProduto:1',
              onChangeValue: handleChangeProduto,
              disabled: Boolean(form.values.idFichaTecnica)
            }
          },
          {
            fieldName: 'produto',
            label: 'Produto',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            fieldSize: 4,
            autoCompleteProps: {
              getLabel: opt => opt.nmProduto,
              getValue: opt => opt.idProduto,
              genericApi: produtoApi,
              searchField: 'nmProduto',
              orderField: 'nmProduto',
              staticSearchParams: ',stProduto:1',
              PaperSuggestionsProps: { className: 'suggestions-fieldSize-4' },
              onChangeValue: handleChangeProduto,
              disabled: Boolean(form.values.idFichaTecnica)
            }
          },
          {
            fieldName: 'stFichaTecnica',
            label: 'Ativo',
            fieldType: FormFieldTypeEnum.SWITCH,
            fieldSize: 1
          },
        ]}
      />

      <Grid className={classes.container}>
        Aro
      </Grid>

      <Form<FichaTecnica>
        form={form}
        verificaDados
        nextTab
        fields={[
          {
            fieldName: 'diametro.tpDiametro',
            fieldType: FormFieldTypeEnum.SELECT,
            label: 'Tipo',
            fieldSize: 2,
            selectProps: {
              getOptionLabel: opt => opt.label,
              getOptionValue: opt => opt.value,
              placeholder: 'Selecione...',
              options: options,
              onChangeValue: handleChangeTpDiametro
            }
          },
          {
            fieldName: 'diametro',
            label: 'Diâmetro',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            fieldSize: 1,
            autoCompleteProps: {
              getLabel: opt => opt.nrDiametro ?? '',
              getValue: opt => opt.idDiametro ?? '',
              searchField: 'nrDiametro',
              orderField: 'nrDiametro',
              staticSearchParams: ',stDiametro:1',
              PaperSuggestionsProps: { className: 'suggestions-xs-1' },
              onChangeValue: handleChangeDiametro,
              suggestions: dadosFiltrados,
            }
          },
          {
            fieldName: 'diametro.dsDiametro',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            label: 'Descrição',
            fieldSize: 4
          },
          {
            fieldName: 'diametro.nrFatias',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            label: 'Rendimento Aprox. (fatias)',
            fieldSize: 2
          }
        ]}
      />

      <Grid className={classes.container}>
        Rendimento
      </Grid>

      <Form<FichaTecnica>
        form={form}
        verificaDados
        nextTab
        key={key + 1}
        fields={[
          {
            fieldName: 'nrQtdeRendimento',
            fieldType: FormFieldTypeEnum.TEXTFIELD,
            label: 'Quantidade',
            fieldSize: 2,
            textFieldProps: {
              type: 'number',
            }
          },
          {
            fieldName: 'unidadeMedidaRendimento',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            label: 'Un. Medida',
            fieldSize: 1,
            autoCompleteProps: {
              getLabel: opt => opt.sgUnidadeMedida,
              getValue: opt => opt,
              genericApi: unidadeMedidaApi,
              searchField: 'dsUnidadeMedida',
              orderField: 'dsUnidadeMedida',
              staticSearchParams: ',stUnidadeMedida:1',
              PaperSuggestionsProps: { className: 'suggestions-xs-1' },
            }
          }
        ]}
      />
    </section>
  );
}

export default PrincipalFichaTecnica;
