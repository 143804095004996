import { makeStyles } from '@mui/styles';
import {
  menuLateralMaxWidth,
  menuLateralMinWidth,
} from '../components/menulateral/menu.styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente principal da aplicação
 */
const useStyles = makeStyles((theme: any) => ({
  main: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
  },
  containerToolbarAndRoutes: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: `calc(100vw - ${menuLateralMinWidth}px)`,
    minWidth: `calc(100vw - ${menuLateralMaxWidth}px)`,
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${toolbarHeight}px)`,
  },
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  containerDataTable: {
    display: 'flex',
    flex: 1,
  },
  smallDataTable: {
    display: 'flex',
    maxHeight: 200,
  },
  titleContainer: {
    maxHeight: 44,
    padding: '12px 25px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 18,
    color: '#000000',
    fontWeight: 400,
    width: '100%',
  },
  content: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: 25,
    flex: 1,
  },
  tabContent: {
    padding: '25px 21px 5px',
    flex: 1,
    overflow: 'hidden',
  },
  formWithTableContainer: {
    padding: '25px 21px 5px',
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '90%'
  },
  switchContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all .8s ease-in-out'
  },
  label: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  sectionTable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%'
  },
  gridCell: {
    paddingRight: 25,
    height: 76,
    transition: 'all .2s ease-in-out'
  },
  chooseImage: {
    borderRadius: `16px !important`,
    backgroundColor: `${theme.palette.primary.dark} !important`,
    color: '#FFFFFF',
    height: 35,
    alignItems: 'center',
    display: 'flex',
    width: 150,
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 15,
    cursor: 'pointer'
  },
  fileLabel: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontFamily: 'Roboto',
    margin: '0 0 0 8px',
    padding: 0,
    alignSelf: 'center'
  },
  container: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 16,
    padding: '25px 0 12px',
    color: `${theme.palette.primary.dark}`,
    marginBottom: '10px !important'
  },
  icone: {
    height: 21,
    width: 21,
    filter: 'invert(40%) sepia(12%) saturate(733%) hue-rotate(183deg) brightness(91%) contrast(89%)'
  },
  iconeSelecionado: {
    filter: 'invert(17%) sepia(32%) saturate(2955%) hue-rotate(205deg) brightness(95%) contrast(92%)'
  },
  iconDisabled: {
    filter: 'brightness(0) saturate(100%) invert(73%) sepia(8%) saturate(22%) hue-rotate(350deg) brightness(95%) contrast(80%)'
  },
  totalPedido: {
    foontSize: '18px',
    fontWeight: 600,
    color: '#213670'
  },
  readOnly: {
    opacity: '1.5 !important',
    '& input': {
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.7) !important',
    },
    '& textarea': {
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.7) !important',
    },
  },
  textError: {
    color: `${theme.palette.error.light} !important`,
    fontSize: 14,
    fontWeight: 'bold !important'
  },
  textHighlight: {
    WebkitTextFillColor: `${theme.palette.primary.light}`,
    fontSize: 14,
    fontWeight: 'bold !important'
  },
  labelTabela: {
    fontSize: 16,
    fontWeight: 'bold !important',
    color: `${theme.palette.primary.dark} !important`,
    padding: '25px 0 0 25px'
  },
  cardImage: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 30
  },
  fileName: {
    marginTop: 5,
    marginBottom: 15,
    marginLeft: 5,
    color: 'rgb(0, 0, 0, 0.5)',
    fontSize: 12,
  },
  cardUpload: {
    height: 300,
    width: 300,
    fontWeight: 'bold',
    marginRight: 15,
    display: 'flex',
    flexDirection: 'column',
    border: '1px dashed #707070',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#707070'
  },
  imageLower: {
    height: 112,
    width: 127,
    objectFit: 'contain'
  },
  iconLoading: {
    width: 200,
    marginBottom: 20
  },
  cardIconLoading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#484848'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px',
  },
  button: {
    margin: '0px 10px !important',
    color: `${theme.palette.secondary.main} !important`
  },
  simpleSection: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: '25px 0px',
    margin: '0 auto',
    marginBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    alignItems: 'center',
    '& div': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 12.5
    }
  },
}));

export { useStyles };
