import React from 'react';
import CardFiltersBase, {
  CardFiltersBasePropTypes
} from '@bubotech/sumora-react-components/lib/cardfilters';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import { MainStateType } from 'root-states';
import FilterModel from '@bubotech/sumora-react-components/lib/cardfilters/models/filter';
import OrderModel from '@bubotech/sumora-react-components/lib/cardfilters/models/order';
import CardFiltersActions from 'root-states/actions/card-filters-actions';
import ScreensKeysEnum from './screens-keys';

import './cardfilters.styles.css';

export type CardFiltersPropType = CardFiltersBasePropTypes & {
  /**
   * Key usada para diferenciar o componente CardFilters de cada tela.
   * (Obs: deve ser única(cada tela deve ter a sua) e constante no sistema)
   */
  screenKey: ScreensKeysEnum;
};

/**
 * Componente para encapsular o CardFiltersBase.
 * Gerencia a parte de salvar os filtros e ordenações quando o componente desmonta
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param {CardFiltersBasePropTypes} props
 */
function CardFilters(props: CardFiltersPropType): JSX.Element {
  const { screenKey } = props;

  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const stateFilters = useSelector<MainStateType, FilterModel[]>(
    state => state.cardFiltersReducer.data[screenKey]?.filters
  );
  const stateOrders = useSelector<MainStateType, OrderModel[]>(
    state => state.cardFiltersReducer.data[screenKey]?.orders
  );
  const appLayoutActions = new CardFiltersActions(useDispatch<Dispatch<DispatchAction>>());

  return (
    <CardFiltersBase
      {...props}
      disabled={isLoading}
      selectPlaceholder='Selecione...'
      emptyFilterChipLabel='Nenhum filtro selecionado'
      expandedIconLabel='Filtros'
      titleExpanded=''
      filtersInitial={stateFilters}
      ordersInitial={stateOrders}
      AccordionDetailsProps={{
        className: 'cardFilters'
      }}
      onUnmount={(filters, orders) =>
        appLayoutActions.persistFiltersAndOrders({ screenKey, filters, orders })
      }
    />
  );
}

export default CardFilters;
