import GenericApi from 'root-resources/generic-api';
import Aro from '../model/aro';
import { AxiosResponse } from 'axios';

/**
 * API de aro, diametro
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @extends {RootApi}
 */
class DiametroAPI extends GenericApi<Aro> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao aro, diametro
   */
  public constructor() {
    super({ baseEndpoint: 'diametro' });
  }

  public async findAllDiametro(): Promise<AxiosResponse<Aro[]>> {
    return this.api.get<Aro[]>('/get-all');
  }
}

export default DiametroAPI;
