/**
 * Enum de situação
 *
 * @author davi takayama
 */
 enum SituacaoProdutoEnum {
  INATIVO,
  ATIVO,
  DESCONTINUADO
}

export default SituacaoProdutoEnum;
