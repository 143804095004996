import { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import AppMainLayout from 'root-views';
import ConfirmarCadastro from 'root-views/confirmarcadastro/confirmar-cadastro';
import ConfirmarConta from 'root-views/confirmarconta/confirmar-conta';
import Login from 'root-views/login/login';
import RecuperarSenha from 'root-views/recuperarsenha/recuperar-senha';
import AlterarEmpresa from './alterarempresa/views/alterar-empresa';

function Redirect({ to }: any) {
  let navigate = useNavigate();
  const navigateAux = useCallback(navigate, [navigate]);
  useEffect(() => {
    navigateAux(to);
  }, [navigateAux, to]);
  return null;
}
/**
 * Cria e declara as rotas principais do App
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 */
function createRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Redirect to='/dashboard' />} />
        <Route path='/login' element={<Login />} />
        <Route path='/alterar-empresa' element={<AlterarEmpresa />} />
        <Route path='/resetar-senha' element={<RecuperarSenha />} />
        <Route path='/confirmar-cadastro' element={<ConfirmarCadastro />} />
        <Route path='/confirmar-conta/:id' element={<ConfirmarConta />} />
        <Route path='/*' element={<AppMainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default createRoutes;
