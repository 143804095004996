import React, { useState, useRef } from 'react';
import Sweetalert from 'sweetalert2';
import { useTheme } from '@mui/material';
import Swal from 'root-components/swal/swal';
import { LoadingSwal } from 'root-components';
import AddIcon from '@mui/icons-material/Add';
import { useStyles } from 'root-views/app.styles';
import withReactContent from 'sweetalert2-react-content';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import CategoriaNutricionalAPI from "../resource/categorianutricional";
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { DataTable, GroupButtonGrid, ButtonFAB } from '@bubotech/sumora-react-components';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

// Models
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import CategoriaNutricional from "../model/categorianuticional";

// Redux
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';

// Utils
import { useNavigate } from 'react-router-dom';
import { buildQuery } from 'root-utils/build-query';

export type CategoriaNutricionalProps = {};

/**
 * Tela de listagem de Categoria Nutricional
 * 
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {CategoriaNutricionalProps} props
*/
function CategoriaNutricionais(props: CategoriaNutricionalProps): JSX.Element {
	const theme = useTheme();
	const classes = useStyles();
	const history = useNavigate();
	const SwalClose = withReactContent(Sweetalert);
	const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

	const api = new CategoriaNutricionalAPI();
	const [pageData, setPageData] = useState<Resposta>();
	const [selected, setSelected] = useState<CategoriaNutricional | undefined>();
	const params = useRef<Busca>({ page: 1, orderField: 'nrOrdem', orderType: 'ASC' });
	const [dataFiltered, setDataFiltered] = useState<CategoriaNutricional[]>([]);
	const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

	useComponentDidMount(() => {
		appLayoutActions.setTitleToolbar('Categoria Nutricional');
		if (ScreensKeysEnum.CATEGORIA_NUTRICIONAL in filtros) {
			const staticFields = buildQuery(filtros[ScreensKeysEnum.CATEGORIA_NUTRICIONAL].filters, [], []);
			params.current = { ...params.current, staticParams: staticFields };
		}
		loadData();
	});

	const handleFilterChange = (data: any[]) => {
		const staticFields = buildQuery(data, [], []);
		params.current = { ...params.current, staticParams: staticFields };
		loadData();
	};

	const handleClickEdit = () => {
		if (!selected) return;
		history(`/cadastros/categoria-nutricional/editar/${selected.idCategoriaNutricional}`);
	};

	const handleChangePage = (page: number) => {
		params.current = { ...params.current, page: page + 1 };
		loadData();
	};

	const handleChangeOrder = (order: any[]) => {
		if (order.length) params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
		else params.current = { ...params.current, orderField: 'nrOrdem', orderType: 'ASC' };
		loadData();
	};

	function loadData() {
		appLayoutActions.setLoading(true);
		api.findByPage(params.current)
			.then(res => {
				setPageData(res.data);
				setDataFiltered(res.data.data as CategoriaNutricional[]);
			})
			.catch(() => {
				setDataFiltered([]);
				Swal({
					showConfirmButton: false,
					showCancelButton: true,
					cancelButtonText: 'Ok',
					title: 'Ocorreu um erro',
					text: 'Falha ao carregar dados',
					icon: 'error',
				});
			})
			.finally(() => appLayoutActions.setLoading(false));
	}

	function onDragEnd(list: CategoriaNutricional[], e: any, overIndex: number, fromIndex: number) {
		const item = pageData?.data[fromIndex];

		if (item) {
			LoadingSwal({ text: 'Carregando' });

			api.updateNrOrdem({...item, nrOrdem: overIndex + 1}).then(res => {
				setPageData({...pageData, data: res.data});
				setDataFiltered(res.data);
				SwalClose.close();
			}).catch(() => {
				Swal({
					text: 'Não foi possível reordenar a categoria',
					title: 'Erro',
					showConfirmButton: true,
					icon: 'error'
				});
			});
		}
	}

	return (
		<main className={classes.rootListagem}>
			<section id='filtros'>
				<CardFilters
					screenKey={ScreensKeysEnum.CATEGORIA_NUTRICIONAL}
					data={pageData?.data ?? []}
					onLoadData={loadData}
					onRefreshFilters={setDataFiltered}
					changeFilters={handleFilterChange}
					filtersDefinitions={[
						{
							field: 'nrOrdem',
							label: 'Ordem',
							type: TipoFiltroEnum.NUMBER,
						},
						{
							field: 'nmCategoriaNutricional',
							label: 'Nome',
							type: TipoFiltroEnum.STRING
						},
					]}
				/>
			</section>

			<section id='tabela' className={classes.sectionTable}>
				<GroupButtonGrid
					showAdd={false}
					showDelete={false}
					showEdit
					buttonEditProps={{ disabled: !selected, disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
					onClickEdit={handleClickEdit}
				/>
				<div className={`${classes.containerDataTable} tabela`}>
					<DataTable<CategoriaNutricional>
						columns={[
							{
								field: 'nrOrdem',
								headerName: 'Ordem',
								col: 2,
								rowDrag: true
							},
							{
								field: 'nmCategoriaNutricional',
								headerName: 'Nome',
								col: 10
							}

						]}
						data={dataFiltered}
						onSelectRow={setSelected}
						onChangePage={handleChangePage}
						onOrderChange={handleChangeOrder}
						rowsPerPageEnabled={false}
						enableDrag={true}
						onRowDragEnd={onDragEnd}
						paginationConfigs={{
							labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
							count: pageData?.totalRegistros ?? 0
						}}
					/>
				</div>

				<ButtonFAB onClick={() => history('/cadastros/categoria-nutricional/novo')}>
					<AddIcon />
				</ButtonFAB>
			</section>

		</main>
	);
}

export default CategoriaNutricionais;
