/**
 * Enum de status de upload de produo
 *
 * @author Marcos Davi
 */
enum StatusUploadEnum {
  EM_IMPORTACAO,
  FINALIZDO,
  FINALIZADO_ERRO
}

export default StatusUploadEnum;
