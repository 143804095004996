import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

/**
 * Estilo do upload produto
 */
const useStyles = makeStyles((theme: Theme) => ({
    tabContent: {
        padding: '25px 21px 5px',
        flex: 1,
        overflow: 'hidden',
    },
    container: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        fontSize: 16,
        color: `${theme.palette.primary.main}`,
    },
    titleContainer: {
        maxHeight: 44,
        padding: '12px 25px',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        fontSize: 18,
        color: '#000000',
        fontWeight: 400,
        width: '100%',
    },
    containerDataTable: {
        display: 'flex',
        flex: 1,
    },
    uploadButtonContainer: {
        padding: '25px 0px 25px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    uploadButton: {
        marginRight: '10px',
        width: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 15,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '90%',
        backgroundSize: '80px',
        cursor: 'pointer',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.6)'
    },
    uploadText: {
        color: 'white',
        alignSelf: 'center'
    },
    nomeArquivo: {

    },
    content: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        padding: 25,
        flex: 1,
    },
    sectionTable: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%'
    },
    simpleSection: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        padding: '0 25px',
        margin: '0 auto',
        marginBottom: 20,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        alignItems: 'center'
    },
    chooseImage: {
        borderRadius: `16px !important`,
        backgroundColor: `${theme.palette.primary.dark} !important`,
        color: '#FFFFFF',
        height: 35,
        alignItems: 'center',
        display: 'flex',
        width: 150,
        justifyContent: 'center',
        marginTop: 15,
        marginBottom: 15,
        cursor: 'pointer'
    },
    fileName: {
        marginTop: 5,
        marginBottom: 15,
        marginLeft: 5,
        color: 'rgb(0, 0, 0, 0.5)',
        fontSize: 12,
    }
}))

export { useStyles }
