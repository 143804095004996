import GenericApi from 'root-resources/generic-api';
import CorProduto from '../model/corproduto';

/**
 * API de Cor de Produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @extends {RootApi}
 */
class CorProdutoAPI extends GenericApi<CorProduto> {
  /**
   * Injeta a instância do axios com o endpoint base referente as cores de produto
   */
  public constructor() {
    super({ baseEndpoint: 'cor-produto' });
  }
}

export default CorProdutoAPI;
