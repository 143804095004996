import React from 'react';
import { useStyles } from './cabecalho.styles';
import Card from '@bubotech/sumora-react-components/lib/card';
import Logo from 'root-images/logo_projeto.png';

export type CabecalhoPropType = {};

/**
 * Componente para um cabeçalho com logo
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param {CabecalhoPropType} props
 */
function Cabecalho(props: CabecalhoPropType): JSX.Element {
  const classes = useStyles(props);

  return (
    <Card className={classes.card} contentContainerDivProps={{ style: { padding: 0 } }}>
      <div className={classes.container}>
        <img src={Logo} className={classes.logo} alt='Logomarca'/>
      </div>
    </Card>
  );
}

export default Cabecalho;
