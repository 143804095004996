import Cest from 'root-models/fiscal/cest';
import GenericApi from '../generic-api';
/**
 * API de cest
 *
 * @author davi takayama <carlos.bageston@kepha.com.br>
 * @extends {FISCALAPI}
 */
class CESTAPI extends GenericApi<Cest> {
  /**
  * Injeta a instância do axios com o endpoint base referente a cest
  */
  public constructor() {
    super({ baseEndpoint: 'cest' });
  }
}

export default CESTAPI;