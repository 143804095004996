import { makeStyles } from '@mui/styles'

/**
 * Estilos do componente modal
 */
const useStyles = makeStyles((theme: any) => ({
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  
  '@keyframes dash': {
    '0%': {
      strokeDasharray: [1, 50],
      strokeDashoffset: 0,
    },
    '50%': {
      strokeDasharray: [90, 150],
      strokeDashoffset: -35,
    },
    '100%': {
      strokeDasharray: [90, 150],
      strokeDashoffset: -124
    }
  },
  modal: {
    display: 'flex', 
    flex: 1, 
    justifyContent: 'center'
  },
  content: {
    borderRadius: 10,
    padding: '24px 24px 24px 24px',
    backgroundColor: '#FFF',
    position: 'absolute',
    alignSelf: 'center',
    maxWidth: '466px',
    maxHeight: '268px',
    overflow: 'hidden',
    display: 'flex'
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '18px',
    color: `${theme.palette.grey['700']}`,
    marginTop: '10px'
  },
  options: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px'
  },
  option: {
    borderRadius: '16px !important',
    color: '#FFF !important',
    padding: '5px 15px !important',
    margin: '0px 3px !important',
    fontFamily: 'Open Sans !important'
  },
  loading: {
    alignSelf: 'center',
    animation: `$rotate 2s linear infinite`,
    width: '50px',
    height: '50px',
    '& .path': {
      stroke: '#223771',
      strokeLinecap: 'round',
      animation: '$dash 1.5s ease-in-out infinite'
    },    
  },
  infoIcon: {
    width: '35px', 
    paddingTop: '10px'
  },
  optionsDescription: {
    borderTop: `1px solid ${theme.palette.grey['900']}`,
    marginTop: '23px'
  }
}))

export { useStyles }
