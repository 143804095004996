import Sweetalert from 'sweetalert2';
import { LoadingSwal, Swal } from "root-components";
import withReactContent from "sweetalert2-react-content";
import ReportGeneralAPI from '../../resources/report-api';
import { StateProps } from 'root-views/pdfviewer/pdf-viewer';

const SwalClose = withReactContent(Sweetalert);
const reportApi = new ReportGeneralAPI();

function handleClickReport(id: string, produto: string, notIncludeModoPreparo: boolean, navigate?: (data: any, state: { state: StateProps }) => void) {
  LoadingSwal({ text: 'Carregando' });
  
  reportApi.fetchReportFichaTecnica(id ?? '', notIncludeModoPreparo).then((res: any) => {
    SwalClose.close();
    navigate && navigate(`/cadastros/pdf/${id}`, { state: { data: res.data, from: `ficha-técnica-${produto}` } })
  }).catch(() => {
    SwalClose.close();
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: 'Ocorreu um erro',
      text: 'Erro ao buscar relatório',
      icon: 'error',
    });
  })
  
}

export { handleClickReport };