import { ButtonFABMenu, Switch, TextField } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from 'root-views/app.styles';
import * as Yup from 'yup';
import EtapaModel from '../model/etapa';
import EtapaAPI from '../resource/etapa';

/**
 * Tipo dos valores do formik
 */
export type EditarEtapaFormikValuesType = {
  idEtapa?: string;
  nmEtapa: string;
  stEtapa: SituacaoEnum;
};

export type EditarEtapaPropType = {};

/**
 * View de edição de uma etapa
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param {EditarEtapaPropType} props
 */
function EditarEtapas(props: EditarEtapaPropType): JSX.Element {
  const classes = useStyles();
  const history = useNavigate();
  const { id }: Readonly<Params<string>> = useParams();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

  const api = new EtapaAPI();

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] =
    useState<EditarEtapaFormikValuesType>({
      nmEtapa: '',
      stEtapa: 1
    } as EditarEtapaFormikValuesType);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, dirty } =
    useFormik<EditarEtapaFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: true,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        nmEtapa: Yup.string().required('Campo obrigatório'),
        stEtapa: Yup.boolean().default(true).required('Campo obrigatório'),
      }),
      onSubmit: handleSubmitFormik,
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(id ? 'Edição de Etapa' : 'Cadastro de Etapa');
    if (id) setEnableReinitialize(true);
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);
    api.findById(id).then(res => {
      setInitialValues(res.data as EtapaModel);
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    });
    // eslint-disable-next-line
  }, [enableReinitialize]);

  useVerificaDados({
    funcaoPrincipalProps: { dirty },
    handleSubmit,
    ignoreTabChange: true
  });

  return (
    <main style={{ height: '100%' }}>
      <section id='titulo-pagina'>
        <div className={classes.titleContainer}>Etapa</div>
      </section>

      <section id='formulario'>
        <Grid container className={classes.content}>
          <Grid item xs={6} className={classes.gridCell}>
            <TextField
              label={'Nome'}
              name={'nmEtapa'}
              inputProps={{ maxLength: 999 }}
              value={values.nmEtapa}
              error={touched.nmEtapa && errors.nmEtapa !== undefined}
              helperText={touched.nmEtapa && errors.nmEtapa !== undefined ? `${errors.nmEtapa}` : ''}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          </Grid>

          <Grid item className={classes.switchContainer}>
            <Switch
              label={'Ativo'}
              checked={values.stEtapa ? true : false}
              defaultChecked
              onBlur={handleBlur}
              onChange={() => setFieldValue('stEtapa', values.stEtapa ? 0 : 1)}
            />
          </Grid>
        </Grid>
      </section>

      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
        }}
        secondaryAction={{
          onClick: () => history('/cadastros/etapa'),
          iconProps: { color: 'inherit' },
        }}
      />
    </main>
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarEtapaFormikValuesType} values - Valores do submit
   */
  async function handleSubmitFormik(values: EditarEtapaFormikValuesType): Promise<void> {
    LoadingSwal({ text: 'Carregando' });
    let error = false;

    if (!id) {
      await api.save(values).catch(e => { error = true; });
    } else {
      await api.update(values).catch(e => { error = true; });
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success',
      });

      history('/cadastros/etapa');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error',
      });
    }
  }
}

export default EditarEtapas;