import FichaTecnica from '../models/fichatecnica';
import GenericApi from 'root-resources/generic-api';
import UploadFicha from 'root-cadastros/uploadFichaTecnica/model/upload-ficha-tecnica';

/**
 * API de Ficha Técnica
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @extends {GenericApi}
 */
class FichaTecnicaAPI extends GenericApi<FichaTecnica> {
  /**
   * Injeta a instância do axios com o endpoint base referente as Fichas Técnicas
   */
  public constructor() {
    super({ baseEndpoint: 'ficha-tecnica' });
  }

  /**
   * 
   * @param idProduto id do Produto
   * @returns se existe ficha técnica desse produto
   */
  public async checkProduto(idProduto: string) {
    return this.api.get(`/check-produto/${idProduto}`);
  }

  /**
   * Importa os dados da ficha técnica a partir do arquivo base64
   * 
   * @param body Corpo da requisição com o base64 da ficha técnica
   * @returns importa ficha técnica
   */
  public async importar(fichaTecnicaBase64: string) {
    return this.api.post('/importar', { fichaTecnicaBase64 });
  }

  /**
   * Confirma a importação da ficha técnica
   * 
   * @param values valores da ficha técnica de importação
   * @returns confirma a importação da ficha técnica
   */
  public async confirmarImportacao(values: Omit<UploadFicha, 'fichaTecnicaBase64'>) {
    return this.api.post<Omit<UploadFicha, 'fichaTecnicaBase64'>>('/salvar', values);
  }

  /**
   * Retorna a visualização da ficha técnica importada
   * 
   * @param idFichaTecnica id da ficha técnica
   * @returns visualização da ficha técnica importada
   */
  public async getImportacao(idFichaTecnica: string) {
    return this.api.get(`/importar/${idFichaTecnica}`);
  }
}

export default FichaTecnicaAPI;
