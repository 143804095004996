import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import { Swal, LoadingSwal } from 'root-components';
import { useParams, Params } from 'react-router-dom';
import CategoriaNutricionalAPI from '../resource/categorianutricional';
import { ButtonFABMenu, TextField } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

// Models
import MensagemErro from 'root-models/error';
import CategoriaNutricional from '../model/categorianuticional';

// Redux
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';

export type EditarCategoriaNutricionalPropType = {};

/**
 * View de edição de Categoria Nutricional
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {EditarCategoriaNutricionalPropType} props
 */
function EditarCategoriaNutricional(props: EditarCategoriaNutricionalPropType): JSX.Element {
  const classes = useStyles();
  const history = useNavigate();
  const { id }: Readonly<Params<string>> = useParams();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

  const api = new CategoriaNutricionalAPI();
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<CategoriaNutricional>({
    nmCategoriaNutricional: '',
    nrOrdem: undefined
  });

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik<CategoriaNutricional>({
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize,
    initialValues,
    validationSchema: Yup.object().shape({
      nrOrdem: Yup.number().required('Campo obrigatório'),
      nmCategoriaNutricional: Yup.string().required('Campo obrigatório')
    }),
    onSubmit: handleSubmitFormik,
  });

  async function getNextNrOrem() {
    try {
      const next = await api.getNextNrOrdem()
    
      if (next.status === 200) setFieldValue('nrOrdem', next.data);
    } catch {
      setFieldValue('nrOrdem', 1);
    }
  }

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Categoria Nutricional');

    if (id) setEnableReinitialize(true);
    else getNextNrOrem()
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api.findById(id).then(res => {
      setInitialValues(res.data);
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <main style={{ height: '100%' }}>
      <section id='titulo-pagina'>
        <div className={classes.titleContainer}>Dados</div>
      </section>

      <section id='formulario'>
        <Grid container className={classes.content}>
          <Grid item xs={1} className={classes.gridCell}>
            <TextField
              name='nrOrdem'
              label='Ordem'
              type={'number'}
              value={values.nrOrdem ?? ''}
              error={touched.nrOrdem && errors.nrOrdem !== undefined}
              helperText={touched.nrOrdem && errors.nrOrdem !== undefined ? `${errors.nrOrdem}` : ''}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={5} className={classes.gridCell}>
            <TextField
              name='nmCategoriaNutricional'
              label='Nome'
              inputProps={{ maxLength: 60 }}
              value={values.nmCategoriaNutricional ?? ''}
              error={touched.nmCategoriaNutricional && errors.nmCategoriaNutricional !== undefined}
              helperText={touched.nmCategoriaNutricional && errors.nmCategoriaNutricional !== undefined ? `${errors.nmCategoriaNutricional}` : ''}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
              onBlur={handleBlur}
            />
          </Grid>

        </Grid>
        <ButtonFABMenu
          disabled={isLoading}
          primaryAction={{ onClick: (e: any) => handleSubmit(e), iconProps: { color: 'inherit' } }}
          secondaryAction={{ onClick: () => history('/cadastros/categoria-nutricional'), iconProps: { color: 'inherit' } }}
        />
      </section>
    </main>
  );

  async function updateList() {
    let error = false;
    LoadingSwal({ text: 'Carregando' });

    if (!id) await api.updateList(values).catch(e => error = true);
    else await api.editList(values).catch(e => error = true);

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success',
      });

      history('/cadastros/categoria-nutricional');
    } else {
      Swal({
        showConfirmButton: true,
        title:'Erro',
        text: `Erro ao ${id ? 'editar' : 'salvar'} categoria.`,
        icon: 'error',
      });
    }
  }

    /**
     * Manipula o evento de submit do Formik
     *
     * @param {CategoriaNutricional} values - Valores do submit
     */
    async function handleSubmitFormik(values: CategoriaNutricional) {
      LoadingSwal({ text: 'Carregando' });

      let error: MensagemErro = { mensagem: '', titulo: '', nome_exception: '', error: false };

      if (!id) {
        await api.save(values).catch(e => {
          error = { ...e.response.data as MensagemErro };
          error.error = true;
        });
      } else {
        await api.update(values).catch(e => {
          error = { ...e.response.data as MensagemErro };
          error.error = true;
        });
      }

      if (!error.error) {
        Swal({
          showConfirmButton: true,
          title: 'Sucesso',
          text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
          icon: 'success',
        });

        history('/cadastros/categoria-nutricional');
      } else {

        if (error.titulo === 'Campo duplicado') {
          await Swal({
            showConfirmButton: true,
            showCancelButton: true,
            title: 'Ordem duplicada',
            text: 'Já existe uma categoria com essa ordem',
            icon: 'error',
            confirmButtonText: 'Atualizar lista',
            cancelButtonText: 'Editar campo',
          }).then(option => {
            if (option.isConfirmed) updateList();
          });
        } else {
          await Swal({
            showConfirmButton: true,
            title: error['titulo'] ?? 'Erro',
            text: error['mensagem'] ?? `Erro ao ${id ? 'editar' : 'salvar'} categoria.`,
            icon: 'error',
          });
        }
      }
    }
}

export default EditarCategoriaNutricional;
