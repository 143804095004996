/**
 * Responsável pela memória das URL's das API's do sistema
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @abstract
 */
abstract class URL {
  /**
   * URL da API de autenticação
   *
   * @static
   */
  public static readonly OAUTH = process.env.REACT_APP_URL_OAUTH;

  /**
   * URL da API principal
   *
   * @static
   */
  public static readonly API = process.env.REACT_APP_PIANI_API_URL_CENTRAL;

  /**
 * URL da API FISCAL
 * 
 * @static
 */
  public static readonly FISCALAPI = process.env.REACT_APP_PIANI_API_URL_FISCAL;

  /**
   * URL da API de Reports do General
   * 
   * @static
   */
  public static readonly REPORTGENERAL = process.env.REACT_APP_PIANI_API_URL_CENTRAL_REPORT_GENERAL;
}

export default URL;
