
import GeneroProduto from '../model/genero-produto';
import GenericApi from 'root-resources/generic-api';

/**
 * API de Gênero de Produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @extends {RootApi}
 */
class GeneroProdutoAPI extends GenericApi<GeneroProduto> {
  /**
   * Injeta a instância do axios com o endpoint base referente aos Gêneros de Produto
  */
  public constructor() {
    super({ baseEndpoint: 'genero-produto' });
  }
}

export default GeneroProdutoAPI;
