import React, { useState, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormikProps } from 'formik';
import { useTheme } from '@mui/material';
import FichaTecnica from '../models/fichatecnica';
import { useStyles } from 'root-views/app.styles';
import { Modal, useVerificaDados } from 'root-components';
//import { useFormHistory } from 'root-components/form/form';
import EtapaFichaTecnica from '../models/etapafichatecnica';
import TipoEtapaEnum from 'root-enumerations/tipo-etapa-enum';
import EditarComposicaoFichaTecnica from './editar-composicao';
import { DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components';
import { useKeyboardControls } from 'root-utils/hooks/use-keyboard-controls';
import SituacaoRegistroEnum from 'root-enumerations/situacao-registro';


interface ComposicaoFichaTecnicaProps {
  form: FormikProps<FichaTecnica>;
  hideFab: (hide: boolean) => void;
  setGlobalDirty: (dirty: boolean) => void;
}

/**
 * Lista de composição de uma ficha técnica
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {ComposicaoFichaTecnicaProps} props 
 */
function ComposicaoFichaTecnica(props: ComposicaoFichaTecnicaProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { form, hideFab, setGlobalDirty } = props;

  const [key, setKey] = useState<number>(0);
  const [edit, setEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<EtapaFichaTecnica>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const commonButtonProps = {
    backgroundColor: theme.palette.primary.main,
    disabledColor: theme.palette.grey['100'],
  };

  const etapasAtivas = useMemo(() => {
    let newEtapas = form.values.produtoEtapaList.filter(etapa => etapa.stRegistro !== 2);
    return newEtapas;
  }, [form.values.produtoEtapaList]);

  function handleAdd() {
    setSelected(undefined);
    setEdit(true);
    hideFab(true);
    setSelectedIndex(-1);
  }

  function handleEdit() {
    if (!selected && selectedIndex === -1) return;

    setSelected(selectedIndex !== -1 ? etapasAtivas[selectedIndex] : selected);
    setEdit(true);
    hideFab(true);
  }

  function handleDelete() {
    if (!selected && selectedIndex === -1) return;

    let novasEtapas = [...form.values.produtoEtapaList];
    let compare = selected ?? etapasAtivas[selectedIndex];
    const index = form.values.produtoEtapaList.findIndex(etapa => etapa.idProdutoEtapa === compare.idProdutoEtapa);

    if (index > -1) {
      novasEtapas[index].stRegistro = 2;
      novasEtapas = novasEtapas.map(etapa => {
        const predecessoraIndex = etapa.produtoEtapasPredecessoras.findIndex(predecessora => predecessora.idProdutoEtapa === novasEtapas[index].idProdutoEtapa);
        if (predecessoraIndex !== -1) {
          etapa.produtoEtapasPredecessoras[predecessoraIndex].stRegistro = 2;
          etapa.produtoEtapasPredecessoras = etapa.produtoEtapasPredecessoras.concat(novasEtapas[index].produtoEtapasPredecessoras);
        }

        return etapa;
      });
    }

    let nrOrdem = 1;

    const novasEtapasNrOrdem = novasEtapas.map((item) => {
      if (item.stRegistro === SituacaoRegistroEnum.DELETE) {
        return { ...item, nrOrdem: 0 };
      } else {
        const newItem = { ...item, nrOrdem };
        nrOrdem++;
        return newItem;
      }
    });

    form.setFieldValue('produtoEtapaList', novasEtapasNrOrdem);
    setSelectedIndex(-1);
    setShowDelete(false);
  }

  function updatePredecessoras(etapa: EtapaFichaTecnica, list: EtapaFichaTecnica[]) {
    etapa.produtoEtapasPredecessoras?.forEach(predecessora => {
      list = list.map((etapaFicha) => {
        let predecessoras = [...etapaFicha.produtoEtapasPredecessoras];
        const index = predecessoras.findIndex(predecessoraEtapaFicha => predecessoraEtapaFicha.idProdutoEtapa === predecessora.idProdutoEtapa);

        if (index === -1 || etapaFicha.tpEtapa === TipoEtapaEnum.CONCORRENTE || etapa.tpEtapa === TipoEtapaEnum.CONCORRENTE) return etapaFicha;
        predecessoras[index] = etapa;

        return {
          ...etapaFicha,
          fichaTecnica: null,
          produtoEtapasPredecessoras: predecessoras
        } as EtapaFichaTecnica;
      });
    });

    return list;
  }

  function handleSubmitComposicaoFicha(values: EtapaFichaTecnica) {
    let etapasFicha = [...form.values.produtoEtapaList];
    if (!selected && selectedIndex === -1) {
      const novaEtapa = { ...values, idProdutoEtapa: uuidv4() };

      etapasFicha = updatePredecessoras(novaEtapa, etapasFicha);
      form.setFieldValue('produtoEtapaList', [...etapasFicha, novaEtapa]);
    } else {
      const index = form.values.produtoEtapaList.findIndex(composicaoFicha => JSON.stringify(composicaoFicha) === JSON.stringify(selected));

      const currentSelection = selectedIndex !== -1 ? etapasAtivas[selectedIndex] : selected;
      if (JSON.stringify(values.produtoEtapasPredecessoras) !== JSON.stringify(currentSelection?.produtoEtapasPredecessoras)) {
        etapasFicha = updatePredecessoras(values, etapasFicha);
      }

      if (index > -1) etapasFicha[index] = values;
      form.setFieldValue('produtoEtapaList', etapasFicha);
    }
    setGlobalDirty(true);

    setSelected(undefined);
    setEdit(false);
    hideFab(false);
    setSelectedIndex(-1);
    // clearFutureStack();
  }

  useVerificaDados({ funcaoPrincipalProps: { dirty: !edit ? form.dirty : false }, handleSubmit: form.handleSubmit, ignoreTabChange: true });

  //const { clearFutureStack } = useFormHistory(form, !edit && !showDelete);

  const handleTab = useCallback(() => {
    const newIndex = selectedIndex + 1;

    if (newIndex < etapasAtivas.length) {
      setSelectedIndex(newIndex);
      setSelected(etapasAtivas[newIndex]);
    } else {
      setSelected(etapasAtivas[0]);
      setSelectedIndex(0);
    }

    setKey(Math.random());
  }, [selectedIndex, etapasAtivas, setSelectedIndex, setSelected]);

  useKeyboardControls(
    [
      {
        keys: ['Tab'],
        onPress: handleTab
      },
      {
        keys: ['Enter'],
        onPress: handleEdit
      },
      {
        keys: ['Backspace', 'Delete'],
        onPress: () => {
          if (selected || selectedIndex !== -1) {
            !selected && setSelected(etapasAtivas[selectedIndex]);
            setShowDelete(true);
          }
        }
      }
    ],
    !edit && !showDelete,
    form.values.historicoList.length !== 0,
    true,
    () => handleAdd()
  );

  const tableColumns = useMemo(() => ([
    {
      field: 'nrOrdem',
      headerName: 'Número',
      col: 1
    },
    {
      field: 'etapa.tpEtapa',
      headerName: 'Tipo Etapa',
      col: 1,
      valueGetter: (params: any) => {

        switch (params.data.tpEtapa) {
          case TipoEtapaEnum.INICIAL:
            return 'Inicial';
          case TipoEtapaEnum.SEQUENCIAL:
            return 'Sequencial';
          case TipoEtapaEnum.CONCORRENTE:
            return 'Concorrente';
          case TipoEtapaEnum.FINAL:
            return 'Final';
          default:
            return '-';
        }
      }
    },
    {
      field: 'etapa.nmEtapa',
      headerName: 'Etapa do Processo',
      col: 2
    },
    {
      field: 'dsModoPreparo',
      headerName: 'Modo de Preparo',
      col: 8,
      valueGetter: (params: any) => {
        return params.data.dsModoPreparo ? params.data.dsModoPreparo.replace(/\n[\s\S]*/, '...') : '';
      }
    }
  ]), []);

  return (
    <section id='lista-composicao' className={classes.sectionTable}>
      {!edit ?
        <>
          <GroupButtonGrid
            showAdd
            showEdit
            showDelete
            onClickAdd={handleAdd}
            onClickEdit={handleEdit}
            onClickDelete={() => setShowDelete(true)}
            buttonAddProps={commonButtonProps}
            buttonEditProps={{ ...commonButtonProps, disabled: !Boolean(selected || selectedIndex !== -1) }}
            buttonDeleteProps={{ disabledColor: theme.palette.grey['100'], disabled: !Boolean(selected || selectedIndex !== -1) }}
          />
          <div className={`${classes.containerDataTable} tabela`}>
            <DataTable<EtapaFichaTecnica>
              key={key}
              initialSelected={selected}
              columns={tableColumns}
              onSelectRow={setSelected}
              rowsPerPageEnabled={false}
              data={etapasAtivas}
            />
          </div>
          <Modal
            open={showDelete}
            title='Clicando em EXCLUIR você estará removendo esse item da lista.'
            message='Tem certeza que deseja realizar esta ação?'
            onFinishLabel='EXCLUIR'
            onCloseLabel='CANCELAR'
            onClose={() => setShowDelete(false)}
            onFinish={handleDelete}
          />
        </>
        :
        <EditarComposicaoFichaTecnica onSubmit={handleSubmitComposicaoFicha} handleCancel={() => { setEdit(false); setSelectedIndex(-1); setSelected(undefined); hideFab(false); }} initialValues={selected} etapas={etapasAtivas} />
      }
    </section>
  );
}

export default ComposicaoFichaTecnica;