import { makeStyles } from '@mui/styles';

/**
 * Estilos do read only
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 **/
const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(239, 243, 248, 0.5)',
        paddingLeft: '4.5px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: '11px',
        fontWeight: 'normal',
        minHeight: '34px'
    },
    title: {
        marginBottom: '6px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    value: {
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgba(0, 0, 0, 0.75)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

export { useStyles };