import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DispatchAction } from 'root-states/root-dispatcher'
import AppLayoutActions from 'root-states/actions/app-layout-actions'
import { Dispatch } from 'redux'
import CardFilters from 'root-components/cardfilters/card-filters'
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum'
import DataTable from '@bubotech/sumora-react-components/lib/datatable'
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid'
import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab'
import SituacaoEnum from 'root-enumerations/situacao-enum'
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import Swal from 'root-components/swal/swal'
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys'
import { useTheme } from '@mui/material'
import { MainStateType } from 'root-states'
import { useStyles } from 'root-views/app.styles';
import { TableOrder } from 'root-models/table-order';
import { buildQuery } from 'root-utils/build-query';
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import UnidadeMedidaAPI from '../resources/unidademedida';
import UnidadeMedida from '../model/unidade-medida';

export type UnidadesMedidaPropType = {}

/**
 * Tela de listagem de Unidades de Medida
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param {UnidadesMedidaPropType} props
 */
function UnidadesMedida(props: UnidadesMedidaPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>())

  const theme = useTheme()
  const history = useNavigate()
  const classes = useStyles()

  const api = new UnidadeMedidaAPI()
  const [pageData, setPageData] = useState<Resposta>()
  const params = useRef<Busca>({ page: 1, orderField: 'dsUnidadeMedida', orderType: 'ASC' })
  const [dataFiltered, setDataFiltered] = useState<UnidadeMedida[]>([])
  const [selected, setSelected] = useState<UnidadeMedida | undefined>()
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Cadastro de Unidade de Medida')
    if (ScreensKeysEnum.UNIDADE_MEDIDA in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.UNIDADE_MEDIDA].filters, ['stUnidadeMedida'], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  })

  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData()
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'dsUnidadeMedida', orderType: 'ASC' };

    loadData();
  }

  function handleChangeFilters(data: any[]) {
    const staticFields = buildQuery(data, ['stUnidadeMedida'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }
  
  function loadData() {
    appLayoutActions.setLoading(true)
    api.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as UnidadeMedida[]);
      })
      .catch(() => {
        setDataFiltered([])
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        })
      })
      .finally(() => appLayoutActions.setLoading(false))
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.UNIDADE_MEDIDA}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          changeFilters={handleChangeFilters}
          onRefreshFilters={setDataFiltered}
          filtersDefinitions={[
            {
              field: 'dsUnidadeMedida',
              label: 'Descrição',
              type: TipoFiltroEnum.STRING,
            },
            {
              field: 'sgUnidadeMedida',
              label: 'Sigla',
              type: TipoFiltroEnum.STRING,
            },
            {
              field: 'stUnidadeMedida',
              label: 'Status',
              type: TipoFiltroEnum.ENUM,
              enumOptions: [
                {
                  label: 'Ativo',
                  value: SituacaoEnum.ATIVO,
                },
                {
                  label: 'Inativo',
                  value: SituacaoEnum.INATIVO,
                },
              ],
            },
          ]}
        />
      </section>

      <section className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          disableEdit={!selected}
          buttonEditProps={{ disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
          onClickEdit={() => {
            if (!selected) return
            history(`/cadastros/unidade-medida/editar/${selected.idUnidadeMedida}`)
          }}
        />

        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<UnidadeMedida>
            columns={[
              {
                field: 'dsUnidadeMedida',
                headerName: 'Descrição',
                col: 4,
              },
              {
                field: 'sgUnidadeMedida',
                headerName: 'Sigla',
                col: 7,
              },
              {
                valueGetter: (node) => node.data.stUnidadeMedida === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo',
                col: 1,
                headerName: 'Status',
                field: 'stUnidadeMedida',
              },
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handleChangePage}
            onOrderChange={handleChangeOrder}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/unidade-medida/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  )
}

export default UnidadesMedida;
