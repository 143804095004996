import Dashboard from 'root-views/dashboard/dashboard';
import Perfil from 'root-views/perfil/perfil';
import PDFView from './pdfviewer/pdf-viewer';
import Aros from 'root-cadastros/aro/views/aro';
import Etapas from '../cadastros/etapa/views/etapa';
import Marcas from '../cadastros/marca/views/marca';
import Produtos from '../cadastros/produto/views/produto';
import Alergenos from 'root-cadastros/alergenos/view/alergenos';
import EditarEtapas from '../cadastros/etapa/views/editar-etapa';
import EditarMarcas from '../cadastros/marca/views/editar-marca';
import CoresProdutos from '../cadastros/corproduto/views/cor-produto';
import EditarProduto from '../cadastros/produto/views/editar-produto';
import FichaTecnica from 'root-cadastros/fichatecnica/views/ficha-tecnica';
import EditarAlergenos from 'root-cadastros/alergenos/view/editarAlergenos';
import UnidadesMedida from '../cadastros/unidademedida/views/unidade-medida';
import UploadProduto from 'root-cadastros/uploadproduto/views/upload-produto';
import EditarCoresDeProduto from '../cadastros/corproduto/views/editar-cor-produto';
import EditarUnidadesMedida from '../cadastros/unidademedida/views/editar-und-medida';
import EditarFichaTecnica from 'root-cadastros/fichatecnica/views/editar-ficha-tecnica';
import EditarUploadProduto from 'root-cadastros/uploadproduto/views/editar-upload-produto';
import UploadFichaTecnica from 'root-cadastros/uploadFichaTecnica/views/upload-ficha-tecnica';
import CategoriaNutricionais from 'root-cadastros/categorianutricional/views/categoria-nutricional';
import ReferenciasNutricionais from 'root-cadastros/referencianutricional/views/referencia-nutricional';
import EditarCategoriaNutricional from 'root-cadastros/categorianutricional/views/editar-categoria-nutricional';
import EditarReferenciaNutricional from 'root-cadastros/referencianutricional/views/editar-referencia-nutricional';
import UploadFichaTecnicaListagem from 'root-cadastros/uploadFichaTecnica/views/listagem-ficha-tecnica';
import TiposCaracteristica from 'root-cadastros/tipocaracteristica/views/tipo-caracteristica';
import EditarTipoCaracteristica from 'root-cadastros/tipocaracteristica/views/editar-tipo-caracteristica';
import CaracteristicasQualidade from 'root-cadastros/caracteristicadequalidade/views/caracteristica-qualidade';
import EditarCaracteristicaQualidade from 'root-cadastros/caracteristicadequalidade/views/editar-caracteristica-qualidade';

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: any;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/dashboard',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: '/perfil',
    exactPath: true,
    component: Perfil,
  },
  {
    path: '/cadastros/unidade-medida',
    component: UnidadesMedida,
    exactPath: true,
  },
  {
    path: '/cadastros/unidade-medida/novo',
    component: EditarUnidadesMedida,
  },
  {
    path: '/cadastros/unidade-medida/editar/:id',
    component: EditarUnidadesMedida,
  },
  {
    path: '/cadastros/etapa',
    component: Etapas,
    exactPath: true,
  },
  {
    path: '/cadastros/etapa/novo',
    component: EditarEtapas,
  },
  {
    path: '/cadastros/etapa/editar/:id',
    component: EditarEtapas,
  },
  {
    path: '/cadastros/marca',
    component: Marcas,
    exactPath: true,
  },
  {
    path: '/cadastros/marca/novo',
    component: EditarMarcas,
  },
  {
    path: '/cadastros/marca/editar/:id',
    component: EditarMarcas,
  },
  {
    path: '/cadastros/cor-produto',
    component: CoresProdutos,
    exactPath: true,
  },
  {
    path: '/cadastros/cor-produto/novo',
    component: EditarCoresDeProduto,
  },
  {
    path: '/cadastros/cor-produto/editar/:id',
    component: EditarCoresDeProduto,
  },
  {
    path: '/cadastros/produto',
    component: Produtos,
    exactPath: true,
  },
  {
    path: '/cadastros/produto/novo',
    component: EditarProduto,
  },
  {
    path: '/cadastros/produto/editar/:id',
    component: EditarProduto,
  },
  {
    path: '/cadastros/ficha-tecnica',
    component: FichaTecnica,
    exactPath: true,
  },
  {
    path: '/cadastros/ficha-tecnica/nova',
    component: EditarFichaTecnica,
  },
  {
    path: '/cadastros/ficha-tecnica/editar/:id',
    component: EditarFichaTecnica,
  },
  {
    path: '/cadastros/categoria-nutricional',
    component: CategoriaNutricionais,
    exactPath: true,
  },
  {
    path: '/cadastros/categoria-nutricional/novo',
    component: EditarCategoriaNutricional,
  },
  {
    path: '/cadastros/categoria-nutricional/editar/:id',
    component: EditarCategoriaNutricional,
  },
  {
    path: '/cadastros/referencia-nutricional',
    component: ReferenciasNutricionais,
    exactPath: true,
  },
  {
    path: '/cadastros/referencia-nutricional/novo',
    component: EditarReferenciaNutricional
  },
  {
    path: '/cadastros/referencia-nutricional/editar/:id',
    component: EditarReferenciaNutricional
  },
  {
    path: 'cadastros/pdf/:id',
    exactPath: true,
    component: PDFView,
  },
  {
    path: 'cadastros/upload-produto',
    component: UploadProduto,
    exactPath: true
  },
  {
    path: 'cadastros/upload-produto/novo',
    component: EditarUploadProduto,
    exactPath: true
  },
  {
    path: 'cadastros/upload-ficha-tecnica',
    component: UploadFichaTecnicaListagem
  },
  {
    path: 'cadastros/upload-ficha-tecnica/novo',
    component: UploadFichaTecnica
  },
  {
    path: 'cadastros/upload-ficha-tecnica/editar/:id',
    component: UploadFichaTecnica
  },
  {
    path: '/cadastros/aro',
    component: Aros,
    exactPath: true,
  },
  {
    path: 'cadastros/alergenos',
    component: Alergenos,
    exactPath: true
  },
  {
    path: 'cadastros/alergenos/novo',
    component: EditarAlergenos,
  },
  {
    path: 'cadastros/alergenos/editar/:id',
    component: EditarAlergenos,
  },
  {
    path: 'cadastros/tipo-caracteristica',
    component: TiposCaracteristica,
    exactPath: true
  },
  {
    path: 'cadastros/tipo-caracteristica/editar/:id',
    component: EditarTipoCaracteristica
  },
  {
    path: 'cadastros/tipo-caracteristica/novo',
    component: EditarTipoCaracteristica
  },
  {
    path: 'cadastros/caracteristica-qualidade',
    component: CaracteristicasQualidade,
    exactPath: true
  },
  {
    path: 'cadastros/caracteristica-qualidade/editar/:id',
    component: EditarCaracteristicaQualidade
  },
  {
    path: 'cadastros/caracteristica-qualidade/novo',
    component: EditarCaracteristicaQualidade
  },
];

export default viewsRoutes;
