import { AxiosResponse } from 'axios';
import GenericApi from 'root-resources/generic-api';
import Produto from '../model/produto';
import UploadProduto, { Base64 } from '../../uploadproduto/model/uploadproduto';

/**
 * API de Produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @extends {RootApi}
 */
class ProdutoAPI extends GenericApi<Produto> {
  /**
   * Injeta a instância do axios com o endpoint base referente aos Produtos
   */
  public constructor() {
    super({ baseEndpoint: 'produto' });
  }

  /**
   * Verifica se um código de barras já foi cadastrado no banco
   * @param value código de barras
   */
  public async validaCodigo(value: string): Promise<AxiosResponse> {
    return this.api.get(`/codigo-barra/${value}`);
  }

 /**
   * Buscar todas as Informações do produto para ordem de produção
   * @param id ID do produto
   * @param quantidade Quantidade passada pelo usuário para realizar calculo.
   */
  public async fetchDataOrdemProducao(id: string, quantidade: number): Promise<AxiosResponse> {
    return this.api.get(`/ordem-producao/${id}/${quantidade}`);
  }

  /**
   * Realizando o calculo automatico de data e hora para a tela de apontamento
   * @param id id Do Produto
   * @param dtInicio Data Inicio Operação
   * @param quantidade Quantidade passada pelo usuário para realizar calculo.
   */
  public async automaticTimeCalc(id: string, dtInicio: string, quantidade: number): Promise<AxiosResponse> {
    return this.api.get(`/calculo-tempo/${id}/${dtInicio}/${quantidade}`);
  }

  /**
   * Busca os estoques disponíveis para os insumos de um produto
   *  @param id Id do produto
   */
  public async fetchProductStocks(id: string) {
    return this.api.get(`/materia-prima-embalagem/${id}`);
  }

  
  /**
   * Retorna a visualização da produto importado
   * 
   * @param arquivo base64 do arquivo xls
   */
  public async getFileExcel(arquivo: Base64) {
    return this.api.put(`/upload-produto`, arquivo);
  }

  /**
   * Salva Dados da planilha excel de Produto
   * 
   * @param uploadProdutoDtoList lista de produtos da planilha excel
   */
  public async saveFileExcel(uploadProdutoDtoList: UploadProduto) {
    return this.api.post(`/save-upload-produto`, uploadProdutoDtoList);
  }
}

export default ProdutoAPI;
