import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import Report from 'root-models/report';
import { ArrowBack } from '@mui/icons-material';
import Swal from 'root-components/swal/swal';
import { PDFViewer } from '@bubotech/sumora-react-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

export interface StateProps {
  data: Report;
  from: string;
}

/**
 * Tela para visualização de PDF
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 */
function PDFView(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Visualizar PDF');
  });

  function pdfProps() {
    let { data, from } = state as StateProps;

    return { 
      fileCSV: data?.dsBase64Csv ?? undefined, 
      filePDF: data?.dsBase64Pdf ?? undefined, 
      fileXLS: data?.dsBase64Xls ?? undefined, 
      fileDocx: data?.dsBase64Docx ?? undefined,
      fileName: from,
    };
  }
  
  return (
    <div style={{ height: '100%' }}>
      <PDFViewer 
        {...pdfProps()}
        additionalActions={[
          {
            onClick: () => navigate(-1),
            iconProps: { color: 'inherit' },
            icon: <ArrowBack />,
            tooltipLabel: 'Voltar',
          }
        ]}
        addTime={false}
        onDownloadFinish={() => {
          Swal({
            showConfirmButton: true,
            title: 'Sucesso',
            text: 'Download realizado',
            icon: 'success'
          })
        }}
        onError={() => {
          Swal({
            showConfirmButton: true,
            title: 'Erro',
            text: 'Erro durante o download do arquivo.',
            icon: 'error'
          })
        }}
      />
    </div>
  )
}

export default PDFView;