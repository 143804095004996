import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import Swal from 'root-components/swal/swal';
import ProdutoAPI from '../resource/produto';
import Modal from 'root-components/modal/modal';
import { Grid, IconButton } from '@mui/material';
import { useStyles } from 'root-views/app.styles';
import CodigoBarras from '../model/codigo-barras';
import { FormikErrors, FormikTouched } from 'formik';
import { EditarProdutoFormikValuesType } from './editar-produto';
import { DataTable, GroupButtonGrid, Select, TextField } from '@bubotech/sumora-react-components';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';


export type EditarCodigoBarrasProdutoPropType = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarProdutoFormikValuesType;
  errors: FormikErrors<EditarProdutoFormikValuesType>;
  touched: FormikTouched<EditarProdutoFormikValuesType>;
};

/**
 * View de edição de códigos de barra de um produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {EditarCodigoBarrasProdutoPropType} props
 */
function EditarCodigoBarrasProduto(props: EditarCodigoBarrasProdutoPropType): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const produtoApi = new ProdutoAPI();
  const [descricao, setDescricao] = useState<string>('');
  const [selected, setSelected] = useState<CodigoBarras>();
  const [codigoBarras, setcodigoBarras] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setFieldValue, handleBlur, values, touched, errors } = props;
  const [tipoCodigo, setTipoCodigo] = useState<number | undefined>(undefined);
  const [dados, setDados] = useState<CodigoBarras[]>(values.codigosBarras.filter(codigo => codigo.stRegistro !== 2));

  const digitoVerificador = (codigo: string): boolean => {
    codigo = codigo.replace(/\D/g, '');
    if (codigo.length < 13 || codigo.length > 14)
      return false;

    if (codigo.length === 13) {
      const valores = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
      let soma = 0;
      let valor;

      // multiplicando os números do código pelos valores do array e somando
      for (let i = 0; i < 12; i++) {
        soma += parseInt(codigo[i]) * valores[i];
      }

      // dividindo a soma por 10 e transformando no inteiro mais próximo
      valor = Math.round(soma / 10);
      valor = ((valor + 1) * 10) - soma;

      // caso o valor encontrando seja múltiplo de 10, o digito será 0
      if (valor % 10 === 0) {
        return codigo[12] === '0';
      } else {
        if (valor > 10)
          valor = valor - 10;
        return codigo[12] === valor.toString();
      }
    } else {
      const valores = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
      let soma = 0;
      let valor;

      // multiplicando os números do código pelos valores do array e somando
      for (let i = 0; i < 13; i++) {
        soma += parseInt(codigo[i]) * valores[i];
      }

      // dividindo a soma por 10 e transformando no inteiro mais próximo
      valor = Math.round(soma / 10);
      valor = ((valor + 1) * 10) - soma;
      // caso o valor encontrando seja múltiplo de 10, o digito será 0
      if (valor % 10 === 0) {
        return codigo[13] === '0';
      } else {
        if (valor > 10)
          valor = valor - 10;
        return codigo[13] === valor.toString();
      }
    }
  };

  function disableOption(opt: any) {
    const filtered = opt.value === 0 && values.codigosBarras?.find(codigo => codigo.tpCodigoBarra === 0) !== undefined;
    const filtrando = values.codigosBarras.filter(codigo => codigo.stRegistro !== 2);
    if (filtrando.length === 0) {
      values.codigosBarras.find(codigo => codigo.tpCodigoBarra = undefined);
    }

    return filtered;
  }

  const handleAddCode = () => {
    if (digitoVerificador(codigoBarras)) {
      produtoApi.validaCodigo(codigoBarras).then(() => {
        const codigosBarra = [
          ...values.codigosBarras, { tpCodigoBarra: tipoCodigo ?? 1, dsCodigoBarra: descricao, nrCodigoBarra: codigoBarras, stRegistro: 0 }
        ];
        setFieldValue('codigosBarras', codigosBarra);
        setDescricao('');
        setcodigoBarras('');
        setTipoCodigo(undefined);
        //filtrando codigos de barras que foram deletados.
        const codigoBarraexiste = codigosBarra.filter(codigo => codigo.stRegistro !== 2);
        setDados(codigoBarraexiste);
        setDirty(false)
      }).catch(() =>
        Swal({
          showConfirmButton: true,
          title: 'Código duplicado',
          text: `O código ${codigoBarras} já existe. Insira um código diferente`,
          icon: 'error',
        })
      );
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Código inválido',
        text: `O código ${codigoBarras} é inválido. Insira um código diferente`,
        icon: 'error',
      });
    }
  };

  const handleDelete = () => {
    setShowModal(false);
    if (selected) {
      // alterando o stRegistro do código escolhido
      const remove = values.codigosBarras.map(codigo => {
        if (JSON.stringify(selected) === JSON.stringify(codigo))
          return { ...codigo, stRegistro: 2 };
        else return codigo;
      });

      setFieldValue('codigosBarras', remove.filter(codigo => {
        if (!codigo.idCodigoBarra && codigo.stRegistro === 2) return false;
        else return true;
      }));
      setDados(remove.filter(codigo => codigo.stRegistro !== 2));
    }

    setSelected(undefined);
  };

  // verifica se o formulário está sujo já que a aba não tem formik
  const [dirty, setDirty] = useState<boolean>(false);
  useComponentDidMount(() => {
    setDirty(false);
  });
  useEffect(() => {
    if (dirty)
      return;
    if (tipoCodigo !== undefined || descricao !== '' || codigoBarras !== '')
      setDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoCodigo, descricao, codigoBarras]);
  useVerificaDados({ funcaoPrincipalProps: { dirty }, handleSubmit: handleAddCode });

  return (
    <section id='editar-codigo-barras' className={classes.sectionTable}>
      <section id='formulario' className={classes.tabContent}>
        <Grid container>
          <Grid item xs={3} className={classes.gridCell}>
            <Select<any>
              label={'Tipo'}
              variant='standard'
              value={tipoCodigo}
              placeholder='Selecione...'
              // desabilita a opção primário caso já tem um cadastrado
              disableOptionIf={opt => disableOption(opt)}
              options={[{ label: 'EAN', value: 0 }, { label: 'DUN', value: 1 }]}
              onChangeValue={(selected) => {
                if (selected) setTipoCodigo(selected.value);
                else setTipoCodigo(undefined);
              }}
              errorText={Boolean(touched.codigosBarras && errors.codigosBarras) ? `Campo obrigatório` : ''}
              error={Boolean(touched.codigosBarras && errors.codigosBarras)}
              helperText={Boolean(touched.codigosBarras && errors.codigosBarras) ? `Campo obrigatório` : ''}
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={5} className={classes.gridCell}>
            <TextField
              label={'Descrição'}
              variant='standard'
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </Grid>

          <Grid item xs={3} className={classes.gridCell}>
            <TextField
              label={'Código'}
              variant='standard'
              value={codigoBarras}
              onChange={(e) => setcodigoBarras(e.target.value)}
              error={touched.codigosBarras && errors.codigosBarras !== undefined}
              helperText={touched.codigosBarras && errors.codigosBarras !== undefined ? `Campo obrigatório` : ''}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={1} style={{ height: 65, display: 'flex', alignItems: 'center' }}>
            <IconButton
              disabled={tipoCodigo === undefined && codigoBarras === ''}
              color='primary'
              onClick={handleAddCode}>
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </section>

      <section id='tabela' style={{ flex: 8, display: 'flex', flexDirection: 'column' }}>
        <GroupButtonGrid
          showAdd={false}
          showEdit={false}
          showDelete
          buttonDeleteProps={{ disabled: selected === undefined, disabledColor: theme.palette.grey[100] }}
          disableEdit
          onClickDelete={() => setShowModal(true)}
        />
        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<any>
            columns={[
              {
                field: 'tpCodigoBarra',
                headerName: 'Tipo',
                col: 3,
                valueGetter: (node) => node.data.tpCodigoBarra === 0 ? 'EAN' : 'DUN',
                sortable: false
              },
              {
                field: 'dsCodigoBarra',
                headerName: 'Descrição',
                col: 7,
              },
              {
                field: 'nrCodigoBarra',
                headerName: 'Cód. de Barras',
                col: 2,
              },
            ]}
            data={dados}
            onSelectRow={(selected) => setSelected(selected)}
            rowsPerPageEnabled={false}
          />
        </div>
      </section>
      <Modal
        title='Clicando em EXCLUIR você estará removendo esse item da lista.'
        message='Tem certeza que deseja realizar esta ação?'
        open={showModal}
        onFinishLabel='EXCLUIR'
        onCloseLabel='CANCELAR'
        onClose={() => setShowModal(false)}
        onFinish={handleDelete}
      />
    </section>
  );
}

export default EditarCodigoBarrasProduto;
