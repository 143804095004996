import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { useStyles } from 'root-views/app.styles';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { FormFieldTypeEnum } from 'root-components/form/form';
import { useVerificaDados, FormWithTable } from 'root-components';
import SituacaoRegistroEnum from 'root-enumerations/situacao-registro';

// Models
import FichaTecnicaAlergenos from '../models/fichatecnicaalergenico';
import FichaTecnica from '../models/fichatecnica';
import Busca from 'root-models/parametrosbusca';

// APIs
import AlergenicosAPI from 'root-cadastros/alergenos/resource/api';
import FichaTecnicaCaracteristica from '../models/fichatecnicacaracteristica';
import CaracteristicaAPI from '../../caracteristicadequalidade/resources/api';
import TipoCaracteristicaAPI from '../../tipocaracteristica/resources/api';
import TipoCaracteristica from '../../tipocaracteristica/model/tipocaracteristica';
import CaracteristicaQualidade from '../../caracteristicadequalidade/models/caracteristicaqualidade';

interface CaracteristicaFichaTecnicaPropType {
    valuesFt: FichaTecnica
    setFieldValuesForm: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<FichaTecnica>>
}

/**
 * View de edição de uma caracteristica
 * 
 * @author @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @param {CaracteristicaFichaTecnicaPropType} props
 */
function CaracteristicaFichaTecnica(props: CaracteristicaFichaTecnicaPropType): JSX.Element {
    const classes = useStyles();
    const { setFieldValuesForm, valuesFt } = props;
    const [edit, setEdit] = useState<boolean>(false);
    const [selected, setSelected] = useState<FichaTecnicaCaracteristica>();
    const [suggestions, setSuggestions] = useState<CaracteristicaQualidade[]>([]);
    const [allSuggestions, setAllSuggestions] = useState<CaracteristicaQualidade[]>([]);

    const apiCaracteristica = new CaracteristicaAPI();
    const apiTipoCaracteristica = new TipoCaracteristicaAPI();

    const params = useRef<Busca>({ page: 1, orderField: 'tipoCaracteristica.nmTipoCaracteristica', orderType: 'ASC' });

    const form = useFormik<FichaTecnicaCaracteristica>({
        initialValues: { caracteristica: null, stRegistro: SituacaoRegistroEnum.CREATE, idFichaTecnicaCaracteristica: '' } as FichaTecnicaCaracteristica,
        validationSchema: Yup.object({
            caracteristica: Yup.object().required('Campo obrigatório').nullable(),
        }),
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit: handleSubmitForm
    })

    const { dirty, handleSubmit, values } = form;

    useVerificaDados({ funcaoPrincipalProps: { dirty }, handleSubmit })

    function handleSubmitForm(selected: FichaTecnicaCaracteristica | undefined) {
        if (!edit) {
            values.idFichaTecnicaCaracteristica = null
            const newValues = valuesFt.fichaTecnicaCaracteristicaList.map(item => ({
                ...item,
                idFichaTecnicaCaracteristica: null,
                stRegistro: SituacaoRegistroEnum.CREATE
            }));
            setFieldValuesForm('fichaTecnicaCaracteristicaList', [...newValues, values]);
        } else {
            if (selected) {
                const updatedList = valuesFt.fichaTecnicaCaracteristicaList.map(item => {
                    const newItem = {
                        ...item,
                        idFichaTecnicaCaracteristica: null,
                        stRegistro: SituacaoRegistroEnum.CREATE
                    }
                    const newValues = {
                        ...values,
                        idFichaTecnicaCaracteristica: null,
                        stRegistro: SituacaoRegistroEnum.CREATE
                    }
                    return JSON.stringify(item) === JSON.stringify(selected) ? newValues : newItem;
                });
                setFieldValuesForm('fichaTecnicaCaracteristicaList', updatedList);
            }
            setEdit(false)
        }
        form.resetForm()
    }

    // Atualiza as sugestões de características ativas que ainda não foram adicionados à lista
    useEffect(() => {
        apiCaracteristica.findByPage(params.current).then(res => {
            const itemsFromAPI = res.data.data;
            const novosItens = itemsFromAPI.filter(itemFromAPI => (
                itemFromAPI.stCaracteristica === SituacaoEnum.ATIVO &&
                !valuesFt.fichaTecnicaCaracteristicaList.some(selectedItem =>
                    selectedItem.caracteristica?.nmCaracteristica === itemFromAPI.nmCaracteristica
                )
            ));
            setSuggestions(novosItens);
            setAllSuggestions(novosItens);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valuesFt.fichaTecnicaCaracteristicaList])

    useEffect(() => {
        if (selected && edit) {
            form.setValues(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, edit]);

    function handleDelete() {
        if (selected) {
            const removeList = valuesFt.fichaTecnicaCaracteristicaList.map(item => {
                if (JSON.stringify(item) === JSON.stringify(selected)) return { ...item, stRegistro: SituacaoRegistroEnum.DELETE };
                else return { ...item, stRegistro: SituacaoRegistroEnum.CREATE };
            });
            const filteredList = removeList.filter(item => item.stRegistro !== SituacaoRegistroEnum.DELETE);

            setFieldValuesForm('fichaTecnicaCaracteristicaList', filteredList);
        }
    }

    function handleChangeTipoCaracteristica(value: TipoCaracteristica) {
        form.resetForm()
        form.setFieldValue('caracteristica.tipoCaracteristica', value)
        const allItens = allSuggestions
        const novosItens = allItens.filter(item => {
            return item.tipoCaracteristica?.idTipoCaracteristica === value.idTipoCaracteristica;
        });
        setSuggestions(novosItens);
    }    

    return (
        <section id='editar-caracteristica' className={classes.formWithTableContainer} style={{ overflowY: 'auto' }}>
            <FormWithTable<FichaTecnicaCaracteristica>
                list={valuesFt.fichaTecnicaCaracteristicaList}
                handleMainForm={form.setFieldValue}
                listField='fichaTecnicaCaracteristicaList'
                onClickSave={() => handleSubmitForm(selected)}
                listItemIdField='idFichaTecnicaCaracteristica'
                tableProps={{ onClickEdit: () => setEdit(true), onSelectRow: setSelected, onClickDelete: () => handleDelete() }}
                listForm={{
                    form: form,
                    fields: [
                        {
                            fieldName: 'caracteristica.tipoCaracteristica',
                            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
                            label: 'Tipo de Característica',
                            fieldSize: 5,
                            showInTable: 3,
                            customTableField: 'caracteristica.tipoCaracteristica.nmTipoCaracteristica',
                            autoCompleteProps: {
                                genericApi: apiTipoCaracteristica,
                                searchField: 'nmTipoCaracteristica',
                                orderField: 'nmTipoCaracteristica',
                                staticSearchParams: ',stTipoCaracteristica:1',
                                getLabel: opt => opt.nmTipoCaracteristica,
                                getValue: opt => opt.idTipoCaracteristica,
                                label: 'Tipo',
                                onChangeValue: handleChangeTipoCaracteristica,
                            }
                        },
                        {
                            fieldName: 'caracteristica',
                            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
                            label: 'Característica',
                            fieldSize: 5,
                            showInTable: 3,
                            customTableField: 'caracteristica.nmCaracteristica',
                            autoCompleteProps: {
                                suggestions: suggestions,
                                searchField: 'caracteristica.nmCaracteristica',
                                orderField: 'caracteristica.nmCaracteristica',
                                staticSearchParams: ',caracteristica.stCaracteristica:1',
                                getLabel: opt => opt.nmCaracteristica ?? '',
                                getValue: opt => opt.idCaracteristica,
                                label: 'Característica'
                            }
                        },
                        {
                            fieldName: 'caracteristica.dsReferenciaLmt',
                            fieldType: FormFieldTypeEnum.READ_ONLY,
                            label: 'Referência/LMT',
                            fieldSize: 1,
                            showInTable: 6,
                            customTableField: 'caracteristica.dsReferenciaLmt',
                        },
                    ]
                }}
            />
        </section>
    );
}

export default CaracteristicaFichaTecnica;