import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { Form, Formik } from 'formik';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import ProdutoAPI from '../resource/produto';
import { useStyles } from '../assets/produtos.styles';
import { useDispatch, useSelector } from 'react-redux';
import TipoItemEnum from 'root-enumerations/tipo-item-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import ContextActions from 'root-states/actions/context-actions';
import { ButtonFABMenu } from '@bubotech/sumora-react-components/lib';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { ContextStateType } from 'root-states/reducers/context-reducer';
import VerticalTabs from '@bubotech/sumora-react-components/lib/verticaltabs';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

// icones
import iconImposto from 'root-icons/icon-tax.svg';
import iconImagem from 'root-icons/icon-image.svg';
import iconProduto from 'root-icons/icon-product.svg';
import iconCodigoBarras from 'root-icons/icon-barcode.svg';

//models
import Etapa from '../model/etapa';
import Arquivo from '../model/arquivo';
import Produto from '../model/produto';
import Cest from 'root-models/fiscal/cest';
import MensagemErro from 'root-models/error';
import NcmModel from 'root-models/fiscal/ncm';
import CodigoBarras from '../model/codigo-barras';
import CodigoServicos from 'root-models/fiscal/codigo-servico';

// abas formulário
import EditarDadosProduto from './editar-dados';
import EditarFiscalProduto from './editar-fiscal';
import EditarImagemProduto from './editar-imagem';
import EditarCodigoBarrasProduto from './editar-codigo-barras';


/**
 * Tipo dos valores do formik
 */
export interface EditarProdutoFormikValuesType extends Produto { }

export type EditarProdutoPropType = {};

/**
 * View de edição de produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {EditarProdutoPropType} props
 */
function EditarProduto(props: EditarProdutoPropType): JSX.Element {
  const classes = useStyles();
  const api = new ProdutoAPI();
  const history = useNavigate();
  const { state } = useLocation();
  const [values, setValues] = useState<Produto>();
  const { id }: Readonly<Params<string>> = useParams();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);
  const [arquivo, setArquivo] = useState<Arquivo>();
  const [hideFab, setHideFab] = useState<boolean>(false);

  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  const contextProps = useSelector<MainStateType, ContextStateType>((state) => state.contextReducer);

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<EditarProdutoFormikValuesType>({
    nmProduto: '',
    cdProduto: '',
    unidadeMedida: undefined,
    blMovimentaEstoque: false,
    blNotaFiscal: true,
    blPossuiVariacao: false,
    blPossuiComposicao: false,
    idUnidadeMedidaVenda: '',
    idUnidadeMedidaComposicao: '',
    nrReferenciaComposicao: undefined,
    idUnidadeMedidaEmbalamento: '',
    nrReferenciaEmbalamento: undefined,
    nrReferenciaProdutiva: undefined,
    idUnidadeMedidaEntrega: '',
    nrReferenciaEntrega: undefined,
    nrQtdeMinimaPrd: undefined,
    produtoFornecedorList: [],
    produtoConversoes: [],
    tpItem: undefined,
    stProduto: 1,
    codigosBarras: [] as CodigoBarras[],
    dsModelo: '',
    dsObservacoes: '',
    dsProduto: '',
    nrTamanho: '',
    nrAltura: '',
    planta: '',
    idPlanta: '',
    nrLargura: '',
    nrComprimento: '',
    nrPesoBruto: '',
    dsVolume: '',
    nrPesoLiquido: '',
    dsNomeEtiqueta: ''
  } as EditarProdutoFormikValuesType);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(id ? 'Edição de Produto' : 'Cadastro de Produto');

    if (id || state) setEnableReinitialize(true);
  });

  const buildProduto = (copy: boolean, values: Produto): Produto => {
    if (!values.blNotaFiscal) {
      values.idCest !== undefined && delete values.idCest;
      values.dsNcm !== undefined && delete values.dsNcm;
      values.cdNcm !== undefined && delete values.cdNcm;
      values.dsCest !== undefined && delete values.dsCest;
      values.idNcm !== undefined && delete values.idNcm;
      values.cdCest !== undefined && delete values.cdCest;
    } else {
      if (values.tpItem === TipoItemEnum.SERVICOS) {
        values.idCest !== undefined && delete values.idCest;
        values.dsCest !== undefined && delete values.dsCest;
        values.cdCest !== undefined && delete values.cdCest;
        values.idNcm !== undefined && delete values.idNcm;
        values.dsNcm !== undefined && delete values.dsNcm;
        values.cdNcm !== undefined && delete values.cdNcm;
        values.codigoServicoDto = {
          nrCodigoServicos: values.cdServico
        } as CodigoServicos;
      } else {
        values.cdServico !== undefined && delete values.cdServico;
        values.CestDto = {
          dsCest: values.dsCest ?? '',
          idCest: values.idCest,
          cdCest: values.cdCest ?? ''
        } as Cest;
        values.ncmDto = {
          dsNcm: values.dsNcm ?? '',
          idNcm: values.idNcm ?? '',
          cdNcm: values.cdNcm ?? ''
        } as NcmModel;
      }

    }
    values.nrPesoBruto = values.nrPesoBruto?.toString().replace('.', ',');
    values.nrPesoLiquido = values.nrPesoLiquido?.toString().replace('.', ',');
    values.nrReferenciaComposicao = values.nrReferenciaComposicao?.toString().replace('.', ',');
    values.nrReferenciaEmbalamento = values.nrReferenciaEmbalamento?.toString().replace('.', ',');
    values.nrReferenciaEntrega = values.nrReferenciaEntrega?.toString().replace('.', ',');
    values.nrQtdeMinimaPrd = values.nrQtdeMinimaPrd?.toString().replace('.', ',');
    // ajustando stRegistro
    values.codigosBarras = values.codigosBarras?.map(codigo => ({ ...codigo, stRegistro: copy ? 0 : 1 }));

    // setando os ids
    if (values.corProduto) values.idCorProduto = values.corProduto.idCorProduto;
    if (values.marca) values.idMarca = values.marca.idMarca;
    if (values.unidadeMedidaVenda) values.idUnidadeMedidaVenda = values.unidadeMedidaVenda.idUnidadeMedida;

    if (copy) {
      // limpandos os IDs
      delete values.idProduto;
      values.cdProduto = '';

      values.nmProduto = `Cópia de ${values.nmProduto}`;
      values.codigosBarras = [];
    }

    return values;
  };

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api.findById(id ?? state).then(res => {
      const produto = buildProduto(id ? false : true, res.data);
      setInitialValues(produto as EditarProdutoFormikValuesType);
      const arquivoAux: Arquivo = {
        dsArquivo: produto.dsImagem,
        dsFileName: ''
      };
      setArquivo(arquivoAux);
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }).catch(() => {
      appLayoutActions.setLoading(false);
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: 'Produto não encontrado',
        icon: 'error',
      }).then(() => history('/cadastros/produto'));
    });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  const showButton = () => contextProps.selectedTab !== 9 && !hideFab;

  const getIconStyle = (index: number) => index === contextProps.selectedTab ? `${classes.icone} ${classes.iconeSelecionado}` : classes.icone;

  useEffect(() => {
    setValues(values);
  }, [values]);

  return (
    <main style={{ height: '100%' }}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={enableReinitialize}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={Yup.object().shape({
          nmProduto: Yup.string().required('Campo obrigatório'),
          dsProduto: Yup.string().optional().nullable(),
          cdProduto: Yup.string().required('Campo obrigatório'),
          tpItem: Yup.number().required('Campo obrigatório'),
          idCorProduto: Yup.string().optional().nullable(),
          idMarca: Yup.string().optional().nullable(),
          nrTamanho: Yup.string().optional().nullable(),
          idUnidadeMedidaVenda: Yup.string().required('Campo obrigatório'),
          nrPesoBruto: Yup.string().optional().nullable(),
          nrLargura: Yup.number().optional().nullable(),
          nrAltura: Yup.number().optional().nullable(),
          nrComprimento: Yup.number().optional().nullable(),
          stProduto: Yup.number().required('Campo obrigatório'),
          dsObservacoes: Yup.string().optional().nullable(),
          blMovimentaEstoque: Yup.boolean().default(false).required('Campo obrigatório'),
          blNotaFiscal: Yup.boolean().default(false).required('Campo obrigatório'),
          idCest: Yup.string().optional().nullable(),
          cdCest: Yup.string().optional().nullable(),
          dsCest: Yup.string().optional().nullable(),
          idNcm: Yup.string().optional().nullable(),
          cdNcm: Yup.string().optional().nullable(),
          dsNcm: Yup.string().optional().nullable(),
          cdExtipi: Yup.string().optional().nullable(),
          dsExtipi: Yup.string().optional().nullable(),
          cdServico: Yup.string().optional().nullable(),
          dsModelo: Yup.string().optional().nullable(),
          produtoEtapas: Yup.array().optional().nullable(),
          codigosBarras: Yup.array().optional().nullable(),
          produtoEstoques: Yup.array().optional().nullable(),
          dsNomeEtiqueta: Yup.string().optional().nullable(),
        })}
        onSubmit={handleSubmitFormik}
      >
        {({ values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit, dirty }) => {
          return (
            <Form style={{ height: '100%' }}>
              <VerticalTabs
                title={'Produto'}
                tabs={[
                  {
                    icon: <img src={iconProduto} alt='Ícone Dados Produto' className={getIconStyle(0)} />,
                    label: 'Dados',
                    content: (
                      <EditarDadosProduto
                        errors={errors}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        superHandleSubmit={handleSubmit}
                        superDirty={dirty}
                      />
                    ),
                  },
                  {
                    icon:
                      <img
                        src={iconCodigoBarras}
                        alt='Ícone Código de Barras'
                        className={getIconStyle(1)}
                      />,
                    label: 'Código de Barras',
                    content: (
                      <EditarCodigoBarrasProduto
                        errors={errors}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    ),
                  },
                  {
                    icon: <img src={iconImposto} alt='Ícone Fiscal' className={!values.blNotaFiscal ? classes.iconDisabled : getIconStyle(2)} />,
                    label: 'Fiscal',
                    disabled: !values.blNotaFiscal,
                    content: (
                      <EditarFiscalProduto
                        errors={errors}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        superHandleSubmit={handleSubmit}
                      />
                    ),
                  },
                  {
                    icon: <img src={iconImagem} alt='Ícone Imagem' className={values.tpItem === TipoItemEnum.SERVICOS ? classes.iconDisabled : getIconStyle(3)} />,
                    label: 'Imagem',
                    content: (
                      <EditarImagemProduto
                        errors={errors}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        imageChange={(image: Arquivo) => setArquivo(image)}
                        arquivoURL={arquivo}
                      />
                    ),
                    disabled: values.tpItem === TipoItemEnum.SERVICOS
                  },
                ]}
                selectedIndex={contextProps.selectedTab}
                onChangeIndex={(tab) => {
                  contextActions.tryChangeTab(tab);
                  if (contextProps.allowTabChange) {
                    tab !== 6 && setHideFab(false);
                  }
                }}
                menuEffect
              />
              {showButton() && (
                <ButtonFABMenu
                  disabled={isLoading}
                  primaryAction={{
                    onClick: (e: any) => handleSubmit(e),
                    iconProps: { color: 'inherit' },
                  }}
                  secondaryAction={{
                    onClick: () => history('/cadastros/produto'),
                    iconProps: { color: 'inherit' },
                  }}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </main>
  );

  // função para montar etapas para enviar para o backend
  function buildEtapas(etapas?: Etapa[]) {
    return etapas?.map(etapa => {
      etapa.hrColaborador = etapa.hrColaborador?.replace('h', ':').replace('m', ':').replace('s', '');
      etapa.hrTotal = etapa.hrTotal?.replace('h', ':').replace('m', ':').replace('s', '');
      etapa.hrSetup = etapa.hrSetup?.replace('h', ':').replace('m', ':').replace('s', '');
      if (etapa.hrSetup === 'null') etapa.hrSetup = undefined;
      const etapasPredecessoras = etapa.produtoEtapasPredecessoras?.map((etapaPredecessora: Etapa) => {
        return {
          produtoEtapa: { idProdutoEtapa: etapa.idProdutoEtapa ?? etapa.nrOrdem.toString() },
          etapaPredecessora: { idProdutoEtapa: etapaPredecessora.idProdutoEtapa },
          produtoEtapasPredecessoras: [] as Etapa[],
          nmEtapa: etapa.nmEtapa,
          idProdutoEtapa: etapa.idProdutoEtapa,
          nrOrdem: etapa.nrOrdem
        };
      });
      const produtoComposicao = etapa.produtoComposicoes?.map(composicao => {
        if (typeof (composicao.nrQuantidade) === 'string')
          return { ...composicao, nrQuantidade: parseFloat(composicao.nrQuantidade.replaceAll('.', '').replace(',', '.')) };
        else return composicao;
      });
      return { ...etapa, produtoEtapasPredecessoras: etapasPredecessoras, produtoComposicoes: produtoComposicao };
    });
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarProdutoFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarProdutoFormikValuesType>} formikHelpers - Auxiliares
   */
  async function handleSubmitFormik(values: Produto): Promise<void> {
    let error: MensagemErro = { mensagem: '', titulo: '', nome_exception: '', error: false };

    const novoProduto = { ...values };
    let produtoEtapas: any = buildEtapas(novoProduto.produtoEtapas);

    if (arquivo && arquivo.dsBase64) {
      novoProduto.dsImagemBase64 = arquivo.dsBase64;
      novoProduto.dsImagem = arquivo.dsFileName || '';
    }

    if (!novoProduto.blNotaFiscal) {
      novoProduto.idCest !== undefined && delete novoProduto.idCest;
      novoProduto.dsNcm !== undefined && delete novoProduto.dsNcm;
      novoProduto.cdNcm !== undefined && delete novoProduto.cdNcm;
      novoProduto.dsCest !== undefined && delete novoProduto.dsCest;
      novoProduto.idNcm !== undefined && delete novoProduto.idNcm;
      novoProduto.cdCest !== undefined && delete novoProduto.cdCest;
    }

    if (novoProduto.nrPesoBruto?.includes(',')) novoProduto.nrPesoBruto = novoProduto.nrPesoBruto.replaceAll('.', '').replace(',', '.');
    if (novoProduto.nrPesoLiquido?.includes(',')) novoProduto.nrPesoLiquido = novoProduto.nrPesoLiquido.replaceAll('.', '').replace(',', '.');
    if (novoProduto.nrReferenciaComposicao?.includes(',')) novoProduto.nrReferenciaComposicao = novoProduto.nrReferenciaComposicao.replaceAll(',', '.');
    if (novoProduto.nrReferenciaEmbalamento?.includes(',')) novoProduto.nrReferenciaEmbalamento = novoProduto.nrReferenciaEmbalamento.replaceAll(',', '.');
    if (novoProduto.nrReferenciaEntrega?.includes(',')) novoProduto.nrReferenciaEntrega = novoProduto.nrReferenciaEntrega.replaceAll(',', '.');
    if (novoProduto.nrQtdeMinimaPrd?.includes(',')) novoProduto.nrQtdeMinimaPrd = novoProduto.nrQtdeMinimaPrd.replaceAll(',', '.');

    LoadingSwal({ text: 'Carregando' });

    if (!id) {
      await api.save({ ...novoProduto, produtoEtapas }).catch((e) => {
        error = { ...e.response.data as MensagemErro };
        error.error = true;
      });
    } else {
      await api.update({ ...novoProduto, produtoEtapas }).catch((e) => {
        error = { ...e.response.data as MensagemErro };
        error.error = true;
      });
    }

    if (!error.error) {
      await Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastrado com sucesso',
        icon: 'success',
      });
      history('/cadastros/produto');
    } else if (error.error) {
      if (error.mensagem && error['mensagem'].includes('cdProduto')) {
        error.mensagem = error['mensagem'].replace('cdProduto', 'código item');
      }

      if (error.titulo === 'Campo duplicado') {
        await Swal({
          showConfirmButton: true,
          title: 'Código do Item duplicado',
          icon: 'error',
        });
      } else {
        await Swal({
          showConfirmButton: true,
          title: error['titulo'] ?? 'Erro',
          text: error['mensagem'] ?? `Erro ao ${id ? 'editar' : 'salvar'} produto.`,
          icon: 'error',
        });
      }
    }
  }
}

export default EditarProduto;
