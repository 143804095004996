import React, { useState } from 'react';
import Arquivo from '../model/arquivo';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { useStyles } from 'root-views/app.styles';
import { FormikErrors, FormikTouched } from 'formik';
import { EditarProdutoFormikValuesType } from './editar-produto';
import { UploadFiles, SnipImage } from '@bubotech/sumora-react-components';
import { ContextStateType } from 'root-states/reducers/context-reducer';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';

export type EditarImagemProdutoPropType = {
  onChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarProdutoFormikValuesType;
  errors: FormikErrors<EditarProdutoFormikValuesType>;
  touched: FormikTouched<EditarProdutoFormikValuesType>;
  imageChange: (image: Arquivo) => void;
  arquivoURL?: Arquivo;
};

/**
 * View de edição de Imagem de um produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {EditarImagemProdutoPropType} props
 */
function EditarImagemProduto(props: EditarImagemProdutoPropType): JSX.Element {
  const classes = useStyles();
  const [arquivo, setArquivo] = useState<Arquivo>();
  const [cropImage, setCropImage] = useState<string>();
  const { arquivoURL } = props;

  const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);
  useVerificaDados({ funcaoPrincipalProps: { dirty: contextProps.principalTabHasUnsavedData }, handleSubmit: contextProps.principalFormSubmit, ignoreTabChange: true });

  return (
    <section id='dados-imagem'>
      <div className={classes.cardImage}>
        <div>
          <UploadFiles
            propsInput={{
              accept: '.png, .jpg, .jpeg'
            }}
            multiUploads={false}
            key={1}
            addNewFiles={(fileList: Arquivo[]) => {
              setArquivo(fileList[0]);
            }}
            tratarError={() => { }}
          >
            <div className={classes.chooseImage}>
              Escolher arquivo
            </div>
          </UploadFiles>

          <div className={classes.fileName}>{arquivo?.dsFileName}</div>
        </div>

        <div style={{ width: 40 }} />

        <div className={classes.cardUpload}>
          {arquivoURL && !arquivoURL?.dsBase64 && arquivoURL.dsArquivo && <img src={arquivoURL.dsArquivo} alt='produto' />}
          {arquivo && (
            <SnipImage
              imageToSnap={arquivo.dsFile || ''}
              onSnapImage={(croppedImage: any) => setCropImage(croppedImage)}
              dsImageName={arquivo.dsFileName ?? ''}
              isNewImage={true}
              sendResult={(base64) => props.imageChange({
                ...arquivo,
                dsBase64: base64
              })}
            />
          )}
        </div>
        {cropImage && (
          <div style={{ marginTop: 90 }}>
            <img className={classes.imageLower} alt='cropImage' src={cropImage} />
          </div>
        )}
      </div>
    </section>
  );
}

export default EditarImagemProduto;
