import { makeStyles } from '@mui/styles'

/**
 * Estilos do componente de formulário
 */
const useStyles = makeStyles((theme: any) => ({
  titleContainer: {
    maxHeight: 44,
    padding: '12px 25px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 18,
    color: '#000000',
    fontWeight: 400,
    width: '100%',
  },
  tabContent: {
    padding: '25px 21px 5px',
    flex: 1,
    overflow: 'hidden',
  },
  switchContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all .8s ease-in-out'
  },
  label: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    marginLeft: 5
  },
  gridCell: {
    paddingRight: 25,
    height: 76,
    transition: 'all .2s ease-in-out'
  },
  container: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 16,
    padding: '25px 0 12px',
    color: `${theme.palette.primary.dark}`,
    marginBottom: 15
  },
}))

export { useStyles };
