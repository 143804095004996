import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

/**
 * Estilos das views de produto
 */
const useStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: '0 17px 25px',
    flex: 1,
    marginBottom: 25
  },
  iconDisabled: {
    height: 21,
    width: 21,
    filter: 'brightness(0) saturate(100%) invert(73%) sepia(8%) saturate(22%) hue-rotate(350deg) brightness(95%) contrast(80%)'
  },
  container: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 16,
    padding: '25px 0 12px',
    color: `${theme.palette.primary.dark}`,
  },
  cedula: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 14,
    color: '#798191',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 13px !important',
    height: 102
  },
  cedulaItem: {
    marginBottom: 27
  },
  tituloCedula: {
    marginBottom: 10,
    fontSize: 12,
    color: `${theme.palette.text.secondary}`
  },
  valorCedula: {
    fontSize: 20,
    fontWeight: 'bold',
    color: `${theme.palette.grey[700]}`,
  },
  containerDataTable: {
    marginBottom: 15,
    display: 'flex',
    height: '100%',
  },
  diagram: {
    maxWidth: 101,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  diagramLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Roboto',
    margin: '0 5px',
    color: theme.palette.text.secondary
  },
  icone: {
    height: 21,
    width: 21,
    filter: 'invert(40%) sepia(12%) saturate(733%) hue-rotate(183deg) brightness(91%) contrast(89%)'
  },
  iconeSelecionado: {
    filter: 'invert(17%) sepia(32%) saturate(2955%) hue-rotate(205deg) brightness(95%) contrast(92%)'
  },
}))

export { useStyles };
