import { AutoComplete, TextField } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { Grid } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';
import GeneroProduto from '../model/genero-produto';
import ContextActions from 'root-states/actions/context-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from 'root-views/app.styles';
import GeneroProdutoAPI from '../resource/genero-produto';
import { EditarProdutoFormikValuesType } from './editar-produto';
import Cest from 'root-models/fiscal/cest';
import CESTAPI from 'root-resources/api/cest-api';
import NcmModel from 'root-models/fiscal/ncm';
import NcmAPI from 'root-resources/api/ncm-api';
import TipoItemEnum from 'root-enumerations/tipo-item-enum';
import { ReadOnly } from 'root-components';
import CodigoServicos from 'root-models/fiscal/codigo-servico';
import InserirCodigoServicosApi from 'root-resources/api/codigo-servico-api';

export type EditarFiscalProdutoPropType = {
  onChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarProdutoFormikValuesType;
  errors: FormikErrors<EditarProdutoFormikValuesType>;
  touched: FormikTouched<EditarProdutoFormikValuesType>;
  superHandleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

/**
 * View de edição de fiscal de um produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {EditarFiscalProdutoPropType} props
 */
function EditarFiscalProduto(props: EditarFiscalProdutoPropType): JSX.Element {
  const classes = useStyles();
  const { setFieldValue, handleBlur, values, superHandleSubmit } = props;

  const apiCest = new CESTAPI();
  const apiNcm = new NcmAPI();
  const codigoServicoApi = new InserirCodigoServicosApi();
  const generoProdutoApi = new GeneroProdutoAPI();

  // verifica se o formulário está sujo já que a aba não tem formik
  const [dirty, setDirty] = useState<boolean>(false);
  useComponentDidMount(() => {
    setDirty(false);
  });
  useEffect(() => {
    if (dirty)
      return;
    if (values.CestDto || values.idNcm !== '' || values.dsNcm !== '' || values.cdServico !== '' || values.generoProduto || values.cdExtipi !== '')
      setDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CestDto, values.idNcm, values.dsNcm, values.cdServico, values.generoProduto, values.cdExtipi]);
  // Definindo que existem dados não salvos no formulário principal
  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  useEffect(() => {
    contextActions.principalTabHasUnsavedData(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);
  useVerificaDados({ funcaoPrincipalProps: { dirty }, handleSubmit: superHandleSubmit, ignoreTabChange: true });

  return (
    <section id='dados-fiscal'>
      <Grid container className={classes.tabContent}>
        <Grid item xs={2} className={classes.gridCell}>
          <AutoComplete<Cest>
            label={'CEST'}
            name='cdCest'
            inputProps={{ maxLength: 7 }}
            value={values.CestDto}
            onBlur={handleBlur}
            genericApi={apiCest}
            searchField='cdCest'
            orderField='cdCest'
            getLabel={(opt) => opt.cdCest ?? ''}
            getValue={(opt) => opt.idCest}
            onChangeValue={(e) => { 
              setFieldValue('idCest', e?.idCest);
              setFieldValue('cdCest', e?.cdCest); 
              setFieldValue('dsCest', e?.dsCest); 
              setFieldValue('CestDto', e);
            }}
            disabled={values.tpItem === TipoItemEnum.SERVICOS}
          />
        </Grid>

        <Grid item xs={2} className={classes.gridCell}>
          <AutoComplete<NcmModel>
            label={'NCM'}
            variant='standard'
            name='cdNcm'
            onBlur={handleBlur}
            value={values.ncmDto}
            genericApi={apiNcm}
            searchField='cdNcm'
            orderField='cdNcm'
            getLabel={(opt) => opt.cdNcm ?? ''}
            getValue={(opt) => opt.idNcm}
            onChangeValue={(e) => {
              if (e) {
                setFieldValue('idNcm', e.idNcm);
                setFieldValue('cdNcm', e.cdNcm);
                setFieldValue('dsNcm', e.dsNcm);
                setFieldValue('ncmDto', e);
              }
            }}
            disabled={values.tpItem === TipoItemEnum.SERVICOS}
          />
        </Grid>

        <Grid item xs={8} className={classes.gridCell}>
          <ReadOnly
            label='Descrição do NCM'
            value={values.dsNcm ?? ''}
          />
        </Grid>  

        <Grid item xs={4} className={classes.gridCell}>
          <AutoComplete<CodigoServicos>
            label={'Cód. de Serviço'}
            value={values.codigoServicoDto}
            name='nrCodigoServicos'
            onBlur={handleBlur}
            genericApi={codigoServicoApi}
            searchField='nrCodigoServicos'
            orderField='nrCodigoServicos'
            getLabel={(opt) => opt.nrCodigoServicos ?? ''}
            getValue={(opt) => opt.idCodigoServicos}
            onChangeValue={(e) => { 
              setFieldValue('cdServico', e?.nrCodigoServicos);
              setFieldValue('codigoServicoDto', e);
            }}
            disabled={values.tpItem !== TipoItemEnum.SERVICOS}
          />
        </Grid>
        <Grid item xs={6} className={classes.gridCell}>
          <AutoComplete<GeneroProduto>
            label={'Cód. de Gênero'}
            variant='standard'
            name='idGeneroProduto'
            onBlur={handleBlur}
            value={values.generoProduto}
            searchField='dsGeneroProduto'
            orderField='dsGeneroProduto'
            genericApi={generoProdutoApi}
            getLabel={opt => opt.dsGeneroProduto}
            getValue={opt => opt.idGeneroProduto}
            PaperSuggestionsProps={{
              className: 'suggestions-xs-6'
            }}
            onChangeValue={e => {
              if (e) {
                setFieldValue('idGeneroProduto', e.idGeneroProduto);
                setFieldValue('generoProduto', e);
              }
            }}
          />
        </Grid>

        <Grid item xs={2} className={classes.gridCell}>
          <TextField
            label={'Ex_IPI - Excessão TIPI'}
            name='cdExtipi'
            value={values.cdExtipi}
            inputProps={{ maxLength: 10 }}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
          />
        </Grid>
      </Grid>
    </section>
  );
}

export default EditarFiscalProduto;