import GenericApi from 'root-resources/generic-api';
import Marca from '../../cadastros/marca/model/marca';

/**
 * API de Marca
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @extends {RootApi}
 */
class MarcaAPI extends GenericApi<Marca> {
  /**
   * Injeta a instância do axios com o endpoint base referente as Marcas
   */
  public constructor() {
    super({ baseEndpoint: 'marca' });
  }
}

export default MarcaAPI;
