import GenericApi from "root-resources/generic-api";
import TipoCaracteristica from "../model/tipocaracteristica";

/**
 * API de tipo de característica
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @extends {RootApi}
 */
class TipoCaracteristicaAPI extends GenericApi<TipoCaracteristica> {
    /**
    * Injeta a instância do axios com o endpoint base referente a tipo de característica
    */
    public constructor() {
        super({baseEndpoint:'tipo-caracteristica'});
    }
}

export default TipoCaracteristicaAPI;