import GenericApi from "root-resources/generic-api";
import ReferenciaNutricional from "../model/referencia-nutricional";

/**
 * API de referência nutricional
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 * @extends {RootApi}
 */
class ReferenciaNutricionalAPI extends GenericApi<ReferenciaNutricional> {
    /**
     * Injeta a instância do axios com o endpoint base referente as referências nutricionais
     */
    public constructor() {
        super({ baseEndpoint: 'referencia-nutricional' });
    }
}

export default ReferenciaNutricionalAPI;