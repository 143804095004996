import URL from 'root-resources/URL';
import { AxiosResponse } from 'axios';
import ReportPCP from 'root-models/report';
import GenericApi from 'root-resources/generic-api';


/**
 * Classes para buscar informações referentes aos reports do pcp
 *
 * @author Marcos Davi
 */
class ReportGeneralAPI<E> extends GenericApi<E> {
  /**
   * Injeta as configurações da api
   */
  public constructor(value?: string) {
    super({ baseURL: `${URL.REPORTGENERAL + (value ?? '')}` });
  }

  /**
   * Busca o relatório de uma ficha técnica
   * @param notIncludeModoPreparo - Indica se o modo de preparo deve ser incluído (false) ou não (true)
   */
  public async fetchReportFichaTecnica(id: string, notIncludeModoPreparo: boolean): Promise<AxiosResponse<ReportPCP>> {
    return this.api.get(`/fichatecnica/${id}${notIncludeModoPreparo ? '?notModoPreparo=true' : '?notModoPreparo=false'}`);
  }
}

export default ReportGeneralAPI;