/**
 * Enum de tipos de etapas
 *
 * @author davi takayama
 */
 enum TipoEtapaEnum {
  INICIAL,
  SEQUENCIAL,
  CONCORRENTE,
  FINAL
}

export default TipoEtapaEnum;
