import { ButtonFAB, ButtonGrid, DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { ContentCopy } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import SituacaoProdutoEnum from 'root-enumerations/situacao-produto-enum';
import TipoItemEnum from 'root-enumerations/tipo-item-enum';
import Busca from 'root-models/parametrosbusca';
import Resposta from 'root-models/respostaAPI';
import { TableOrder } from 'root-models/table-order';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { buildQuery } from 'root-utils/build-query';
import { useStyles } from 'root-views/app.styles';
import Produto from '../model/produto';
import ProdutoAPI from '../resource/produto';
import { tiposItem } from 'root-utils/tipo-item';

/**
 * Tela de listagem de Produtos
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {ProdutoPropType} props
 */
function Produtos(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const history = useNavigate();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  const api = new ProdutoAPI();
  const [pageData, setPageData] = useState<Resposta>();
  const [selected, setSelected] = useState<Produto | undefined>();
  const [dataFiltered, setDataFiltered] = useState<Produto[]>([]);
  const params = useRef<Busca>({ page: 1, orderField: 'nmProduto', orderType: 'ASC' });
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  const filters = useMemo(() => ([
    {
      field: 'cdProduto',
      label: 'Cód. Item',
      type: TipoFiltroEnum.STRING,
    },
    {
      field: 'nmProduto',
      label: 'Nome',
      type: TipoFiltroEnum.STRING,
    },
    {
      field: 'tpItem',
      label: 'Tipo de Item',
      type: TipoFiltroEnum.ENUM,
      enumOptions: [
        { label: 'Mercadoria para revenda', value: TipoItemEnum.MERCADORIA_PARA_REVENDA },
        { label: 'Materia Prima', value: TipoItemEnum.MATERIA_PRIMA },
        { label: 'Embalagem', value: TipoItemEnum.EMBALAGEM },
        { label: 'Produto em processo', value: TipoItemEnum.PRODUTO_EM_PROCESSO },
        { label: 'Produto acabado', value: TipoItemEnum.PRODUTO_ACABADO },
        { label: 'Subprodutos', value: TipoItemEnum.SUBPRODUTO },
        { label: 'Produto intermediário', value: TipoItemEnum.PRODUTO_INTERMEDIARIO },
        { label: 'Material de uso e consumo', value: TipoItemEnum.MATERIAL_DE_USO_E_CONSUMO },
        { label: 'Ativo imobilizado', value: TipoItemEnum.ATIVO_IMOBILIZADO },
        { label: 'Serviços', value: TipoItemEnum.SERVICOS },
        { label: 'Outros insumos', value: TipoItemEnum.OUTROS_INSUMOS },
        { label: 'Outras', value: TipoItemEnum.OUTRAS },
      ],
    },
    {
      field: 'unidadeMedida.dsUnidadeMedida',
      label: 'Un. de medida',
      type: TipoFiltroEnum.STRING,
    },
    {
      field: 'stProduto',
      label: 'Status',
      type: TipoFiltroEnum.ENUM,
      enumOptions: [
        {
          label: 'Ativo',
          value: SituacaoProdutoEnum.ATIVO,
        },
        {
          label: 'Inativo',
          value: SituacaoProdutoEnum.INATIVO,
        },
        {
          label: 'Descontinuado',
          value: SituacaoProdutoEnum.DESCONTINUADO
        }
      ],
    },
  ]), []);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Cadastro de Produto');
    if (ScreensKeysEnum.PRODUTO in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.PRODUTO].filters, ['stProduto', 'tpItem'], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData();
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'nmProduto', orderType: 'ASC' };

    loadData();
  }

  function handleChangeFilters(data: any[]) {
    const staticFields = buildQuery(data, ['stProduto', 'tpItem'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }

  function loadData() {
    appLayoutActions.setLoading(true);
    api.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Produto[]);
      })
      .catch(() => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  const handleClickEdit = () => {
    if (!selected) return;
    history(`/cadastros/produto/editar/${selected.idProduto}`);
  };


  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.PRODUTO}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={handleChangeFilters}
          filtersDefinitions={filters}
        />
      </section>

      <section id='tabela' className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          disableEdit={!selected}
          buttonEditProps={{ disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
          onClickEdit={handleClickEdit}
          customButtons={[
            <ButtonGrid
              icon={<ContentCopy style={{ color: theme.palette.secondary.main }} />}
              show={true}
              disabled={!selected}
              disabledColor={theme.palette.grey[100]}
              backgroundColor={'#8a5ab4'}
              onClick={() => history('/cadastros/produto/novo', { state: selected!.idProduto })}
            />,
          ]}
        />

        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<Produto>
            columns={[
              {
                field: 'cdProduto',
                headerName: 'Cód. Item',
                col: 2,
              },
              {
                field: 'nmProduto',
                headerName: 'Nome',
                col: 3,
              },
              {
                field: 'tpItem',
                headerName: 'Tipo de Item',
                valueGetter: (node) => tiposItem.find(e => e.codigo === node.data.tpItem)!.descricao,
                col: 2,
                sortable: false
              },
              {
                field: 'unidadeMedidaVenda.dsUnidadeMedida',
                headerName: 'Un. de medida',
                col: 3,
              },
              {
                valueGetter: (node) => {
                  switch (node.data.stProduto) {
                    case 0:
                      return 'Inativo';
                    case 1:
                      return 'Ativo';
                    case 2:
                      return 'Descontinuado';
                    default:
                      return 'Ativo';
                  }
                },
                col: 2,
                headerName: 'Status',
                field: 'stProduto',
                sortable: false
              },
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handleChangePage}
            onOrderChange={handleChangeOrder}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/produto/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  );
}

export default Produtos;
