import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FichaTecnicaAPI from '../resources/api';
import { DiagramaEtapas, LoadingSwal, Modal, Swal } from 'root-components';
import { ButtonFABMenu, VerticalTabs } from '@bubotech/sumora-react-components';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import CategoriaNutricionalAPI from '../../categorianutricional/resource/categorianutricional';

// Models
import FichaTecnica from '../models/fichatecnica';
import MatrizNutricional from '../models/matriznutricional';
import EtapaFichaTecnica from '../models/etapafichatecnica';
import CategoriaNutricional from '../../categorianutricional/model/categorianuticional';

// Enums
import SituacaoEnum from 'root-enumerations/situacao-enum';
import TipoEtapaEnum from 'root-enumerations/tipo-etapa-enum';
import SituacaoRegistroEnum from 'root-enumerations/situacao-registro';

// Redux
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import ContextActions from 'root-states/actions/context-actions';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { ContextStateType } from 'root-states/reducers/context-reducer';

// Abas formulário
import Etiqueta from './etiqueta';
import ResumoFichaTecnica from './resumo';
import HistoricoVersoes from './historico';
import PrincipalFichaTecnica from './principal';
import AlergenicoFichaTecnica from './alergenico';
import ComposicaoFichaTecnica from './lista-composicao';
import NutricionalFichaTecnica from './lista-nutricional';

// Ícones
import iconDiagrama from 'root-icons/icon-diagrama.svg';
import iconEtiqueta from 'root-icons/icon-etiqueta.svg';
import IconRelatorio from 'root-icons/icon-impressao.svg';
import iconResumo from 'root-icons/icon-ficha-tecnica.svg';
import iconPrincipal from '../assets/icons/icon-principal.svg';
import iconHistorico from '../assets/icons/icon-historico.svg';
import iconComposicao from '../assets/icons/icon-composicao.svg';
import iconNutricional from '../assets/icons/icon-nutricional.svg';
import iconAlergenos from '../assets/icons/icon-alergenos.svg';
import iconCaracteristica from '../assets/icons/icon-caracteristica.svg';

// Utils
import { handleClickReport } from '../utils';
import { useStyles } from '../assets/styles/fichatecnica.styles';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import CaracteristicaFichaTecnica from './caracteristica';
import SimNao from 'root-enumerations/sim-nao-enum';

interface EditarFichaTecnicaPropType { }

/**
 * View de Edição de uma Ficha Técnica de Produto
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {EditarFichaTecnicaPropType} props
 */
function EditarFichaTecnica(props: EditarFichaTecnicaPropType): JSX.Element {
  const diagrama = useRef('');
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id }: Readonly<Params<string>> = useParams();

  const api = new FichaTecnicaAPI();
  const categoryApi = new CategoriaNutricionalAPI();

  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);

  const [hideFab, setHideFab] = useState<boolean>(false);
  const [globalDirty, setGlobalDirty] = useState<boolean>(false);
  const [showDiagrama, setShowDiagrama] = useState<boolean>(false);
  const [enableReinitialize, setEnableReinitialize] = useState<boolean>(false);
  const [showDiagramaHistorico, setShowDiagramaHistorico] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FichaTecnica>({
    produto: undefined,
    diametro: undefined,
    stFichaTecnica: SituacaoEnum.ATIVO,
    idFichaTecnica: '',
    produtoEtapaList: [],
    nrVersao: 1,
    historicoList: [],
    matrizNutricionalList: [],
    dsPorcao: '',
    dsPorcaoEmbalagem: '',
    fichaTecnicaAlergenicoList: [],
    dsEtiqueta: '',
    nrQtdeRendimento: '',
    unidadeMedidaRendimento: undefined,
    fichaTecnicaCaracteristicaList: [],
    snAcucar: SimNao.NAO,
    snLactose: SimNao.NAO,
    snGluten: SimNao.NAO,
  });

  const formik = useFormik<FichaTecnica>({
    initialValues,
    enableReinitialize,
    validateOnBlur: true,
    validationSchema: Yup.object({
      produto: Yup.object().required('Campo obrigatório'),
      produtoEtapaList: Yup.array().test('produtoEtapaList', '', (value) => {
        if (value && formik.values.produto && formik.isSubmitting && value?.length < 1) {
          Swal({
            showConfirmButton: true,
            title: 'Erro',
            text: 'Necessário cadastrar etapas',
            icon: 'error',
          }).then(() => contextActions.changeTab(1));

          return false;
        }
        return true;
      })
    }),
    onSubmit: handleSubmitFormik
  });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar("Ficha Técnica");
    contextActions.setPrincipalFormSubmit(formik.submitForm);
    setEnableReinitialize(true);

    if (!id && !state) loadCategories(initialValues);
  });

  function getIconStyle(index: number) {
    return index === contextProps.selectedTab ? `${classes.icone} ${classes.iconeSelecionado}` : classes.icone;
  }

  function buildFichaTecnica(ficha: FichaTecnica, copy?: boolean) {
    let novaFicha = { ...ficha };

    novaFicha.matrizNutricionalList.forEach(matriz => {
      matriz.referenciaNutricionalList.forEach(referencia => {
        referencia.stRegistro = SituacaoRegistroEnum.UPDATE;
        referencia.vlNutricional = new Intl.NumberFormat('pt-br', { minimumFractionDigits: 2 }).format(referencia.vlNutricional as number);
      });
      matriz.stRegistro = 1;
    });

    novaFicha.produtoEtapaList = ficha.produtoEtapaList.map(etapa => {
      const predecessoras = etapa.produtoEtapasPredecessoras.map(etapaPredecessora => etapaPredecessora.etapaPredecessora as EtapaFichaTecnica);
      const composicao = etapa.produtoComposicoes.map(composicao => ({
        ...composicao,
        nrQuantidade: new Intl.NumberFormat('pt-br', { minimumFractionDigits: 8 }).format(composicao?.nrQuantidade as number)
      }));
      return {
        ...etapa,
        stRegistro: 0,
        produtoEtapasPredecessoras: predecessoras,
        produtoComposicoes: composicao
      };
    });

    novaFicha.historicoList = ficha.historicoList?.map(versao => {
      return {
        ...versao,
        produtoEtapaList: versao.produtoEtapaList.map(etapa => ({
          ...etapa,
          produtoEtapasPredecessoras: etapa.produtoEtapasPredecessoras.map(etapaPredecessora => etapaPredecessora.etapaPredecessora as EtapaFichaTecnica),
          stRegistro: 0
        }))
      };
    });

    if (copy) {
      novaFicha.nrVersao = 1;
      novaFicha.produto = undefined;
      novaFicha.diametro = undefined;
      novaFicha.idFichaTecnica = undefined;
      novaFicha.historicoList = [];
    }

    setInitialValues(novaFicha);
    loadCategories(novaFicha);
  }

  useEffect(() => {
    if (!enableReinitialize || (!id && !state)) return;
    appLayoutActions.setLoading(true);

    api.findById(id ?? state)
      .then(res => {
        buildFichaTecnica(res.data, Boolean(state));
      })
      .catch(() => {
        Swal({
          showConfirmButton: true,
          title: 'Erro',
          text: 'Ficha Técnica não encontrada',
          icon: 'error',
        }).then(() => navigate(-1));
      })
      .finally(() => {
        appLayoutActions.setLoading(false);
      });
    // eslint-disable-next-line
  }, [enableReinitialize]);

  async function loadCategories(ficha: FichaTecnica) {
    const nutritionalMatrixAux: MatrizNutricional[] = [...ficha.matrizNutricionalList];
    await categoryApi.getAllCategorias().then(res => {

      res.data.forEach((category: CategoriaNutricional) => {
        if (!nutritionalMatrixAux.find(matrix => matrix.categoriaNutricional?.idCategoriaNutricional === category.idCategoriaNutricional)) {
          nutritionalMatrixAux.push({
            referenciaNutricionalList: [],
            stRegistro: !ficha.idFichaTecnica ? 1 : 0,
            categoriaNutricional: category,
          });
        }
      });

      setInitialValues(prev => ({
        ...prev,
        matrizNutricionalList: nutritionalMatrixAux
      }));
      setEnableReinitialize(false);
      // formik.setFieldValue('matrizNutricionalList', nutritionalMatrixAux)
    });
  }

  useMemo(() => ![1, 2].includes(contextProps.selectedTab) && setHideFab(false), [contextProps.selectedTab]);

  const buttonFab = useMemo(() =>
    !hideFab &&
    <ButtonFABMenu
      disabled={isLoading}
      primaryAction={{
        onClick: formik.handleSubmit,
        disabled: !formik.dirty && !globalDirty,
        iconProps: { color: 'inherit' }
      }}
      secondaryAction={{
        onClick: () => navigate(-1),
        iconProps: { color: 'inherit' }
      }}
      additionalActions={id ? [
        {
          onClick: () => setShowModal(true),
          icon: <img src={IconRelatorio} alt='ícone-relatorio' style={{ height: 21, width: 21, color: '#FFF' }} />,
          tooltipLabel: 'Relatório'
        }
      ] : []}
    />
    //eslint-disable-next-line
    , [hideFab, isLoading, formik, globalDirty]);

  useEffect(() => {
    contextActions.principalTabHasUnsavedData(formik.dirty || globalDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, globalDirty]);

  const etapasAtivas = useMemo(() => {
    let newEtapas = formik.values.produtoEtapaList.filter(etapa => etapa.stRegistro !== 2);
    return newEtapas;
  }, [formik.values.produtoEtapaList]);

  useEffect(() => {
    let novoDiagrama = '';
    const etapas = [...etapasAtivas];

    etapas.filter(etapa => etapa.stRegistro !== 2)?.forEach(etapa => {
      if (etapa.produtoEtapasPredecessoras.length) {
        etapa.produtoEtapasPredecessoras.forEach(etapaPredecessora => {
          if (etapaPredecessora.stRegistro !== 2) {
            novoDiagrama += `${etapaPredecessora.idProdutoEtapa}
          -->${etapa.idProdutoEtapa}(<div>${etapa.etapa?.nmEtapa}</div>);`;
          }
        });
      } else {
        novoDiagrama += `${etapa.idProdutoEtapa}(<div>${etapa.etapa?.nmEtapa}</div>);`;
      }
    });

    diagrama.current = novoDiagrama;
  }, [etapasAtivas, diagrama]);

  const verDiagrama = <span className={classes.diagram} onClick={() => setShowDiagrama(true)} key={0}>
    <p className={classes.diagramLabel}>Ver Diagrama</p>
    <img alt='Ícone Diagrama' src={iconDiagrama} />
  </span>;

  return (
    <main style={{ height: '100%' }}>
      <VerticalTabs
        title='Ficha'
        menuEffect
        selectedIndex={contextProps.selectedTab}
        onChangeIndex={(tab) => {
          contextActions.tryChangeTab(tab);

          if (contextProps.allowTabChange && hideFab) setHideFab(false);
        }}
        tabs={[
          {
            label: 'Principal',
            content: <PrincipalFichaTecnica
              form={formik}
            />,
            icon: <img alt='ícone-principal' src={iconPrincipal} className={getIconStyle(0)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Composição',
            content: <ComposicaoFichaTecnica
              form={formik}
              hideFab={setHideFab}
              setGlobalDirty={setGlobalDirty}
            />,
            icon: <img alt='ícone-composição' src={iconComposicao} className={getIconStyle(1)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Nutricional',
            content:
              <NutricionalFichaTecnica
                form={formik}
                setGlobalDirty={setGlobalDirty}
              />,
            icon: <img alt='ícone-nutricional' src={iconNutricional} className={getIconStyle(2)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Alergenico',
            content:
              <AlergenicoFichaTecnica
                setFieldValuesForm={formik.setFieldValue}
                valuesFt={formik.values}
              />,
            icon: <img alt='ícone-Alergenico' src={iconAlergenos} className={getIconStyle(3)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Características',
            content:
              <CaracteristicaFichaTecnica
                setFieldValuesForm={formik.setFieldValue}
                valuesFt={formik.values}
              />,
            icon: <img alt='ícone-característica' src={iconCaracteristica} className={getIconStyle(4)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Etiqueta',
            content: <Etiqueta form={formik} />,
            icon: <img alt='ícone-etiqueta' src={iconEtiqueta} className={getIconStyle(5)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Resumo',
            content: <ResumoFichaTecnica
              hideFab={setHideFab}
              etapas={formik.values.produtoEtapaList}
            />,
            icon: <img alt='ícone-resumo' src={iconResumo} className={getIconStyle(6)} />,
            tabOptions: [
              verDiagrama
            ]
          },
          {
            label: 'Histórico de Versões',
            content:
              <HistoricoVersoes
                form={formik}
                id={id}
                versoes={formik.values.historicoList}
                hideFab={setHideFab}
                setDiagrama={value => diagrama.current = value}
                setShowDiagrama={setShowDiagramaHistorico}
              />,
            disabled: !formik.values?.historicoList?.length ?? true,
            icon: <img alt='ícone-histórico' src={iconHistorico} className={formik.values?.historicoList?.length ? getIconStyle(6) : classes.iconDisabled} />,
            tabOptions: showDiagramaHistorico ? [verDiagrama] : []
          }
        ]}
      />
      <Modal
        title='Deseja incluir o Modo de Preparo?'
        open={showModal}
        onFinishLabel='SIM'
        onCloseLabel='NÃO'
        onClose={() => {
          setShowModal(false);
          handleClickReport(id ?? '', formik.values.produto?.nmProduto ?? '', true, navigate);
        }}
        onFinish={() => {
          setShowModal(false);
          handleClickReport(id ?? '', formik.values.produto?.nmProduto ?? '', false, navigate);
        }}
        customWidth={450}
      />
      <DiagramaEtapas
        open={showDiagrama}
        subTitle={''}
        data={diagrama.current}
        title='Diagrama Etapas'
        onClose={() => setShowDiagrama(false)}
      />
      {buttonFab}
    </main>
  );

  function buildEtapaList(values: EtapaFichaTecnica[]) {
    return values.map(etapa => ({
      ...etapa,
      produtoComposicoes: etapa.produtoComposicoes.map(composicao => ({
        ...composicao,
        stRegistro: 0,
        nrQuantidade: typeof composicao.nrQuantidade === 'string' ? Number(composicao.nrQuantidade.toString().replaceAll('.', '').replace(',', '.')) : composicao.nrQuantidade
      })),
      produtoEtapasPredecessoras: etapa.produtoEtapasPredecessoras?.map((etapaPredecessora) => {
        return {
          produtoEtapa: { idProdutoEtapa: etapa.idProdutoEtapa ?? etapa.nrOrdem.toString() },
          etapaPredecessora: { idProdutoEtapa: etapaPredecessora.idProdutoEtapa },
          produtoEtapasPredecessoras: [] as EtapaFichaTecnica[],
          nmEtapa: etapa.nmEtapa,
          idProdutoEtapa: etapa.idProdutoEtapa,
          nrOrdem: etapa.nrOrdem,
          fichaTecnica: null,
          produtoComposicoes: [],
          stRegistro: 0
        };
      })
    }));
  }

  function buildNutritionalMatrix(matrix: MatrizNutricional[]) {
    let newMatrix = matrix.map(category => {
      let refs = category.referenciaNutricionalList.filter(ref => ref.vlNutricional);

      refs = refs.map(ref => ({
        ...ref,
        vlNutricional: ref.vlNutricional?.toString().replaceAll('.', '').replace(',', '.')
      }));

      return ({
        ...category,
        referenciaNutricionalList: refs
      });
    });

    return newMatrix.filter(category => category.referenciaNutricionalList.length > 0);
  }
  /**
   * Manipula o evento de submit do Formik
   * 
   * @param {FichaTecnica} values - valores do formulário
  */
  async function handleSubmitFormik(values: FichaTecnica) {
    let error = false;
    if ((values.diametro?.tpDiametro || values.diametro?.tpDiametro === 0) && !values.diametro.nrDiametro) {
      formik.setErrors({
        diametro: 'campo obrigatorio'
      });
      contextActions.tryChangeTab(0);
      return;
    }
    if (!values.produtoEtapaList.find(etapa => etapa.tpEtapa === TipoEtapaEnum.FINAL && etapa.stRegistro !== 2)) {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: 'Etapa final não cadastrada',
        icon: 'error',
      });

      return;
    }

    const body: FichaTecnica = {
      ...values,
      idFichaTecnica: id,
      produtoEtapaList: buildEtapaList(values.produtoEtapaList),
      matrizNutricionalList: buildNutritionalMatrix(values.matrizNutricionalList)
    };

    body.fichaTecnicaCaracteristicaList = body.fichaTecnicaCaracteristicaList.map(caracteristica => {
      caracteristica = { ...caracteristica };
      if (caracteristica.idFichaTecnicaCaracteristica && !caracteristica.stRegistro)
        caracteristica.stRegistro = SituacaoRegistroEnum.UPDATE;
      return caracteristica;
    });

    body.fichaTecnicaAlergenicoList = body.fichaTecnicaAlergenicoList.map(alergenico => {
      alergenico = { ...alergenico };
      if (alergenico.idFichaTecnicaAlergenico && !alergenico.stRegistro)
        alergenico.stRegistro = SituacaoRegistroEnum.UPDATE;
      return alergenico;
    });

    LoadingSwal({ text: 'Carregando' });
    if (!id) await api.save(body).catch(e => error = true);
    else await api.update(body).catch(e => error = true);

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success',
      }).then(() => navigate(-1));
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error',
      });
    }
  }
}

export default EditarFichaTecnica;