import HomeIcon from '@mui/icons-material/Home';
import iconCadastro from 'root-icons/icon-cadastro.svg';

/**
 * Modelo das definições de um item do menu lateral
 *
 * @author davi takayama <davi.takayamas@kepha.com.br>
 */
export interface MenuLateralItemModel {
  label: string | JSX.Element;
  Icon?: (props: any) => JSX.Element;
  route?: string;
  childrens?: MenuLateralItemModel[];
  childrensBackgroundColor?: LevelColors;
}


/**
 * Enum de cores de fundo dos itens do menu pra cada nível.
 * O primeiro nível são os filhos do primeiro item.
 */
export enum LevelColors {
  FIRST_LEVEL = '#3252A8',
  SECOND_LEVEL = '#5C78C7',
  THIRD_LEVEL = '#8DA5E8',
}

/**
 * Lista de definições dos itens do menu lateral
 */
const itensMenuLateral: MenuLateralItemModel[] = [
  {
    label: 'Dashboard',
    Icon: props => <HomeIcon {...props} />,
    route: '/'
  },
  {
    label: 'Cadastros',
    Icon: (props) => (
      <img src={iconCadastro} alt='ícone-cadastros' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      {
        label: 'Produto',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          { label: "Produto", route: '/cadastros/produto' },
          {
            label: "Ficha Técnica",
            childrensBackgroundColor: LevelColors.THIRD_LEVEL,
            childrens: [
              { label: "Ficha técnica", route: '/cadastros/ficha-tecnica' },
              {
                label: "Nutricional",
                childrensBackgroundColor: LevelColors.THIRD_LEVEL,
                childrens: [
                  { label: "Categoria", route: '/cadastros/categoria-nutricional' },
                  { label: "Referência", route: '/cadastros/referencia-nutricional' },
                ]
              }
            ]
          },
          {
            label: 'Cadastros',
            childrensBackgroundColor: LevelColors.THIRD_LEVEL,
            childrens: [
              { label: 'Cor', route: '/cadastros/cor-produto' },
              { label: 'Marca', route: '/cadastros/marca' },
              { label: 'Etapa', route: '/cadastros/etapa' },
              { label: 'Unidade de Medida', route: '/cadastros/unidade-medida' },
              { label: 'Aro', route: '/cadastros/aro' },
              { label: 'Alérgenos', route: '/cadastros/alergenos' },
              {
                label: "Qualidade",
                childrensBackgroundColor: LevelColors.THIRD_LEVEL,
                childrens: [
                  { label: 'Característica Qualidade', route: '/cadastros/caracteristica-qualidade' },
                  { label: 'Tipo de Característica', route: '/cadastros/tipo-caracteristica' },
                ]
              }
            ]
          }
        ],
      },
      {
        label: 'Upload',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          { label: "Produto", route: '/cadastros/upload-produto' },
          { label: "Ficha Técnica", route: '/cadastros/upload-ficha-tecnica' }
        ]
      },
    ]
  }
];

export default itensMenuLateral;
