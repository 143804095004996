import URL from 'root-resources/URL';
import axios from 'axios';
import Usuario from 'root-models/configuracoesdeacesso/usuario';
import RootApi from 'root-resources/root-api';
import Token from 'root-models/token';
import { UsuarioEmpresaLogada } from 'root-models/usuario-empresa-logada';

/**
 * Classes para buscar informações referentes a autenticação do usuário
 *
 * @author davi takayama
 * @author davi takayama
 */
export default class Authentication extends RootApi {
  /**
   * Parâmetros de autenticação para a request de login
   */
  private readonly loginParams: Record<string, any>;

  /**
   * Key to token no localStorage
   */
  private static readonly TOKEN_KEY = 'token';

  /**
   * Injeta as configurações da api e os parâmetros do login
   */
  public constructor() {
    super({ baseURL: URL.OAUTH });
    this.loginParams = {
      client_id: 'azagros',
      client_secret: 'azagros-secret',
      code: '1234',
    };
  }

  /**
   * consulta qual empresa o usuário está logado
   */
  public async findUsuarioEmpresaLogado() {
    return this.api.get<UsuarioEmpresaLogada>(`/usuario-empresa-logada`); //todo
  }

  /**
   * troca a empresa logada do usuário
   */
  public async changeEmpresa(values: UsuarioEmpresaLogada) {
    return this.api.put<UsuarioEmpresaLogada>('/usuario-empresa-logada', values);
  }

  /*
   * Inicializa interceptors ao iniciar o uso do sistema
   */
  public static initInterceptors() {
    axios.interceptors.request.use(
      (query) => {
        const token = Authentication.getToken();

        if (token && query.headers) {
          query.headers.Authorization = `Bearer ${token.access_token}`;
        }

        return query;
      },
      (err) => {
        if (process.env.REACT_APP_IN_DEV) {
          let errInJson;
          try {
            errInJson = JSON.stringify(err, null, 2);
          } catch (stringifyErr) {
            errInJson = null;
          }

          console.error(
            '> Ocorreu um erro em uma request: \n',
            err,
            '\n',
            errInJson ?? ''
          );
        }

        return Promise.reject(err);
      }
    );
  }

  /**
   * @returns {(Token | null)} Objeto do token de autenticação do localStorage
   */
  public static getToken(): Token | null {
    const stringObj = localStorage.getItem(Authentication.TOKEN_KEY);
    return stringObj ? JSON.parse(stringObj) : null;
  }

  /**
   * Setta o objeto do token de autenticação no localStorage
   *
   * @param {(Token | null)} token - Objeto do token
   */
  public static setToken(token: Token | null) {
    localStorage.setItem(
      Authentication.TOKEN_KEY,
      token ? JSON.stringify(token) : ''
    );
  }

  /**
   * Certifica-se que o token do usuário está ativo e atualiza o token
   */
  public async refreshToken() {
    const token = Authentication.getToken();

    if (!token) {
      return Promise.reject();
    }

    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', this.loginParams.client_id);
    params.append('client_secret', this.loginParams.client_secret);
    params.append('code', this.loginParams.code);
    params.append('username', token.email);
    params.append('refresh_token', token.refresh_token);

    return this.api
      .post<Token>('/login', params)
      .then((response) => {
        const newToken: Token = {
          ...response.data,
          email: token.email,
        };
        Authentication.setToken(newToken);
      })
      .catch((err) => {
        Authentication.setToken(null);
        return Promise.reject(err);
      });
  }

  /*
   * Realiza o login do usuário
   */
  public async login(mail: string, password: string) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', this.loginParams.client_id);
    params.append('client_secret', this.loginParams.client_secret);
    params.append('code', this.loginParams.code);
    params.append('username', mail);
    params.append('password', password);

    return this.api
      .post<Token>('/login', params)
      .then((response) => {
        const newToken: Token = {
          ...response.data,
          email: mail,
        };
        Authentication.setToken(newToken);
      })
      .catch((err) => {
        Authentication.setToken(null);
        return Promise.reject(err);
      });
  }

  /*
   * Realiza o logout do usuário
   **/
  public static logout() {
    Authentication.setToken(null);
  }

  /**
   * Envia um email com um link para recuperar a senha do usuário
   */
  public async recuperarSenha(mail: string) {
    return this.api.post<Usuario>('/usuario/change-password/' + mail);
  }

  /**
   * Reseta a senha do usuário
   */
  public async resetarSenha(mail: string, senha: string, token: string) {
    return this.requestConfirmarResetarCadastro(
      mail,
      senha,
      token,
      'update-password'
    );
  }

  /**
   * Confirma o cadastro do usuário
   */
  public async confirmarCadastro(mail: string, senha: string, token: string) {
    return this.requestConfirmarResetarCadastro(
      mail,
      senha,
      token,
      'create-password'
    );
  }

  /**
   * Request para alterar senha ou confirmar cadastro
   */
  private requestConfirmarResetarCadastro(
    mail: string,
    senha: string,
    token: string,
    endpoint: string
  ) {
    this.api.interceptors.request.use(async (query) => {
      if (query && query.headers) {
        query.headers.Authorization = `Bearer ${token}`;
      }

      return query;
    });

    const params = new URLSearchParams();
    params.append('new-password', senha);
    params.append('code', token);

    return this.api.post<Usuario>(`/usuario/${endpoint}`, params);
  }

  /**
   * Envia um email para o usuário alterar a senha
   */
  public async alterarSenha(values: Usuario) {
    return this.api.put<Usuario>('/usuario/alterarsenha', values);
  }
}
