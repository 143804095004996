import { makeStyles } from '@mui/styles';

/**
 * Estilos da view de perfil
 **/
const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    right: '30px',
    bottom: '210px',
    zIndex: 2000
  },
  card: {
    width: '100%',
    height: 182,
    borderRadius: 0
  },
  textField: {
    height: 75,
    padding: 12
  },
  titleCard: {
    backgroundColor: 'rgb(139, 200, 165, 0.1)',
    height: 60,
    fontSize: 20,
    color: 'rgb( 0, 0, 0, 0.7)',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30
  }
});

export { useStyles };
