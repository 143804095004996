import { makeStyles } from "@mui/styles";

/**
 * Estilos da tabela editável
 */
const useStyles = makeStyles((theme: any) => ({
  container: {
    margin: '20px 0',
    width: '100%'
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all .8s ease-in-out',
  },
  headerRow: {
    borderTop: `1px solid ${theme.palette.grey['100']}`,
    height: 50,
    padding: '15px 15px',
    borderBottom: `2px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'rgba(195, 210, 227, 0.25)'
  },
  headerCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 700,
    height: 50,
    color: `${theme.palette.grey['300']}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerCellSwitch: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 700,
    height: 50,
    color: `${theme.palette.grey['300']}`,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  removeIcon: {
    cursor: 'pointer',
    marginRight: 15
  },
  tableRow: {
    height: 47,
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    alignItems: 'center',
    display: 'flex',
    padding: '0 15px'
  },
  oddRow: {
    backgroundColor: 'rgba(195, 210, 227, 0.12)'
  },
  evenRow: {
    backgroundColor: theme.palette.secondary.main,
  },
  selectedRow: {
    borderBottom: `1px solid ${theme.palette.primary.main} !important`,
    backgroundColor: 'rgba(92, 120, 199, 0.22) !important'
  },
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: 15,
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: 'transparent !important',
      borderBottom: 'none !important',
      '&::before': {
        borderBottom: 'none !important',
      },
      '&::focused': {
        borderBottom: 'none !important'
      }
    }
  },
  cellSwitch: {
    display: 'flex',
    justifyContent: 'flex-end', 
    alignItems: 'center', 
  },
  input: {

  }
}));

export { useStyles };