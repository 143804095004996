import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import { useParams, Params } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { ButtonFABMenu, TextField, Switch } from '@bubotech/sumora-react-components/lib';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';
import MarcaApi from '../resource/marca';

/**
 * Tipo dos valores do formik
 */
export type EditarMarcaFormikValuesType = {
  idMarca?: string;
  nmMarca: string;
  stMarca: SituacaoEnum;
};

export type EditarMarcaPropType = {};

/**
 * View de edição de uma marca
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {EditarMarcaPropType} props
 */
function EditarMarcas(props: EditarMarcaPropType): JSX.Element {
  const classes = useStyles();
  const history = useNavigate();
  const { id }: Readonly<Params<string>> = useParams();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

  const api = new MarcaApi();

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] =
    useState<EditarMarcaFormikValuesType>({
      nmMarca: '',
      stMarca: 1
    } as EditarMarcaFormikValuesType);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, dirty } =
    useFormik<EditarMarcaFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: true,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        stMarca: Yup.boolean().default(true).required('Campo obrigatório'),
        nmMarca: Yup.string().required('Campo obrigatório'),
      }),
      onSubmit: handleSubmitFormik,
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(id ? 'Edição de Marca' : 'Cadastro de Marca');

    if (id) setEnableReinitialize(true);
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api.findById(id).then(res => {
      setInitialValues(res.data);
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  useVerificaDados({
    funcaoPrincipalProps: { dirty },
    handleSubmit,
    ignoreTabChange: true
  });

  return (
    <main style={{ height: '100%' }}>
      <section id='titulo-pagina'>
        <div className={classes.titleContainer}>Marca</div>
      </section>

      <section id='formulario'>
        <Grid container className={classes.content}>
          <Grid item xs={6} className={classes.gridCell}>
            <TextField
              label='Nome'
              name='nmMarca'
              inputProps={{ maxLength: 999 }}
              value={values.nmMarca}
              error={touched.nmMarca && errors.nmMarca !== undefined}
              helperText={touched.nmMarca && errors.nmMarca !== undefined ? `${errors.nmMarca}` : ''}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          </Grid>

          <Grid item className={classes.switchContainer}>
            <Switch
              label={'Ativo'}
              checked={values.stMarca ? true : false}
              defaultChecked
              onBlur={handleBlur}
              onChange={() => setFieldValue('stMarca', values.stMarca ? 0 : 1)}
            />
          </Grid>
        </Grid>
      </section>

      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
        }}
        secondaryAction={{
          onClick: () => history('/cadastros/marca'),
          iconProps: { color: 'inherit' },
        }}
      />
    </main>
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarMarcaFormikValuesType} values - Valores do submit
   */
  async function handleSubmitFormik(values: EditarMarcaFormikValuesType): Promise<void> {
    LoadingSwal({ text: 'Carregando' });
    let error = false;

    if (!id) {
      await api.save(values).catch(e => { error = true; });
    } else {
      await api.update(values).catch(e => { error = true; });
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success',
      });

      history('/cadastros/marca');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error',
      });
    }
  }
}

export default EditarMarcas;
