import React, { useRef, useState } from 'react';
import { handleClickReport } from '../utils';
import FichaTecnicaAPI from '../resources/api';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useStyles } from 'root-views/app.styles';
import { CardFilters, Modal, Swal } from 'root-components';
import { buildQuery } from 'root-utils/build-query';
import { Add, ContentCopy } from '@mui/icons-material';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { ButtonFAB, ButtonGrid, DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components';

// Enums
import SituacaoEnum from 'root-enumerations/situacao-enum';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

// Models
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import FichaTecnica from '../models/fichatecnica';
import { TableOrder } from 'root-models/table-order';

// Redux
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';


// Ícones
import IconRelatorio from 'root-icons/icon-impressao.svg';

interface FichaTecnicaPropType { }

/**
 * Tela de listagem de Fichas Técnicas
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {FichaTecnicaPropType} props
 */
function FichasTecnicas(props: FichaTecnicaPropType): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const api = new FichaTecnicaAPI();

  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  const [pageData, setPageData] = useState<Resposta>();
  const [selected, setSelected] = useState<FichaTecnica>();
  const [dataFiltered, setDataFiltered] = useState<FichaTecnica[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const filtros = useSelector<MainStateType, any>(state => state.cardFiltersReducer.data);
  const params = useRef<Busca>({ page: 1, orderField: 'produto.cdProduto', orderType: 'ASC' });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Ficha Técnica');
    if (ScreensKeysEnum.FICHA_TECNICA in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.FICHA_TECNICA].filters, ['stFichaTecnica'], []);
      params.current = { ...params.current, staticParams: staticFields };
    }

    loadData();
  });

  function handleChangeFilters(data: any[]) {
    const staticFields = buildQuery(data, ['stFichaTecnica'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };

    loadData();
  }

  function handleClickEdit() {
    if (!selected) return;

    navigate(`/cadastros/ficha-tecnica/editar/${selected.idFichaTecnica}`);
  }

  function handleClickCopy() {
    if (!selected) return;

    navigate('/cadastros/ficha-tecnica/nova', { state: selected.idFichaTecnica });
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (order.length) params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else params.current = { ...params.current, orderField: 'produto.cdProduto', orderType: 'ASC' };

    loadData();
  }

  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };

    loadData();
  }

  function loadData() {
    appLayoutActions.setLoading(true);

    api.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as FichaTecnica[]);
      })
      .catch(() => {
        Swal({
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.FICHA_TECNICA}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          changeFilters={handleChangeFilters}
          onRefreshFilters={setDataFiltered}
          filtersDefinitions={[
            {
              field: 'produto.cdProduto',
              label: 'Código',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'produto.nmProduto',
              label: 'Produto',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'stFichaTecnica',
              label: 'Status',
              type: TipoFiltroEnum.ENUM,
              enumOptions: [
                { label: 'Ativo', value: SituacaoEnum.ATIVO },
                { label: 'Inativo', value: SituacaoEnum.INATIVO },
              ]
            }
          ]}
        />
      </section>

      <section id='tabela' className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          buttonEditProps={{ disabled: !selected, disabledColor: theme.palette.grey['100'], backgroundColor: theme.palette.primary.main }}
          onClickEdit={handleClickEdit}
          customButtons={[
            <ButtonGrid
              disabled={!selected}
              disabledColor={theme.palette.grey['100']}
              icon={<img src={IconRelatorio} alt='ícone-relatorio' style={{ height: 21, width: 21, color: '#FFF' }} />}
              onClick={() => {
                setShowModal(true);
              }}
            />,
            <ButtonGrid
              disabled={!selected}
              disabledColor={theme.palette.grey['100']}
              backgroundColor={theme.palette.info.contrastText}
              icon={<ContentCopy color='secondary' />}
              onClick={handleClickCopy}
            />,
          ]}
        />
        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<FichaTecnica>
            columns={[
              {
                field: 'produto.cdProduto',
                headerName: 'Cód. Produto',
                col: 1
              },
              {
                field: 'produto.nmProduto',
                headerName: 'Produto',
                col: 10
              },
              {
                field: 'stFichaTecnica',
                headerName: 'Status',
                col: 1,
                valueGetter: (node) => ['Inativo', 'Ativo'][node.data.stFichaTecnica]
              },
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handleChangePage}
            onOrderChange={handleChangeOrder}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => navigate('/cadastros/ficha-tecnica/nova')}>
          <Add />
        </ButtonFAB>

        <Modal
          title='Deseja incluir o Modo de Preparo?'
          open={showModal}
          onFinishLabel='SIM'
          onCloseLabel='NÃO'
          onClose={() => {
            setShowModal(false);
            handleClickReport(selected?.idFichaTecnica ?? '', selected?.produto?.nmProduto ?? '', true, navigate);
          }}
          onFinish={() => {
            setShowModal(false);
            handleClickReport(selected?.idFichaTecnica ?? '', selected?.produto?.nmProduto ?? '', false, navigate);
          }}
          customWidth={450}
        />
      </section>
    </main>
  );
}

export default FichasTecnicas;