import React, { useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { useTheme } from '@mui/material';
import Resposta from 'root-models/respostaAPI';
import { MainStateType } from 'root-states';
import AlergenicosAPI from '../resource/api';
import Alergenicos from '../model/alergenos';
import Swal from 'root-components/swal/swal';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Busca from 'root-models/parametrosbusca';
import { useStyles } from 'root-views/app.styles';
import { buildQuery } from 'root-utils/build-query';
import { TableOrder } from 'root-models/table-order';
import { useDispatch, useSelector } from 'react-redux';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import CardFilters from 'root-components/cardfilters/card-filters';
import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab';
import DataTable from '@bubotech/sumora-react-components/lib/datatable';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';


export type AlergenicosPropType = {}

/**
 * Tela de listagem Alergênicos/ Alérgenos
 * 
 * @author Carlos Bageston <carlos.bageston@kepha.com.br>
 * @param {Alergenicos} props
*/
function Alergenos(props: AlergenicosPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const history = useNavigate();
  const theme = useTheme();
  const api = new AlergenicosAPI();

  const [dataFiltered, setDataFiltered] = useState<Alergenicos[]>([]);
  const [selected, setSelected] = useState<Alergenicos | undefined>();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'dsAlergenico', orderType: 'ASC' });
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Alérgenos');
    if (ScreensKeysEnum.ALERGENOS in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.ALERGENOS].filters, ['stAlergenico'], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  })
  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData();
  }

  //atualiza a ordem dos dados de uma tabela e carrega os dados atualizados com base na nova ordem
  function handleChangeOrder(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'dsAlergenico', orderType: 'ASC' };

    loadData();
  }

  //se houver uma linha selecionada ele envia para tela de edição
  function handleClickEdit() {
    if (!selected) return;
    history(`/cadastros/alergenos/editar/${selected.idAlergenico}`)
  }

  //construindo uma nova consulta e carregando os dados atualizados/filtrados
  function handleFilterChange(data: any[]) {
    const staticFields = buildQuery(data, ['stAlergenico'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }

  //Carregando dados para a tebela de listagem
  function loadData() {
    appLayoutActions.setLoading(true)
    api
      .findByPage(params.current)
      .then((res) => {
        setPageData(res.data)
        setDataFiltered(res.data.data as Alergenicos[])
      })
      .catch(() => {
        setDataFiltered([])
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        })
      })
      .finally(() => appLayoutActions.setLoading(false))
  }
  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.ALERGENOS}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={handleFilterChange}
          filtersDefinitions={[
            {
              field: 'dsAlergenico',
              label: 'Descrição',
              type: TipoFiltroEnum.STRING,
            },
            {
              field: 'stAlergenico',
              label: 'Status',
              selectableForSorting: false,
              type: TipoFiltroEnum.ENUM,
              enumOptions: [
                {
                  label: 'Ativo',
                  value: SituacaoEnum.ATIVO,
                },
                {
                  label: 'Inativo',
                  value: SituacaoEnum.INATIVO,
                },
              ],
            },
          ]}
        />
      </section>

      <section id='tabela' className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showDelete={false}
          disableEdit={!selected}
          buttonEditProps={{ disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
          onClickEdit={handleClickEdit}
        />
        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<Alergenicos>
            columns={[
              {
                field: 'dsAlergenico',
                headerName: 'Descrição',
                col: 10
              },
              {
                field: 'stAlergenico',
                headerName: 'Status',
                col: 2,
                valueGetter: (node) => node.data.stAlergenico === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo'
              }
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            rowsPerPageEnabled={false}
            onOrderChange={handleChangeOrder}
            onChangePage={handleChangePage}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/alergenos/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  )
}

export default Alergenos;