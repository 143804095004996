import { useRef, useState } from "react";
import { Dispatch } from "redux";
import { useTheme } from "@mui/material";
import { MainStateType } from "root-states";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { useStyles } from "root-views/app.styles";
import { CardFilters, Swal } from "root-components";
import { buildQuery } from "root-utils/build-query";
import { TableOrder } from "root-models/table-order";
import { useDispatch, useSelector } from "react-redux";
import { DispatchAction } from "root-states/root-dispatcher";
import ReferenciaNutricional from "../model/referencia-nutricional";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import ScreensKeysEnum from "root-components/cardfilters/screens-keys";
import ReferenciaNutricionalAPI from "../resources/referencianutricional";
import Busca from "@bubotech/sumora-react-components/lib/resources/model/busca";
import Resposta from "@bubotech/sumora-react-components/lib/resources/model/resposta";
import { useComponentDidMount } from "@bubotech/sumora-react-components/lib/utils/hooks";
import { ButtonFAB, DataTable, GroupButtonGrid } from "@bubotech/sumora-react-components";
import TipoFiltroEnum from "@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum";

/**
 * Tela de listagem de Referência Nutricional
 *
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 */
function ReferenciasNutricionais(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles();
    const history = useNavigate();

    const api = new ReferenciaNutricionalAPI();
    const [pageData, setPageData] = useState<Resposta>();
    const [dataFiltered, setDataFiltered] = useState<ReferenciaNutricional[]>([]);
    const [selected, setSelected] = useState<ReferenciaNutricional | undefined>();
    const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
    const params = useRef<Busca>({ page: 1, orderField: 'nmReferenciaNutricional', orderType: 'ASC' });

    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Referência Nutricional');
        if (ScreensKeysEnum.REFERENCIA_NUTRICIONAL in filtros) {
            const staticFields = buildQuery(filtros[ScreensKeysEnum.REFERENCIA_NUTRICIONAL].filters, [], []);
            params.current = { ...params.current, staticParams: staticFields, page: 1 };
        }
        loadData();
    });

    function handleChangePage(page: number) {
        params.current = { ...params.current, page: page + 1 };
        loadData();
    }

    function handleChangeOrder(order: TableOrder[]) {
        if (order.length)
            params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
        else
            params.current = { ...params.current, orderField: 'nmReferenciaNutricional', orderType: 'ASC' };

        loadData();
    }

    function handleChangeFilters(data: any[]) {
        const staticFields = buildQuery(data, [], []);
        params.current = { ...params.current, staticParams: staticFields, page: 1 };
        loadData();
    }

    function loadData() {
        appLayoutActions.setLoading(true);
        api.findByPage(params.current)
            .then(res => {
                setPageData(res.data);
                setDataFiltered(res.data.data as ReferenciaNutricional[]);
            })
            .catch(() => {
                setDataFiltered([]);
                Swal({
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Ok',
                    title: 'Ocorreu um erro',
                    text: 'Falha ao carregar dados',
                    icon: 'error',
                });
            })
            .finally(() => appLayoutActions.setLoading(false));
    }

    return (
        <main className={classes.rootListagem}>
            <section id='filtros'>
                <CardFilters
                    screenKey={ScreensKeysEnum.REFERENCIA_NUTRICIONAL}
                    data={pageData?.data ?? []}
                    onLoadData={loadData}
                    changeFilters={handleChangeFilters}
                    onRefreshFilters={setDataFiltered}
                    filtersDefinitions={[
                        {
                            field: 'nmReferenciaNutricional',
                            label: 'Nome',
                            type: TipoFiltroEnum.STRING
                        },
                        {
                            field: 'dsReferenciaNutricional',
                            label: 'Descrição Complementar',
                            type: TipoFiltroEnum.STRING
                        }
                    ]}
                />
            </section>

            <section className={classes.sectionTable}>
                <GroupButtonGrid
                    showAdd={false}
                    showEdit
                    showDelete={false}
                    disableEdit={!selected}
                    buttonEditProps={{ disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
                    onClickEdit={() => {
                        if (!selected) return;
                        history(`/cadastros/referencia-nutricional/editar/${selected.idReferenciaNutricional}`);
                    }}
                />

                <div className={`${classes.containerDataTable} tabela`}>
                    <DataTable<ReferenciaNutricional>
                        columns={[
                            {
                                field: 'nmReferenciaNutricional',
                                headerName: 'Nome',
                                col: 4
                            },
                            {
                                field: 'dsReferenciaNutricional',
                                headerName: 'Descrição Complementar',
                                col: 8
                            }
                        ]}
                        data={dataFiltered}
                        onSelectRow={setSelected}
                        onChangePage={handleChangePage}
                        onOrderChange={handleChangeOrder}
                        rowsPerPageEnabled={false}
                        paginationConfigs={{
                            labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
                            count: pageData?.totalRegistros ?? 0
                        }}
                    />
                </div>

                <ButtonFAB onClick={() => history('/cadastros/referencia-nutricional/novo')}>
                    <AddIcon />
                </ButtonFAB>
            </section>
        </main>
    );
}

export default ReferenciasNutricionais;