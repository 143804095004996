import GenericApi from "root-resources/generic-api";
import CaracteristicaQualidade from "../models/caracteristicaqualidade";

/**
 * API de característica
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @extends {PcpAPI}
 */
class CaracteristicaAPI extends GenericApi<CaracteristicaQualidade> {
    /**
    * Injeta a instância do axios com o endpoint base referente a característica
    */
    public constructor() {
        super({baseEndpoint:'/caracteristica'});
    }
}

export default CaracteristicaAPI;