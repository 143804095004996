import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import { useParams, Params } from 'react-router-dom';
import TipoCaracteristicaAPI from '../resources/api';
import { useDispatch, useSelector } from 'react-redux';
import TipoCaracteristica from '../model/tipocaracteristica';
import { DispatchAction } from 'root-states/root-dispatcher';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { ButtonFABMenu, TextField, Switch } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

interface EditarTipoCaracteristicaPropType {}

/**
 * View de edição de um Tipo de Caracteristica
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @param {EditarTipoCaracteristicaPropType} props
 */
function EditarTipoCaracteristica(props: EditarTipoCaracteristicaPropType): JSX.Element {
  const classes = useStyles();
  const history = useNavigate();
  const { id }: Readonly<Params<string>> = useParams();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

  const api = new TipoCaracteristicaAPI()
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<TipoCaracteristica>({ nmTipoCaracteristica: '', stTipoCaracteristica: 1 });

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik<TipoCaracteristica>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize,
    initialValues,
    validationSchema: Yup.object().shape({
      stTipoCaracteristica: Yup.number().default(1).required('Campo obrigatório'),
      nmTipoCaracteristica: Yup.string().required('Campo obrigatório')
    }),
    onSubmit: handleSubmitFormik,
  })

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Tipo de Característica');
    if (id) setEnableReinitialize(true);
  })

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    loadData();
    // eslint-disable-next-line
  }, [enableReinitialize])

  async function loadData() {
    appLayoutActions.setLoading(true);
    await api.findById(id).then(res => {
      setInitialValues(res.data);
      setEnableReinitialize(false);
    }).catch(() => {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: 'Tipo de característica não encontrada',
        icon: 'error',
      });

      history('/cadastros/tipo-caracteristica');
    }).finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <main style={{ height: '100%' }}>
      <section id='titulo-pagina'>
        <div className={classes.titleContainer}>Tipo de Característica</div>
      </section>

      <section id='formulario'>
        <Grid container className={classes.content}>
          <Grid item xs={6} className={classes.gridCell}>
            <TextField
              label='Nome'
              name='nmTipoCaracteristica'
              disabled={isLoading}
              inputProps={{ maxLength: 100 }}
              value={values.nmTipoCaracteristica}
              error={Boolean(touched.nmTipoCaracteristica && errors.nmTipoCaracteristica)}
              helperText={Boolean(touched.nmTipoCaracteristica && errors.nmTipoCaracteristica) ? `${errors.nmTipoCaracteristica}` : ''}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          </Grid>


          <Grid item className={classes.switchContainer}>
            <Switch
              label={'Ativo'}
              checked={Boolean(values.stTipoCaracteristica)}
              onBlur={handleBlur}
              disabled={isLoading}
              onChange={() => setFieldValue('stTipoCaracteristica', values.stTipoCaracteristica ? 0 : 1)}
            />
          </Grid>
        </Grid>

        <ButtonFABMenu
          disabled={isLoading}
          primaryAction={{ onClick: (e: any) => handleSubmit(e), iconProps: { color: 'inherit' } }}
          secondaryAction={{ onClick: () => history('/cadastros/tipo-caracteristica'), iconProps: { color: 'inherit' } }}
        />
      </section>
    </main>
  )

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {TipoCaracteristica} values - Valores do submit
   */
  async function handleSubmitFormik(values: TipoCaracteristica): Promise<void> {
    LoadingSwal({ text: 'Carregando' });
    let error = false;

    if (!id) {
      await api.save(values).catch(e => { error = true });

    } else {
      await api.update(values).catch(e => { error = true });
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastrado com sucesso',
        icon: 'success',
      });

      history('/cadastros/tipo-caracteristica');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error',
      })
    }
  }
}

export default EditarTipoCaracteristica;
