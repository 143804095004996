import RootReducer from "../root-reducer";
import { DispatchAction } from "../root-dispatcher";
import ContextActionTypes from "../actiontypes/context-action-types";

/**
 * Modelo do estado do reducer de Contexto
 */
export interface ContextStateType {
    allowTabChange: boolean;
    allowRouteChange: boolean;
    selectedTab: number;
    selectedRoute: string;
    tabRequested?: number;
    routeRequested: string;
    principalTabHasUnsavedData: boolean;
    principalFormSubmit: Function;
}

/**
 * Reducer do contexto do APP
 * 
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @class ContextReducer
 * @extends {RootReducer<ContextStateType>}
 */
class ContextReducer extends RootReducer<ContextStateType> {
    /**
     * Instancia o reducer e injeta o estado inicial
     */
    public constructor() {
        super({
            allowTabChange: true,
            allowRouteChange: true,
            selectedTab: 0,
            selectedRoute: "",
            tabRequested: undefined,
            routeRequested: "",
            principalTabHasUnsavedData: false,
            principalFormSubmit: () => { }
        });
    }

    /**
     * Retorna a função reducer do Redux
     * 
     * @returns {(state: ContextStateType, action: DispatchAction) => ContextStateType}
     */
    public getReducer(): (state: ContextStateType, action: DispatchAction) => ContextStateType {
        return (state: ContextStateType = this.initialState, action: DispatchAction) => {
            switch (action.type) {
                case ContextActionTypes.ALLOW_TAB_CHANGE:
                    return {
                        ...state,
                        allowTabChange: action.payload ?? true
                    };
                case ContextActionTypes.ALLOW_ROUTE_CHANGE:
                    return {
                        ...state,
                        allowRouteChange: action.payload ?? true
                    };
                case ContextActionTypes.TRY_CHANGE_TAB:
                    return {
                        ...state,
                        tabRequested: action.payload,
                        selectedTab: state.allowTabChange ? action.payload : state.selectedTab
                    };
                case ContextActionTypes.TRY_CHANGE_ROUTE:
                    return {
                        ...state,
                        routeRequested: action.payload,
                        selectedRoute: state.allowRouteChange ? action.payload : state.selectedRoute
                    };
                case ContextActionTypes.SET_CURRENT_TAB:
                    return {
                        ...state,
                        selectedTab: action.payload
                    };
                case ContextActionTypes.SET_CURRENT_ROUTE:
                    return {
                        ...state,
                        selectedRoute: action.payload
                    };
                case ContextActionTypes.PRINCIPAL_TAB_HAS_UNSAVED_DATA:
                    return {
                        ...state,
                        principalTabHasUnsavedData: action.payload
                    };
                case ContextActionTypes.SET_PRINCIPAL_FORM_SUBMIT:
                    return {
                        ...state,
                        principalFormSubmit: action.payload
                    };
                default:
                    return state;
            }
        };

    }
}

export default new ContextReducer().getReducer();