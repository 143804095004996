
import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import { MainStateType } from "root-states";
import FichaTecnica from "../models/fichatecnica";
import { useStyles } from "root-views/app.styles";
import { useVerificaDados } from "root-components";
import { Button, Switch } from "@bubotech/sumora-react-components";
import { ContextStateType } from "root-states/reducers/context-reducer";
import { Count, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { Grid } from "@mui/material";

export interface EtiquetaPropType {
    form: FormikProps<FichaTecnica>;
}

/**
 * Aba de etiqueta de uma ficha técnica
 * 
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 * @param {EtiquetaPropType} props
 * @returns {JSX.Element}
 */
function Etiqueta(props: EtiquetaPropType): JSX.Element {
    const { form } = props;
    const classes = useStyles();


    function gerarEtiqueta() {
        const ingredientes: string[] = [];

        if (form.values.produto?.dsNomeEtiqueta) {
            ingredientes.push(form.values.produto?.dsNomeEtiqueta);
        }

        form.values.produtoEtapaList.forEach((etapa) => {
            etapa.produtoComposicoes.forEach((composicao) => {
                if (composicao.produto?.dsNomeEtiqueta && !ingredientes.includes(composicao.produto?.dsNomeEtiqueta)) {
                    ingredientes.push(composicao.produto?.dsNomeEtiqueta);
                }
            });
        });

        form.setFieldValue('dsEtiqueta', ingredientes.join(', '));
    }

    const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);
    useVerificaDados({
        funcaoPrincipalProps: { dirty: contextProps.principalTabHasUnsavedData },
        handleSubmit: contextProps.principalFormSubmit,
        ignoreTabChange: true
    });

    return (
        <section id='etiqueta-ficha-tecnica' className={classes.tabContent}>
            <RichTextEditorComponent
                key={Math.random()}
                id='etiqueta'
                height={200}
                toolbarSettings={{
                    items: ['Bold']
                }}
                value={form.values.dsEtiqueta}
                change={(e: any) => form.setFieldValue('dsEtiqueta', e.value)}
                showCharCount={true}
            >
                <Inject services={[Link, Image, HtmlEditor, Toolbar, QuickToolbar, Count]} />
            </RichTextEditorComponent>
            <section className={classes.buttonContainer} style={{ justifyContent: 'flex-start', padding: '10px 0px' }}>
                <Button color='primary' onClick={gerarEtiqueta}>GERAR ETIQUETA</Button>
            </section>
            <section style={{display: 'flex'}}>
                <Grid item xs={1.3} className={classes.switchContainer}>
                    <Switch
                        label='Contém Glúten?'
                        checked={Boolean(form.values.snGluten)}
                        onBlur={form.handleBlur}
                        onChange={() => {
                            if (form.values.snGluten) form.setFieldValue('snGluten', 0)
                            else form.setFieldValue('snGluten', 1)
                        }}
                    />
                </Grid>
                <Grid item xs={1.3} className={classes.switchContainer}>
                    <Switch
                        label='Contém Lactose?'
                        checked={Boolean(form.values.snLactose)}
                        onBlur={form.handleBlur}
                        onChange={() => {
                            if (form.values.snLactose) form.setFieldValue('snLactose', 0)
                            else form.setFieldValue('snLactose', 1)
                        }}
                    />
                </Grid>
                <Grid item xs={1.3} className={classes.switchContainer}>
                    <Switch
                        label='Zero Açucar?'
                        checked={Boolean(form.values.snAcucar)}
                        onBlur={form.handleBlur}
                        onChange={() => {
                            if (form.values.snAcucar) form.setFieldValue('snAcucar', 0)
                            else form.setFieldValue('snAcucar', 1)
                        }}
                    />
                </Grid>
            </section>
        </section >
    );
}

export default Etiqueta;