import GenericApi from 'root-resources/generic-api';
import EtapaModel from '../model/etapa';

/**
 * API de Etapa
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @extends {RootApi}
 */
class EtapaAPI extends GenericApi<EtapaModel> {
  /**
   * Injeta a instância do axios com o endpoint base referente as Etapas
   */
  public constructor() {
    super({ baseEndpoint: 'etapa' });
  }
}

export default EtapaAPI;
