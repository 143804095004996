import moment from 'moment'

/**
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param filters Lista de filtros da tela
 * @param enums Lista dos nomes dos filtros que são enumerations
 * @param dates Lista de filtros do tipo Data
 * @param screenStaticFilter Filtros das regras de funcionamento da tela
 * @returns 
 */
const buildQuery = (filters: any[], enums: string[], dates: string[], screenStaticFilter?: string) => {
  let staticFields = '';
  const stringFilters = filters.filter(filter => !enums.includes(filter.field) && !dates.includes(filter.field));
  filters.forEach(filter => {
    let operation = '';
    switch (filter.operation) {
      case '===':
        operation = ':';
        break;
      case '!==':
        operation = '<>';
        break;
      case '%%':
        operation = ':';
        break;
      default:
        operation = filter.operation
    }
    if (enums.includes(filter.field)) staticFields += `,${filter.field}${operation}${filter.value.value}`;
    if (dates.includes(filter.field)) staticFields += `,${filter.field}${operation}${moment(new Date(filter.value)).format('YYYY-MM-DD')}`;
    if (Boolean(stringFilters.find(stringFilter => stringFilter.field === filter.field))) staticFields += `,${filter.field}${operation}${filter.value}`;
  });
  
  if (screenStaticFilter) staticFields += `,${screenStaticFilter}`;
  return staticFields;
}

export { buildQuery };