/**
 * Enum de keys das telas do sistema para diferenciar o componente CardFilters de cada uma
 *
 * @author davi takayama
 */
enum ScreensKeysEnum {
  USUARIOS = 'USUARIOS',
  UNIDADE_MEDIDA = 'UNIDADE_MEDIDA',
  ETAPA = 'ETAPA',
  MARCA = 'MARCA',
  COR_PRODUTO = 'COR_PRODUTO',
  PRODUTO = 'PRODUTO',
  FICHA_TECNICA = 'FICHA_TECNICA',
  CATEGORIA_NUTRICIONAL = 'CATEGORIA_NUTRICIONAL',
  REFERENCIA_NUTRICIONAL = 'REFERENCIA_NUTRICIONAL',
  UPLOAD_PRODUTO = 'UPLOAD_PRODUTO',
  UPLOAD_FICHATECNICA = 'UPLOAD_FICHATECNICA',
  ARO = 'ARO',
  ALERGENOS = 'ALERGENOS',
  TIPO_CARACTERISTICA = 'TIPO_CARACTERISTICA',
  CARACTERISTICA_QUALIDADE = 'CARACTERISTICA_QUALIDADE'
}

export default ScreensKeysEnum;
