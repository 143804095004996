import GenericApi from 'root-resources/generic-api';
import CategoriaNutricional from '../model/categorianuticional';

/**
 * API de Categoria Nutricional
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @extends {GenericApi}
 */
class CategoriaNutricionalAPI extends GenericApi<CategoriaNutricional> {
  /**
   * Injeta a instância do axios com o endpoint base referente as categorias nutricionais
   */
  public constructor() {
    super({ baseEndpoint: 'categoria-nutricional' });
  }

  /**
   * Atualiza a ordem das categorias pelo drag and drop da listagem
   * 
   * @param categoriaNuticional categoria que será atualizada
   */
  public async updateNrOrdem(categoriaNuticional: CategoriaNutricional) {
    return this.api.put('/update-nrordem', categoriaNuticional);
  }

  /**
   * Busca o próximo valor para ordem
   * @returns ordem
   */
  public async getNextNrOrdem() {
    return this.api.get('/proxima-nrordem');
  }

  /**
   * Atualiza a lista de categorias através de um cadastro de uma ordem repetida
   * @param categoriaNuticional categoria que será adicionada
   */
  public async updateList(categoriaNuticional: CategoriaNutricional) {
    return this.api.post('/repeat-nrordem', categoriaNuticional);
  }

  /**
   * Atualiza a lista quando o usuário editar um item e substituir a ordem para um valor que já existe
   * @param categoriaNuticional categoria editada
   */
  public async editList(categoriaNuticional: CategoriaNutricional) {
    return this.api.put('/repeat-nrordem', categoriaNuticional);
  }

  /**
   * Retorna todas as categorias cadastradas no sistema
   */
  public async getAllCategorias() {
    return this.api.get('/categoria-no-pagination');
  }
}

export default CategoriaNutricionalAPI;
