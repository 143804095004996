import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { useStyles } from 'root-views/app.styles';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { FormFieldTypeEnum } from 'root-components/form/form';
import { useVerificaDados, FormWithTable } from 'root-components';
import SituacaoRegistroEnum from 'root-enumerations/situacao-registro';

// Models
import FichaTecnicaAlergenos from '../models/fichatecnicaalergenico';
import FichaTecnica from '../models/fichatecnica';
import Busca from 'root-models/parametrosbusca';

// APIs
import AlergenicosAPI from 'root-cadastros/alergenos/resource/api';

interface AlergenicoFichaTecnicaPropType {
    valuesFt: FichaTecnica;
    setFieldValuesForm: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<FichaTecnica>>;
}

/**
 * View de edição de uma composição
 * 
 * @author Carlos Bageston <carlos.bageston@kepha.com.br>
 * @param {AlergenicoFichaTecnicaPropType} props
 */
function AlergenicoFichaTecnica(props: AlergenicoFichaTecnicaPropType): JSX.Element {
    const classes = useStyles();
    const { setFieldValuesForm, valuesFt } = props;
    const [selected, setSelected] = useState<FichaTecnicaAlergenos>();
    const [suggestions, setSuggestions] = useState<FichaTecnicaAlergenos[]>([]);

    const apiAlergenos = new AlergenicosAPI();
    const params = useRef<Busca>({ page: 1, orderField: 'dsAlergenico', orderType: 'ASC' });

    const form = useFormik<FichaTecnicaAlergenos>({
        initialValues: { alergenico: null, stRegistro: SituacaoRegistroEnum.CREATE, idFichaTecnicaAlergenico: '' } as FichaTecnicaAlergenos,
        validationSchema: Yup.object({
            alergenico: Yup.object().required('Campo obrigatório').nullable(),
        }),
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit: handleSubmitForm
    });

    const { dirty, handleSubmit, values } = form;

    useVerificaDados({ funcaoPrincipalProps: { dirty }, handleSubmit });

    function handleSubmitForm() {
        values.idFichaTecnicaAlergenico = null;
        const newValues = valuesFt.fichaTecnicaAlergenicoList.map(item => ({
            ...item,
            idFichaTecnicaAlergenico: null,
            stRegistro: SituacaoRegistroEnum.CREATE
        }));
        setFieldValuesForm('fichaTecnicaAlergenicoList', [...newValues, values]);

        form.resetForm();
    }

    // Atualiza as sugestões de alérgenos ativos que ainda não foram adicionados à lista
    useEffect(() => {
        apiAlergenos.findByPage(params.current).then(res => {
            const itemsFromAPI = res.data.data;
            const novosItens = itemsFromAPI.filter(itemFromAPI => (
                itemFromAPI.stAlergenico === SituacaoEnum.ATIVO &&
                !valuesFt.fichaTecnicaAlergenicoList.some(selectedItem =>
                    selectedItem.alergenico?.dsAlergenico === itemFromAPI.dsAlergenico
                )
            ));
            setSuggestions(novosItens);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valuesFt.fichaTecnicaAlergenicoList]);

    function handleDelete() {
        if (selected) {
            const removeList = valuesFt.fichaTecnicaAlergenicoList.map(item => {
                if (JSON.stringify(item) === JSON.stringify(selected)) return { ...item, stRegistro: SituacaoRegistroEnum.DELETE };
                else return item;
            });
            const filteredList = removeList.filter(item => item.stRegistro !== SituacaoRegistroEnum.DELETE);

            setFieldValuesForm('fichaTecnicaAlergenicoList', filteredList);
        }
    }

    return (
        <section id='editar-composição' className={classes.formWithTableContainer} style={{ overflowY: 'auto' }}>
            <FormWithTable<FichaTecnicaAlergenos>
                list={valuesFt.fichaTecnicaAlergenicoList}
                handleMainForm={form.setFieldValue}
                listField='fichaTecnicaAlergenicoList'
                onClickSave={handleSubmitForm}
                listItemIdField='idFichaTecnicaAlergenico'
                tableProps={{ onSelectRow: setSelected, onClickDelete: () => handleDelete(), showEdit: false }}
                listForm={{
                    form: form,
                    fields: [
                        {
                            fieldName: 'alergenico',
                            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
                            label: 'Alérgenos',
                            fieldSize: 4,
                            showInTable: 4,
                            customTableField: 'alergenico.dsAlergenico',
                            autoCompleteProps: {
                                suggestions: suggestions,
                                searchField: 'dsAlergenico',
                                orderField: 'dsAlergenico',
                                staticSearchParams: ',stAlergenico:1',
                                getLabel: opt => opt.dsAlergenico,
                                getValue: opt => opt.idAlergenico
                            }
                        },
                    ]
                }}
            />
        </section>
    );
}

export default AlergenicoFichaTecnica;