import RootApi from 'root-resources/root-api';
import Authentication from './authentication';
import { deepClone } from '@bubotech/sumora-react-components/lib';
import Usuario from 'root-models/configuracoesdeacesso/usuario';

/**
 * Classe para buscar informaçoes referentes ao usuário logado
 *
 * @author davi takayama
 * @author davi takayama
 */
export default class Admin extends RootApi {
  public constructor() {
    super();
  }

  /**
   * Memória dos dados do usuário logado
   */
  private static usuarioLogado: Usuario | null = null;

  /*
   * Valida, busca e atualiza os dados do usuário
   */
  public async findPerfil() {
    if (Admin.usuarioLogado !== null) {
      return Promise.resolve(Admin.usuarioLogado);
    }

    const token = Authentication.getToken();

    if (token) {
      return this.api
        .get<Usuario>('/perfil', {
          headers: { Authorization: `Bearer ${token.access_token}` },
        })
        .then((response) => {
          this.setDadosUsuario(response.data);
          return response.data;
        })
        .catch((err) => {
          this.clearDadosUsuario();
          return Promise.reject(err);
        });
    }

    return Promise.reject();
  }

  /**
   * Edita as informaçoes do perfil
   */
  public async updateUsuario(values: Usuario) {
    return this.api.put<Usuario>('/perfil', values).then((res) => {
      this.setDadosUsuario(res.data);
      return res;
    });
  }

  /**
   * @returns Dados do usuário logado
   */
  public static getUsuarioLogado(): Usuario | null {
    return deepClone(Admin.usuarioLogado);
  }

  /*
   * Limpa os dados salvos do usuário
   */
  private clearDadosUsuario() {
    Admin.usuarioLogado = null;
  }

  /**
   * Setta os dados do usuário logado
   *
   * @param {(Usuario | null)} _dadosUsuario
   */
  private setDadosUsuario(_dadosUsuario: Usuario | null) {
    Admin.usuarioLogado = deepClone(_dadosUsuario);
  }
}
