import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { Dispatch } from "redux";
import { useFormik } from "formik";
import { MainStateType } from "root-states";
import { useStyles } from "root-views/app.styles";
import { useDispatch, useSelector } from "react-redux";
import { DispatchAction } from "root-states/root-dispatcher";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { Params, useNavigate, useParams } from "react-router-dom";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import { LoadingSwal, Swal, useVerificaDados } from "root-components";
import ReferenciaNutricionalAPI from "../resources/referencianutricional";
import { ButtonFABMenu, TextField } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from "@bubotech/sumora-react-components/lib/utils/hooks";

/**
 * Tipo dos valores do formik
 */
export type EditarReferenciaNutricionalFormikValuesType = {
    idReferenciaNutricional?: string;
    nmReferenciaNutricional: string;
    dsReferenciaNutricional: string;
};

/**
 * View de edição de referência nutricional
 *
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 */
function EditarReferenciaNutricional(): JSX.Element {
    const classes = useStyles();
    const history = useNavigate();

    const { id }: Readonly<Params<string>> = useParams();
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);

    const api = new ReferenciaNutricionalAPI();

    const [enableReinitialize, setEnableReinitialize] = useState(false);
    const [initialValues, setInitialValues] = useState<EditarReferenciaNutricionalFormikValuesType>({
        nmReferenciaNutricional: '',
        dsReferenciaNutricional: ''
    });

    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, dirty } =
        useFormik<EditarReferenciaNutricionalFormikValuesType>({
            validateOnBlur: true,
            validateOnChange: true,
            enableReinitialize,
            initialValues,
            validationSchema: Yup.object().shape({
                nmReferenciaNutricional: Yup.string().required('Campo obrigatório'),
                dsReferenciaNutricional: Yup.string().required('Campo obrigatório'),
            }),
            onSubmit: handleSubmitFormik,
        });

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar(id ? 'Edição de Referencia Nutricional' : 'Cadastro de Referencia Nutricional');

        if (id) {
            setEnableReinitialize(true);
        }
    });

    useEffect(() => {
        if (!enableReinitialize) return;
        appLayoutActions.setLoading(true);

        api.findById(id).then(res => {
            setInitialValues(res.data);
            setEnableReinitialize(false);
            appLayoutActions.setLoading(false);
        });

        // eslint-disable-next-line
    }, [enableReinitialize]);

    useVerificaDados({
        funcaoPrincipalProps: { dirty },
        handleSubmit,
        ignoreTabChange: true
    });

    return (
        <main style={{ height: '100%' }}>
            <section id='titulo-pagina'>
                <div className={classes.titleContainer}>Dados</div>
            </section>

            <section id='formulario'>
                <Grid container className={classes.content}>
                    <Grid item xs={6} className={classes.gridCell}>
                        <TextField
                            label='Nome'
                            name='nmReferenciaNutricional'
                            inputProps={{ maxLength: 10 }}
                            value={values.nmReferenciaNutricional}
                            error={touched.nmReferenciaNutricional && errors.nmReferenciaNutricional !== undefined}
                            helperText={touched.nmReferenciaNutricional && errors.nmReferenciaNutricional !== undefined ? `${errors.nmReferenciaNutricional}` : ''}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ height: 120 }}>
                        <TextField
                            label={'Descrição Complementar'}
                            name='dsReferenciaNutricional'
                            inputProps={{ maxLength: 100 }}
                            value={values.dsReferenciaNutricional}
                            error={touched.dsReferenciaNutricional && errors.dsReferenciaNutricional !== undefined}
                            helperText={touched.dsReferenciaNutricional && errors.dsReferenciaNutricional !== undefined ? `${errors.dsReferenciaNutricional}` : ''}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                            multiline
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Typography variant='subtitle1'>{values.dsReferenciaNutricional?.length ?? '0'}/100</Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </section>
            <ButtonFABMenu
                disabled={isLoading}
                primaryAction={{
                    onClick: (e: any) => handleSubmit(e),
                    iconProps: { color: 'inherit' },
                }}
                secondaryAction={{
                    onClick: () => history('/cadastros/referencia-nutricional'),
                    iconProps: { color: 'inherit' },
                }}
            />
        </main>
    );

    /**
     * Manipula o evento de submit do Formik
     *
     * @param {EditarReferenciaNutricionalFormikValuesType} values - Valores do submit
     */
    async function handleSubmitFormik(values: EditarReferenciaNutricionalFormikValuesType): Promise<void> {
        LoadingSwal({ text: 'Carregando' });
        let error = false;

        if (!id) {
            await api.save(values).catch(() => error = true);
        } else {
            await api.update(values).catch(() => error = true);
        }

        if (!error) {
            Swal({
                showConfirmButton: true,
                title: 'Sucesso',
                text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
                icon: 'success',
            });

            history('/cadastros/referencia-nutricional');
        } else {
            Swal({
                showConfirmButton: true,
                title: 'Erro',
                text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
                icon: 'error',
            });
        }
    }
}

export default EditarReferenciaNutricional;