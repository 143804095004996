import RootDispatcher from 'root-states/root-dispatcher'
import { Dispatch } from 'redux'
import { DispatchAction } from 'root-states/root-dispatcher'
import AppLayoutActionTypes from 'root-states/actiontypes/app-layout-action-types'
import Empresa from 'root-models/empresa'
import Usuario from 'root-models/configuracoesdeacesso/usuario'

/**
 * Actions para o Reducer do Layout do App
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @class AppLayoutActions
 * @extends {RootDispatcher}
 */
class AppLayoutActions extends RootDispatcher {
  /**
   * Construtor padrão que injeta o dispatch
   *
   * @param {Dispatch<DispatchAction>} dispatch
   */
  public constructor(dispatch: Dispatch<DispatchAction>) {
    super(dispatch)
  }

  /**
   * Setta se o menu lateral está aberto
   */
  public setMenuIsOpen(isOpen?: boolean) {
    this.dispatch({ type: AppLayoutActionTypes.MENU_IS_OPEN, payload: isOpen })
  }

  /**
   * Setta se o loading principal está visível
   */
  public setLoading(isLoading?: boolean) {
    this.dispatch({ type: AppLayoutActionTypes.IS_LOADING, payload: isLoading })
  }

  /**
   * Setta se o loading principal está visível
   */
  public setCalculating(isCalculating?: boolean) {
    this.dispatch({ type: AppLayoutActionTypes.IS_CALCULATING, payload: isCalculating })
  }

  /**
   * Altera o título do cabeçalho principal
   *
   * @param {string} title
   */
  public setTitleToolbar(title: string) {
    this.dispatch({
      type: AppLayoutActionTypes.CHANGE_TITLE_TOOLBAR,
      payload: title,
    })
  }

  /**
   * Altera a empresa principal do sistema
   *
   * @param {string} Empresa
   */
  public setCompany(company: Empresa) {
    this.dispatch({
      type: AppLayoutActionTypes.CHANGE_COMPANY,
      payload: company,
    })
  }

  /**
   * Altera a empresa padrão do sistema
   *
   * @param {string} Empresa
   */
  public setStandardCompany(company: Empresa) {
    this.dispatch({
      type: AppLayoutActionTypes.CHANGE_STANDARD_COMPANY,
      payload: company,
    })
  }

  /**
   * Altera o usuário logado no sistema
   *
   * @param {string} usuario
   */
  public setUserLogin(usuario: Usuario) {
    this.dispatch({
      type: AppLayoutActionTypes.CHANGE_USUARIO,
      payload: usuario,
    })
  }
 /**
   * Setta se foi criado ou atualizado uma filial
   */
   public updateFiliais(filiais: boolean | undefined) {
    this.dispatch({
      type: AppLayoutActionTypes.UPDATE_FILIAIS,
      payload: filiais,
    });
  }
}

export default AppLayoutActions
