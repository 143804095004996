import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { MainStateType } from "root-states";
import { useStyles } from "root-views/app.styles";
import { useVerificaDados } from "root-components";
import ItemComposicao from "../models/itemcomposicao";
import { DataTable } from "@bubotech/sumora-react-components";
import EtapaFichaTecnica from "../models/etapafichatecnica";
import TipoItemEnum from "root-enumerations/tipo-item-enum";
import { ContextStateType } from "root-states/reducers/context-reducer";
import { useComponentDidMount, useComponentDidUnmount } from "@bubotech/sumora-react-components/lib/utils/hooks";

interface ResumoFichaTecnicaPropType {
    hideFab: (hide: boolean) => void;
    etapas: EtapaFichaTecnica[];
}

/**
 * View de resumo de uma ficha técnica
 * 
 * @author Daniel fonseca <daniel.silva@kepha.com.br>
 * @param {ResumoFichaTecnicaPropType} props
 */
export default function ResumoFichaTecnica(props: ResumoFichaTecnicaPropType): JSX.Element {
    const classes = useStyles();
    const { etapas, hideFab } = props;

    function formatNumberString(value: string | number) {
        return parseFloat(value.toString().replace(',', '.'));
    }

    /**
     * Retorna uma lista de itens de composição com os produtos do tipo informado
     * 
     * @param {TipoItemEnum} tipo Enum de tipo de item
     * @returns {ItemComposicao[]} Lista de itens de composição com os produtos do tipo informado
     */
    function getComposicaoByTipo(tipo: TipoItemEnum): ItemComposicao[] {
        return etapas
            .flatMap((etapa) => etapa.produtoComposicoes.map((item) => ({ ...item, nrQuantidade: formatNumberString(item.nrQuantidade) })))
            .filter((item) => item.produto?.tpItem === tipo)
            .reduce((acc, item) => {
                item.etapaUtilizada = etapas
                    .filter((etapa) => etapa.produtoComposicoes
                        .some((itemEtapa) => itemEtapa.produto?.cdProduto === item.produto?.cdProduto))
                    .map((etapa) => etapa.nrOrdem);
                const index = acc.findIndex((accItem) => accItem.produto?.cdProduto === item.produto?.cdProduto);
                if (index > -1) {
                    acc[index].nrQuantidade = formatNumberString(acc[index].nrQuantidade) + formatNumberString(item.nrQuantidade);
                } else {
                    acc.push(item);
                }
                return acc;
            }, [] as ItemComposicao[]);
    }

    const materiaPrima = getComposicaoByTipo(TipoItemEnum.MATERIA_PRIMA);
    const embalagem = getComposicaoByTipo(TipoItemEnum.EMBALAGEM);

    useComponentDidMount(() => {
        hideFab(true);
    });

    useComponentDidUnmount(() => {
        hideFab(false);
    });

    const contextProps = useSelector<MainStateType, ContextStateType>((state) => state.contextReducer);
    useVerificaDados({
        funcaoPrincipalProps: { dirty: contextProps.principalTabHasUnsavedData },
        handleSubmit: contextProps.principalFormSubmit,
        ignoreTabChange: true
    });

    function getTableHeight(data: any[]) {
        return data.length < 1 ? '100px' : undefined;
    }

    return (
        <section id='resumo-ficha-tecnica' className={classes.tabContent} style={{ paddingTop: 0 }}>
            <Grid item xs={12} className={classes.container}>
                Matéria Prima
            </Grid>
            <div className={`${classes.containerDataTable} tabela sem-bordas no-overflow`} style={{ marginTop: 20, height: getTableHeight(materiaPrima) }}>
                <DataTable<ItemComposicao>
                    columns={[
                        {
                            field: 'produto.cdProduto',
                            headerName: 'Código',
                            col: 1
                        },
                        {
                            field: 'produto.nmProduto',
                            headerName: 'Produto',
                            col: 7
                        },
                        {
                            field: 'unidadeMedida.sgUnidadeMedida',
                            headerName: 'Un. Medida',
                            col: 1
                        },
                        {
                            field: 'nrQuantidade',
                            headerName: 'Quantidade',
                            col: 1,
                            valueGetter: (node) => Intl.NumberFormat('pt-br', { minimumFractionDigits: 8 }).format(Number(node.data.nrQuantidade))
                        },
                        {
                            field: 'etapaUtilizada',
                            headerName: 'Etapa Utilizada',
                            col: 2
                        }
                    ]}
                    data={materiaPrima}
                    rowsPerPageEnabled={false}
                    showPagination={false}

                />
            </div>
            <Grid item xs={12} className={classes.container}>
                Embalagem
            </Grid>
            <div className={`${classes.containerDataTable} tabela sem-bordas  no-overflow`} style={{ marginTop: 20, height: getTableHeight(embalagem) }}>
                <DataTable<ItemComposicao>
                    columns={[
                        {
                            field: 'produto.cdProduto',
                            headerName: 'Código',
                            col: 1
                        },
                        {
                            field: 'produto.nmProduto',
                            headerName: 'Produto',
                            col: 7
                        },
                        {
                            field: 'unidadeMedida.sgUnidadeMedida',
                            headerName: 'Un. Medida',
                            col: 1
                        },
                        {
                            field: 'nrQuantidade',
                            headerName: 'Quantidade',
                            col: 1,
                            valueGetter: (node) => Intl.NumberFormat('pt-br', { minimumFractionDigits: 3 }).format(Number(node.data.nrQuantidade))
                        },
                        {
                            field: 'etapaUtilizada',
                            headerName: 'Etapa Utilizada',
                            col: 2
                        }
                    ]}
                    data={embalagem}
                    rowsPerPageEnabled={false}
                    showPagination={false}
                />
            </div>
            <Grid item xs={12} className={classes.container}>
                Modo de Preparo
            </Grid>
            <div className={`${classes.containerDataTable} tabela sem-bordas no-overflow`} style={{ marginTop: 20, height: getTableHeight(etapas) }}>
                <DataTable<EtapaFichaTecnica>
                    columns={[
                        {
                            field: 'nrOrdem',
                            headerName: 'Número',
                            col: 1,
                        },
                        {
                            field: 'etapa.nmEtapa',
                            headerName: 'Etapa do Processo',
                            col: 2,
                        },
                        {
                            field: 'dsModoPreparo',
                            headerName: 'Modo de Preparo',
                            col: 9,
                            valueGetter: (params: any) => {
                                return params.data.dsModoPreparo ? params.data.dsModoPreparo.replace(/\n[\s\S]*/, '...') : '';
                            }
                        }
                    ]}
                    data={etapas}
                    rowsPerPageEnabled={false}
                    showPagination={false}
                />
            </div>
        </section>
    );
};