import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Add } from '@mui/icons-material';
import { useStyles } from './listingscreen.styles';
import { buildQuery } from 'root-utils/build-query';
import ScreensKeysEnum from '../cardfilters/screens-keys';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericAPI from '@bubotech/sumora-react-components/lib/resources/genericApi';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

// Components
import Swal from '../swal/swal';
import Table, { TablePropType } from '../table/table';
import { ButtonFAB } from '@bubotech/sumora-react-components';
import CardFilters, { CardFiltersPropType } from '../cardfilters/card-filters';

// Models
import Resposta from 'root-models/respostaAPI';
import { TableOrder } from 'root-models/table-order';
import { TablePaginationProps } from '@mui/material';
import { Column } from '@bubotech/sumora-react-components/lib/datatable';
import Busca from '@bubotech/sumora-react-components/lib/resources/model/busca';
import FilterDefinition from '@bubotech/sumora-react-components/lib/cardfilters/models/filter-definition';

// Redux
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';


interface ListingScreenFilter extends FilterDefinition {
  tableColumnSize: number;
  hideFilter?: boolean;
  otherColProps?: Omit<Column,'col'>;
  decimalScale?: number;
}

export interface ListingScreenState<T> {
  dataState?: Resposta<T>;
  filteredData: T[];
}

export interface ListingScreenPropType<T> {
  id: string;
  api: GenericAPI;
  title: string;
  formScreenUrl: string;
  screenKey: ScreensKeysEnum;
  filtersDefinitions: ListingScreenFilter[];
  customButton?: JSX.Element;
  orderField: string;
  orderType?: 'ASC' | 'DESC';
  screenStaticParams?: string;
  enumFilters: string[];
  dateFilters: string[];
  hideAddButton?: boolean;
  tableProps?: Partial<TablePropType<T>>;
  cardFiltersProps?: CardFiltersPropType;
  customAddClick?: () => void;
  customOrderChange?: (order: TableOrder[], currentParams: Busca) => Busca;
  customChangePage?: (currentPage: number, currentParams: Busca) => Busca;
  customHandleChangeFilters?: (filters: any[], currentParams: Busca) => Busca;
  customTablePaginationConfigs?: (pageState?: Resposta<T>) => TablePaginationProps;
  customLoad?: (currentParams: Busca, appLayoutControl: AppLayoutActions) => Promise<ListingScreenState<T>>;
}

/**
 * Componente para telas de listagem
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 */
function ListingScreen<T = any>(props: ListingScreenPropType<T>): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    api,
    screenKey,
    filtersDefinitions,
    title,
    orderField,
    orderType = 'ASC',
    screenStaticParams,
    enumFilters,
    dateFilters,
    tableProps,
    formScreenUrl,
    customButton,
    id,
    hideAddButton = false,
    cardFiltersProps,
    customLoad,
    customAddClick,
    customOrderChange,
    customChangePage,
    customHandleChangeFilters,
    customTablePaginationConfigs,
  } = props;
  
  const [screenState, setScreenState] = useState<ListingScreenState<T>>({
    dataState: undefined,
    filteredData: []
  });

  const params = useRef<Busca>({ page: 1, orderField, orderType, staticParams: screenStaticParams ? `,${screenStaticParams}` : undefined });
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const filtros = useSelector<MainStateType, any>(state => state.cardFiltersReducer.data);

  function itemValueFormater(filter: ListingScreenFilter, value: any): string {
    const formatterTypes = {
      [TipoFiltroEnum.DATE]: moment(value).format('DD/MM/YYYY'),
      [TipoFiltroEnum.NUMBER]: new Intl.NumberFormat('pt-br', { maximumFractionDigits: filter.decimalScale ?? 2, minimumFractionDigits: filter.decimalScale ?? 2 }).format(value),
      [TipoFiltroEnum.ENUM]: filter.enumOptions ? filter.enumOptions.find(option => option.value === value)?.label : '-',
      [TipoFiltroEnum.AUTOCOMPLETE]: value,
      [TipoFiltroEnum.STRING]: value,
    }

    return formatterTypes[filter.type];
  }

  /**
   * Extrai o valor de um item
   * @param fieldName nome do atributo
   * @param item objeto para extrar o valor
   * @returns 
   */
  function getItemValue(fieldName: string, item: T) {
    const fields = fieldName.split('.');

    let current: any = item;
    while (fields.length) {
      if (typeof current !== 'object' || !current) return undefined;
      else current = current[fields.shift() as keyof T || '' as keyof T];
    }

    return current;
  }

  const tableColumns: Column[] = useMemo(() => 
    filtersDefinitions.map(filter => ({
      col: filter.tableColumnSize,
      field: filter.field,
      headerName: filter.label,
      valueGetter: (node) => itemValueFormater(filter, getItemValue(filter.field, node.data)),
      ...filter.otherColProps
    }))
    //eslint-disable-next-line
  , [filtersDefinitions])

  const visibleFilters: FilterDefinition[] = useMemo(() => 
    filtersDefinitions.filter(filter => !Boolean(filter.hideFilter))
  , [filtersDefinitions])

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(title);

    if (screenKey in filtros) {
      const staticParams = buildQuery(filtros[screenKey].filters, enumFilters, dateFilters, screenStaticParams);
      params.current = {...params.current, staticParams};

    }

    onLoadData();
  })

  function defaultLoadData() {
    appLayoutActions.setLoading(true);

    api.findByPage(params.current)
      .then(res => {
        setScreenState({
          dataState: res.data as Resposta<T>,
          filteredData: res.data.data as T[]
        });
      })
      .catch(() => {
        Swal({
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  async function onLoadData() {
    if (customLoad) {
      const data = await customLoad(params.current, appLayoutActions)
      setScreenState(data);
    }
    else defaultLoadData();
  }

  function handleChangeFilters(filters: any[]) {
    if (!customHandleChangeFilters) {
      const staticParams = buildQuery(filters, enumFilters, dateFilters, screenStaticParams);
      params.current = {...params.current, staticParams};
    } else params.current = customHandleChangeFilters(filters, params.current);

    onLoadData();
  }

  function handleChangePage(page: number) {
    if (!customChangePage) params.current = {...params.current, page: page + 1};
    else params.current = customChangePage(page, params.current);

    onLoadData();
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (!customOrderChange) {
      if (order.length) params.current = {...params.current, orderType: order[0].sort?.toUpperCase()};
      else params.current = {...params.current, orderField, orderType};
    } else params.current = customOrderChange(order, params.current);
    

    onLoadData();
  }

  function getPaginationConfigs() {
    if (!customTablePaginationConfigs) 
      return {
        labelDisplayedRows: ({ from, to }: any) => `${from} - ${to} de ${screenState.dataState?.totalRegistros ?? 0} `,
        count: screenState.dataState?.totalRegistros ?? 0
      }
    else return customTablePaginationConfigs(screenState.dataState)
  }

  function handleClickEdit(selected: T) {
    if (tableProps?.onClickEdit) tableProps?.onClickEdit(selected);
    else navigate(`${location.pathname}/editar/${selected[id as keyof T]}`);
  }

  function handleClickAdd() {
    if (customAddClick) customAddClick();
    else navigate(location.pathname + formScreenUrl);
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters 
          {...cardFiltersProps}
          screenKey={screenKey}
          data={screenState.dataState?.data ?? []}
          onLoadData={onLoadData}
          onRefreshFilters={data => 
            setScreenState(prev => ({
              ...prev, 
              filteredData: data
            }))
          }
          changeFilters={handleChangeFilters}
          filtersDefinitions={visibleFilters}
        />
      </section>
      <section className={classes.sectionTable}>
        <Table<T>
          enableControls
          addShortcut={handleClickAdd}
          columns={tableColumns}
          rowsPerPageEnabled={false}
          fullScreen
          data={screenState.filteredData ?? []}
          onChangePage={handleChangePage}
          onOrderChange={handleChangeOrder}
          paginationConfigs={getPaginationConfigs()}
          showPagination={true}
          onClickEdit={handleClickEdit}
          {...tableProps}
        />
      </section>

      {!hideAddButton &&
        <>
          {customButton ?? 
            <ButtonFAB onClick={handleClickAdd}>
              <Add />
            </ButtonFAB>
          }
        </>       
      }
    </main>
  )
}

export default ListingScreen;