import { Dispatch } from 'redux';
import { useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import CaracteristicaAPI from '../resources/api';
import { useStyles } from 'root-views/app.styles';
import { buildQuery } from 'root-utils/build-query';
import { TableOrder } from 'root-models/table-order';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import CardFilters from 'root-components/cardfilters/card-filters';
import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab';
import DataTable from '@bubotech/sumora-react-components/lib/datatable';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import CaracteristicaQualidade from '../models/caracteristicaqualidade';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

export type CaracteristicaQualidadePropType = {}

/**
 * Tela de listagem de Caracteristica de qualidade
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @param {CaracteristicaQualidadePropType} props
 */
function CaracteristicasQualidade(props: CaracteristicaQualidadePropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();

  const params = useRef<Busca>({ page: 1, orderField: 'nmCaracteristica', orderType: 'ASC' });
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const [selected, setSelected] = useState<CaracteristicaQualidade | undefined>();
  const [dataFiltered, setDataFiltered] = useState<CaracteristicaQualidade[]>([]);
  const [pageData, setPageData] = useState<Resposta>();
  const api = new CaracteristicaAPI();

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Característica');
    if (ScreensKeysEnum.CARACTERISTICA_QUALIDADE in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.CARACTERISTICA_QUALIDADE].filters, ['stCaracteristica'], []);
      params.current = { ...params.current, staticParams: staticFields };
    }
    loadData();
  })

  /**
   * Carrega os dados
   *
   */
  async function loadData() {
    appLayoutActions.setLoading(true);
    // Carrega dados da tabela de Tipo de Característica
    api.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as CaracteristicaQualidade[]);
      })
      .catch(() => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar dados',
          icon: 'error',
        })
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  function handleFilterChange(data: any[]) {
    const staticFields = buildQuery(data, ['stCaracteristica'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }

  function handlePageChange(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData()
  }

  function handleOrderChange(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'nmCaracteristica', orderType: 'ASC' };

    loadData();
  }

  function handleClickEdit() {
    if (!selected) return;
    history(`/cadastros/caracteristica-qualidade/editar/${selected.idCaracteristica}`);
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.CARACTERISTICA_QUALIDADE}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={handleFilterChange}
          filtersDefinitions={[
            {
              field: 'tipoCaracteristica.nmTipoCaracteristica',
              label: 'Tipo de Característica',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'nmCaracteristica',
              label: 'Nome',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'dsReferenciaLmt',
              label: 'Referência/LMT',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'stCaracteristica',
              label: 'Status',
              selectableForSorting: false,
              type: TipoFiltroEnum.ENUM,
              enumOptions: [
                {
                  label: 'Ativo',
                  value: SituacaoEnum.ATIVO,
                },
                {
                  label: 'Inativo',
                  value: SituacaoEnum.INATIVO,
                },
              ],
            },
          ]}
        />
      </section>

      <section id='tabela' className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          disableEdit={!selected}
          disableDelete={!selected}
          buttonEditProps={{ disabledColor: theme.palette.grey[100], backgroundColor: theme.palette.primary.main }}
          onClickEdit={handleClickEdit}
        />

        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<CaracteristicaQualidade>
            columns={[
              {
                field: 'tipoCaracteristica.nmTipoCaracteristica',
                headerName: 'Tipo de Característica',
                col: 3,
              },
              {
                field: 'nmCaracteristica',
                headerName: 'Nome',
                col: 5,
              },
              {
                field: 'dsReferenciaLmt',
                headerName: 'Referência/LMT',
                col: 3,
              },
              {
                valueGetter: (node) =>
                  node.data.stCaracteristica === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo',
                col: 1,
                headerName: 'Status',
                field: 'stCaracteristica',
              },
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handlePageChange}
            onOrderChange={handleOrderChange}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/caracteristica-qualidade/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  )
}

export default CaracteristicasQualidade;
