import React, { useEffect } from 'react';
import mermaid from 'mermaid';
import { Modal, Box } from '@mui/material';
import { useStyles } from './diagramaetapas.styles';

mermaid.initialize({
  startOnLoad: true
})

interface DiagramaPropType {
  open: boolean;
  title: string;
  subTitle: string;
  data: string;
  onClose: () => void;
}

const Diagrama = (props: { chart: string }) => {
  const { chart } = props;

  useEffect(() => mermaid.contentLoaded(), []);

  return <div className='mermaid'>{chart}</div>
}

/**
 * Componente para mostrar diagrama de etapas de um Produto
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {DiagramaPropType} props 
 */
function DiagramaEtapas(props: DiagramaPropType): JSX.Element {
  const classes = useStyles();
  const { open, title, subTitle, data, onClose } = props;

  useEffect(() => mermaid.contentLoaded(), [open])

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box className={classes.content}>
          <div className={classes.header}>
            <span>
              <b>{title}</b>{subTitle ? ` - ${subTitle}` : ''}
            </span>
          </div>

          <div style={{ overflow: 'auto', height: '100%' }}>
            <div className={classes.diagram}>
              <Diagrama 
                chart={`
                  %%{init: {'theme': 'base', 'themeVariables': { 'primaryColor': '#EFF3F8', 'primaryBorderColor': '#C3D2E3' }}}%%
                  graph TD;
                  ${data}
                `}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default DiagramaEtapas;