/**
 * Enum de SimNao para tela Cadastro Operacao
 *
 * @author Felipe Shimada
 */
enum SimNao {
    
    NAO,

    SIM

  }
  
  export default SimNao;