import { makeStyles } from '@mui/styles';

/**
 * Estilos do componente de tabela
 */
const useStyles = makeStyles((theme: any) => ({
  containerDataTable: {
    display: 'flex',
    flex: 1,
  },
}));

export { useStyles };
