import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FormikProps } from 'formik';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { Search } from '@mui/icons-material';
import DetalhesVersao from './detalhes-versao';
import { useStyles } from 'root-views/app.styles';
import FichaTecnica from '../models/fichatecnica';
import { useVerificaDados } from 'root-components';
import { ContextStateType } from 'root-states/reducers/context-reducer';
import { useKeyboardControls } from 'root-utils/hooks/use-keyboard-controls';
import { ButtonGrid, DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components';

interface HistoricoFichaTecnicaPropType {
  versoes: FichaTecnica[];
  form: FormikProps<FichaTecnica>;
  id?: string;
  hideFab: (hide: boolean) => void;
  setDiagrama: (values: string) => void;
  setShowDiagrama: (value: boolean) => void;
}

/**
 * View de histórico de versões de uma ficha técnica
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {HistoricoFichaTecnicaPropType} props
 */
function HistoricoVersoes(props: HistoricoFichaTecnicaPropType): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { versoes, setDiagrama, setShowDiagrama, hideFab } = props;

  const [key, setKey] = useState<number>(0);
  const [selected, setSelected] = useState<FichaTecnica>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const tableData = useMemo(() => {
    return versoes.sort((a, b) => a.nrVersao >= b.nrVersao ? -1 : 1);
  }, [versoes]);

  function handleClickDetails() {
    if (!selected && selectedIndex === -1) return;

    let diagrama = '';
    const compare = selected ?? versoes[selectedIndex];
    const etapas = [...compare.produtoEtapaList ?? []];

    etapas?.forEach(etapa => {
      if (etapa.produtoEtapasPredecessoras.length) {
        etapa.produtoEtapasPredecessoras.forEach(etapaPredecessora => {
          if (etapaPredecessora.stRegistro !== 2) {
            diagrama += `${etapaPredecessora.idProdutoEtapa}
          -->${etapa.idProdutoEtapa}(<div>${etapa.etapa?.nmEtapa}</div>);`;
          }
        });
      } else {
        diagrama += `${etapa.idProdutoEtapa}(<div>${etapa.etapa?.nmEtapa}</div>);`;
      }
    });

    setDiagrama(diagrama);
    setShowDetails(true);
    hideFab(true);
    setSelected(compare);
  }

  useEffect(() => {
    setShowDiagrama(showDetails);
  }, [showDetails, setShowDiagrama]);

  useEffect(() => {
    if (selectedIndex > -1) setSelected(tableData[selectedIndex]);
    else setSelected(undefined);

    setKey(Math.random());
  }, [selectedIndex, tableData]);

  useKeyboardControls([
    {
      keys: ['Tab'],
      onPress: () => {
        const newIndex = selectedIndex + 1;

        if (newIndex < tableData.length) setSelectedIndex(newIndex);
        else setSelectedIndex(0);
      }
    },
    {
      keys: ['Enter'],
      onPress: handleClickDetails
    }
  ], !showDetails, false, true);

  const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);
  useVerificaDados({
    funcaoPrincipalProps: { dirty: contextProps.principalTabHasUnsavedData },
    handleSubmit: contextProps.principalFormSubmit,
    ignoreTabChange: true
  });

  return !showDetails ? (
    <section id='histórico' className={classes.sectionTable}>
      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete={false}
        customButtons={[
          <ButtonGrid
            icon={<Search color='secondary' />}
            backgroundColor={theme.palette.primary.main}
            disabledColor={theme.palette.grey['100']}
            onClick={handleClickDetails}
            disabled={!Boolean(selected || selectedIndex !== -1)}
          />
        ]}
      />

      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<FichaTecnica>
          key={key}
          initialSelected={selected}
          columns={[
            {
              field: 'nrVersao',
              headerName: 'Versão',
              col: 2
            },
            {
              field: 'dtInicio',
              headerName: 'Data Início',
              col: 2,
              valueGetter: (node) => moment(node.data.dtInicio).parseZone().format('DD/MM/YYYY')
            },
            {
              field: 'dtTermino',
              headerName: 'Data Término',
              col: 2,
              valueGetter: (node) => moment(node.data.dtTermino).parseZone().format('DD/MM/YYYY')
            }
          ]}
          data={tableData}
          rowsPerPageEnabled={false}
          showPagination={false}
          onSelectRow={setSelected}
        />
      </div>
    </section>
  )
    : <DetalhesVersao versao={selected} />;
}

export default HistoricoVersoes;