import moment from 'moment';
import UploadFichaTecnicaApi from '../resources/api';
import IconDownload from 'root-icons/download-solid.svg';
import { ListingScreen, Swal as SwalModal } from 'root-components';
import { ImportacaoFichaTecnica } from '../model/upload-ficha-tecnica';

// enums
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import StatusUploadEnum from 'root-cadastros/uploadproduto/enumerations/status-upload-enum';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';

/**
 * Listagem de Uploads de Produto
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>  
 */
function UploadFichaTecnicaListagem(): JSX.Element {
    const api = new UploadFichaTecnicaApi();

    async function onClickDownload(selected?: ImportacaoFichaTecnica) {
        if (!selected) return;

        try {
            const anchor = document.createElement('a');
            anchor.href = selected.dsBucket || '';
            anchor.download = 'upload';
            anchor.click();

            SwalModal({
                cancelButtonText: 'Ok',
                title: 'Download Realizado!',
                text: 'Download realizado com sucesso!',
                icon: 'success',
            });
        } catch {
            SwalModal({
                cancelButtonText: 'Ok',
                title: 'Erro',
                text: 'Erro ao fazer o download!',
                icon: 'error',
            });
        }
    }
    return (
        <ListingScreen<ImportacaoFichaTecnica>
            title='Upload - Cadastro de Ficha Técnica'
            dateFilters={[]}
            screenStaticParams='tpImportacao:1'
            enumFilters={['stImportacao']}
            filtersDefinitions={[
                {
                    field: 'dhImportacaoInicio',
                    label: 'Início de Importação',
                    tableColumnSize: 2,
                    type: TipoFiltroEnum.DATE,
                    hideFilter: true,
                    otherColProps: {
                        valueFormatter: (node) => node.data.dhImportacaoInicio ? moment(node.data.dhImportacaoInicio).format('DD/MM/YYYY - HH:mm') : '-'
                    }
                },
                {
                    field: 'dhImportacaoTermino',
                    label: 'Término de Importação',
                    tableColumnSize: 9,
                    type: TipoFiltroEnum.DATE,
                    hideFilter: true,
                    otherColProps: {
                        valueFormatter: (node) => node.data.dhImportacaoTermino ? moment(node.data.dhImportacaoTermino).format('DD/MM/YYYY - HH:mm') : '-'
                    }
                },
                {
                    field: 'stImportacao',
                    label: 'Status',
                    tableColumnSize: 1,
                    type: TipoFiltroEnum.ENUM,
                    enumOptions: [
                        { label: 'Em importação', value: StatusUploadEnum.EM_IMPORTACAO },
                        { label: 'Finalizado', value: StatusUploadEnum.FINALIZDO },
                        { label: 'Finalizado com Erro', value: StatusUploadEnum.FINALIZADO_ERRO },
                    ]
                },
            ]}
            api={api}
            formScreenUrl='/novo'
            orderField='stImportacao'
            id='idImportacao'
            screenKey={ScreensKeysEnum.UPLOAD_FICHATECNICA}
            tableProps={{
                showDelete: false,
                showEdit: false,
                customButtons: [{
                    enableWithSelected: true,
                    icon: <img src={IconDownload} alt='download' style={{ height: 21, width: 21, color: '#FFF !important' }} />,
                    onClick: onClickDownload
                }]
            }}
        />
    );
};

export default UploadFichaTecnicaListagem;