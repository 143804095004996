import NcmModel from 'root-models/fiscal/ncm';
import GenericApi from '../generic-api';
/**
 * API de ncm
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 * @extends {FISCALAPI}
 */
class NcmAPI extends GenericApi<NcmModel> {
  /**
  * Injeta a instância do axios com o endpoint base referente a cest
  */
  public constructor() {
    super({ baseEndpoint: 'ncm' });
  }
}

export default NcmAPI;