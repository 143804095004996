import React, { useState } from "react";
import * as Yup from 'yup';
import { Swal } from 'root-components';
import { Grid, useTheme } from "@mui/material";
import { FormikProps, useFormik } from "formik";
import Form, { FormFieldTypeEnum } from "root-components/form/form";
import SituacaoRegistroEnum from "root-enumerations/situacao-registro";
import EditableTable, { ColumnDefinition } from "root-components/editabletable/editable-table";
import ReferenciaNutricionalAPI from "../../referencianutricional/resources/referencianutricional";

// Models
import FichaTecnica from "../models/fichatecnica";
import MatrizNutricional from "../models/matriznutricional";
import ReferenciaMatrizNutricional from "../models/referenciamatriznutricional";

// Utils
import { useStyles } from "root-views/app.styles";
import { useVerificaDados } from "root-components";
import { useComponentDidMount } from "@bubotech/sumora-react-components/lib/utils/hooks";

export type NutricionalFichaTecnicaPropType = {
	form: FormikProps<FichaTecnica>;
	setGlobalDirty: (dirty: boolean) => void;
};

/**
 * Aba Nutricional da Ficha Técnica
 * 
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 * @author Marcos Davi <marcos.dav@kepha.com.br>
 * @param {NutricionalFichaTecnicaPropType} props
 */
function NutricionalFichaTecnica(props: NutricionalFichaTecnicaPropType): JSX.Element {
	const theme = useTheme();
	const classes = useStyles();
	const { form, setGlobalDirty } = props;

	const referenciaApi = new ReferenciaNutricionalAPI();

	const initialColumns: ColumnDefinition<MatrizNutricional>[] = [
		{
			header: 'Categoria Nutricional',
			value: (node) => node.categoriaNutricional?.nmCategoriaNutricional,
			xs: 2
		}
	];

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [tableColumns, setTableColumns] = useState<ColumnDefinition<MatrizNutricional>[]>(initialColumns);

	useVerificaDados({ funcaoPrincipalProps: { dirty: form.dirty }, handleSubmit: form.handleSubmit, ignoreTabChange: true });

	const { matrizNutricionalList } = form.values;
	const setFieldValue = form.setFieldValue;

	function handleRemoveColumn(columnIndex: number, columns: ColumnDefinition<MatrizNutricional>[]) {
		let notEmpty = matrizNutricionalList.find(category => category.referenciaNutricionalList[columnIndex - 1].vlNutricional);

		const removeColumn = () => {
			let newNutritionalMatrix = [...matrizNutricionalList];
			newNutritionalMatrix = newNutritionalMatrix.map(category => {
				let refIndex = category.referenciaNutricionalList.findIndex(ref => ref.referenciaNutricional?.nmReferenciaNutricional === columns[columnIndex].header);

				if (refIndex > -1) {
					category.referenciaNutricionalList.splice(refIndex, 1);
					if (!category.referenciaNutricionalList.length && form.values.idFichaTecnica)
						category.stRegistro = 0;
				}
				return category;
			})

			setFieldValue('matrizNutricionalList', newNutritionalMatrix);
			handleTableColumns();
		}

		if (notEmpty)
			Swal({
				icon: 'warning',
				showConfirmButton: true,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Remover',
				title: 'Atenção',
				text: 'Esta coluna está preenchida, deseja remover?'
			}).then(option => {
				if (option.dismiss) return;
				else removeColumn();
			})
		else removeColumn();
	}

	useComponentDidMount(handleTableColumns);

	function getDisabledRow(rowIndex: number) {
		if (form.values.idFichaTecnica && matrizNutricionalList[rowIndex].stRegistro) return false;
		
		return !Boolean(matrizNutricionalList[rowIndex].stRegistro)
	}

	function handleTableColumns() {
		let newColumns: ColumnDefinition<MatrizNutricional>[] = [...initialColumns];
		matrizNutricionalList.forEach((matrixRow) => {
			matrixRow.referenciaNutricionalList.forEach((ref) => {
				const headerName = ref.referenciaNutricional?.nmReferenciaNutricional;

				const existingColumn = newColumns.find(column => column.header === headerName);
				if (!existingColumn) {
					newColumns.push({
						header: headerName ?? '',
						xs: 1,
						editable: "masked",
						removableColumn: true,
						disabledRow: getDisabledRow,
						onRemoveColumn: handleRemoveColumn,
						value: (params: MatrizNutricional) => {
							const matchingRef = params.referenciaNutricionalList.find(refItem =>
								refItem.referenciaNutricional?.idReferenciaNutricional === ref.referenciaNutricional?.idReferenciaNutricional
							);

							if (matchingRef) return matchingRef.vlNutricional;
						},
						onChangeItem: (value, indexRow, item) => {
							const matchingRef: ReferenciaMatrizNutricional = item.referenciaNutricionalList.find((refItem: ReferenciaMatrizNutricional) =>
								refItem.referenciaNutricional?.idReferenciaNutricional === ref.referenciaNutricional?.idReferenciaNutricional
							);
							if (matchingRef) {
								matchingRef.vlNutricional = value;
								setFieldValue('matrizNutricionalList', matrizNutricionalList);
							} else {
								const newReference = {
									referenciaNutricional: {
										idReferenciaNutricional: ref.referenciaNutricional?.idReferenciaNutricional as string,
										nmReferenciaNutricional: headerName as string,
										dsReferenciaNutricional: ref.referenciaNutricional?.dsReferenciaNutricional as string,
									},
									vlNutricional: value,
									stRegistro: 0,
								};
								matrizNutricionalList[indexRow].referenciaNutricionalList.push(newReference);
								if (!matrizNutricionalList[indexRow].stRegistro) matrizNutricionalList[indexRow].stRegistro = 1;
								return newReference.vlNutricional;
							}
							setGlobalDirty(true);
						},
					});
				}
			});
		});

		setTableColumns(newColumns);
	}

	function handleSubmitReferencia() {
		if (matrizNutricionalList[0].referenciaNutricionalList.find(
			ref => ref.referenciaNutricional?.idReferenciaNutricional === formReferencia.values.referenciaNutricional?.idReferenciaNutricional)
		) {
			formReferencia.setFieldError('referenciaNutricional', 'Referência já cadastrada');
			return;
		}

		matrizNutricionalList.forEach(category => {
			category.referenciaNutricionalList.push({...formReferencia.values})
		})

		setGlobalDirty(true);
		setFieldValue('matrizNutricionalList', matrizNutricionalList);
		formReferencia.resetForm();
		handleTableColumns();
	}

	const formReferencia = useFormik<ReferenciaMatrizNutricional>({
		initialValues: {
			referenciaNutricional: undefined,
			vlNutricional: undefined,
			stRegistro: SituacaoRegistroEnum.CREATE,
		},
		validationSchema: Yup.object({
			referenciaNutricional: Yup.object().required('Campo obrigatório'),
		}),
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: handleSubmitReferencia
	});

	function getSwitchChecked(rowIndex: number) {
		return Boolean(matrizNutricionalList[rowIndex].stRegistro)
	}

	function handleChangeSwitch(rowIndex: number) {
		let newMatrix = [...matrizNutricionalList];
		const newValue = newMatrix[rowIndex].stRegistro ? 0 : 1;
		newMatrix[rowIndex].stRegistro = newValue;
		
		if (newValue === 0) 
			newMatrix[rowIndex].referenciaNutricionalList = [];
		
		setFieldValue('matrizNutricionalList', newMatrix);
		setGlobalDirty(true);
	}

	return (
		<>
			<section id='lista-composicao' className={classes.tabContent}>
				<Grid>
					<Form<FichaTecnica>
						form={form}
						saveOnEnter
						fields={[
							{
								fieldName: 'dsPorcaoEmbalagem',
								fieldType: FormFieldTypeEnum.TEXTFIELD,
								label: 'Porção por Embalagem',
								fieldSize: 2,
							},
							{
								fieldName: 'dsPorcao',
								fieldType: FormFieldTypeEnum.TEXTFIELD,
								label: 'Porção',
								fieldSize: 2,
							}
						]}
					/>
				</Grid>
				<div style={{ borderBottom: `1px solid ${theme.palette.grey['100']}`, marginBottom: 10 }}/>
				<Form<ReferenciaMatrizNutricional>
					form={formReferencia}
					saveOnEnter
					showAdd
					fields={[
						{
							fieldName: 'referenciaNutricional',
							fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
							label: 'Referência',
							fieldSize: 2,
							autoCompleteProps: {
								getLabel: opt => opt.nmReferenciaNutricional,
								getValue: opt => opt.idReferenciaNutricional,
								genericApi: referenciaApi,
								searchField: 'nmReferenciaNutricional',
								orderField: 'nmReferenciaNutricional',
								PaperSuggestionsProps: { className: 'suggestions-xs-2' }
							}
						}
					]}
				/>
				<div style={{ borderBottom: `1px solid ${theme.palette.grey['100']}` }}/>
				<div className={`${classes.containerDataTable} tabela`}>
					<EditableTable<MatrizNutricional>
						cols={tableColumns}
						data={form.values.matrizNutricionalList}
						selectedIndex={selectedIndex}
						showSwitch
						switchProps={{
							label: '',
							value: getSwitchChecked,
							onChangeValue: handleChangeSwitch
						}}
						setSelectedIndex={setSelectedIndex}
					/>
				</div>
			</section>
		</>
	);
}

export default NutricionalFichaTecnica;