export const tiposItem = [
  { codigo: 0, descricao: 'Mercadoria para Revenda' },
  { codigo: 1, descricao: 'Matéria-Prima' },
  { codigo: 2, descricao: 'Embalagem' },
  { codigo: 3, descricao: 'Produto em Processo' },
  { codigo: 4, descricao: 'Produto Acabado' },
  { codigo: 5, descricao: 'Subproduto' },
  { codigo: 6, descricao: 'Produto Intermediário' },
  { codigo: 7, descricao: 'Material de Uso e Consumo' },
  { codigo: 8, descricao: 'Ativo Imobilizado' },
  { codigo: 9, descricao: 'Serviços' },
  { codigo: 10, descricao: 'Outros Insumos' },
  { codigo: 99, descricao: 'Outras' },
]
