import { useStyles } from './readonly.styles';

export type ReadOnlyProps = {
    label: string;
    value: string;
    style?: React.CSSProperties;
    multiline?: boolean;
    maxLength?: number;
};
/**
 * Card simples para vizualização de um dado em string, somente leitura.
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 * @param {ReadOnlyProps} props
 * @param {string} props.label - Label do campo
 * @param {string} props.value - Valor do campo
 * @param {React.CSSProperties} [props.style] - Estilo personalizado do componente
 * @param {boolean} [props.multiline] - Se o campo é de multiplas linhas
 * @param {number} [props.maxLength] - Tamanho máximo do campo, +3 caracteres de reticências além do valor informado serão adicionados, caso o tamanho do texto ultrapasse o tamanho máximo
 * @returns {JSX.Element}
 */
export default function ReadOnly(props: ReadOnlyProps): JSX.Element {
    const { label, value, style, multiline, maxLength } = props;
    const classes = useStyles(props);

    const finalValue = maxLength ? value.length > maxLength ? value.substring(0, maxLength) + '...' : value : value;

    function getStyles(): React.CSSProperties | undefined {
        if (label === ' ') {
            return {
                justifyContent: 'center',
                paddingTop: 3
            };
        }
        if (multiline) {
            return {
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                paddingLeft: 2,
                paddingRight: 2
            };
        }
        return undefined;
    }

    return (
        <div className={classes.container} style={style}>
            <label className={classes.title}>{label !== '' ? label : '-'}</label>
            <label className={classes.value} style={getStyles()}>
                {finalValue !== "" ? finalValue : '-'}
            </label>
        </div>
    );
}