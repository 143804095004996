import CodigoServicos from 'root-models/fiscal/codigo-servico';
import FiscalAPI from './api-fiscal';

/**
 * API de codigo de servicos
 *
 * @author Felipe Shimada <felipe.shimada@kepha.com.br>
 */
class InserirCodigoServicosApi extends FiscalAPI<CodigoServicos> {
    /**
    * Injeta a instância do axios com o endpoint base referente a codigo-servicos
    */
    public constructor() {
        super('cadastro-codigo-servicos');
    }
}

export default InserirCodigoServicosApi;
