import { AutoComplete, MaskedTextField, Select, Switch, TextField } from '@bubotech/sumora-react-components/lib';
import { MaskTypeEnum } from '@bubotech/sumora-react-components/lib/maskedtextfield';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { FastField, FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';
import ContextActions from 'root-states/actions/context-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { tiposItem } from 'root-utils/tipo-item';
import { useStyles } from 'root-views/app.styles';

// Models
import Marca from '../../marca/model/marca';
import CorProduto from '../../corproduto/model/corproduto';
import { EditarProdutoFormikValuesType } from './editar-produto';
import UnidadeMedida from '../../unidademedida/model/unidade-medida';

// APIs
import TipoItemEnum from 'root-enumerations/tipo-item-enum';
import MarcaAPI from 'root-resources/api/marca-api';
import CorProdutoAPI from '../../corproduto/resource/corproduto';
import UnidadeMedidaAPI from '../../unidademedida/resources/unidademedida';

const Fast = FastField as any;

export type EditarDadosProdutoPropType = {
  onChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarProdutoFormikValuesType;
  errors: FormikErrors<EditarProdutoFormikValuesType>;
  touched: FormikTouched<EditarProdutoFormikValuesType>;
  superHandleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  superDirty: boolean;
};

/**
 * View de edição de dados de um produto
 *
 * @author davi takayama <marcos.davi@kepha.com.br>
 * @param {EditarDadosProdutoPropType} props
 */

function EditarDadosProduto(props: EditarDadosProdutoPropType): JSX.Element {
  const classes = useStyles();
  const [key, setKey] = useState(0);
  const { setFieldValue, handleBlur, values, errors, touched, superHandleSubmit, superDirty } = props;

  useEffect(() => {
    if (values.nrComprimento && values.nrAltura && values.nrLargura)
      setFieldValue('dsVolume', (parseFloat(values.nrComprimento) / 1000) * (parseFloat(values.nrAltura) / 1000) * (parseFloat(values.nrLargura) / 1000));
  }, [values.nrComprimento, values.nrAltura, values.nrLargura, setFieldValue, values.dsVolume]);

  const marcaApi = new MarcaAPI();
  const corProdutoApi = new CorProdutoAPI();
  const unidadeMedidaApi = new UnidadeMedidaAPI();


  useEffect(() => setKey(Math.random()), [values]);

  const handleChangeTipoItem = (e: any) => {
    setFieldValue('tpItem', e.codigo);
    if (e.codigo === 9) {
      setFieldValue('blPossuiVaricao', false);
      setFieldValue('blMovimentaEstoque', false);
      setFieldValue('blPossuiComposicao', false);
    }
  };

  const handleChangeField = (field: string, fieldId: string, selected: any | null) => {
    if (selected) {
      setFieldValue(field, selected);
      setFieldValue(fieldId, selected[fieldId]);
      setKey(Math.random());
    } else {
      setFieldValue(field, null);
      setFieldValue(fieldId, null);
      setKey(Math.random());
    }

    switch (field) {
      case 'grupoProduto':
        handleChangeField('subgrupoProduto', 'idSubgrupoProduto', null);
        break;
      case 'subgrupoProduto':
        selected && setFieldValue('idSubgrupoProduto', selected['idSubGrupoProduto']);
        handleChangeField('secaoProduto', 'idSecaoProduto', null);
        break;
      case 'secaoProduto':
        handleChangeField('divisaoProduto', 'idDivisaoProduto', null);
        break;
      default:
        break;
    }
  };

  // Definindo que existem dados não salvos no formulário principal
  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  useEffect(() => {
    contextActions.principalTabHasUnsavedData(superDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superDirty]);
  useVerificaDados({ funcaoPrincipalProps: { dirty: superDirty }, handleSubmit: superHandleSubmit, ignoreTabChange: true });

  return (
    <section id='dados-produto' style={{ overflowY: 'auto', paddingBottom: 40 }}>
      <Grid container className={classes.tabContent}>
        <Grid item xs={3} className={classes.gridCell}>
          <Select<any>
            label={'Tipo de Item'}
            variant='standard'
            name='tpItem'
            onBlur={handleBlur}
            helperText={Boolean(touched.tpItem && errors.tpItem) ? errors.tpItem : ''}
            error={Boolean(touched.tpItem && errors.tpItem)}
            value={values.tpItem}
            options={tiposItem}
            placeholder='Selecione...'
            getOptionLabel={opt => opt.descricao}
            getOptionValue={(opt) => opt.codigo}
            onChangeValue={handleChangeTipoItem}
            HelperTextProps={{ variant: 'standard' }}
          />
        </Grid>
        <Grid item xs={5} className={classes.gridCell}>
          <Fast name='nmProduto'>
            {({ field }: { field: any, form: any; }) => (
              <TextField
                label={'Nome'}
                inputProps={{ maxLength: 160 }}
                error={Boolean(touched.nmProduto && errors.nmProduto)}
                helperText={Boolean(touched.nmProduto && errors.nmProduto) ? errors.nmProduto : ''}
                {...field}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} style={{ height: 76 }} className={classes.gridCell}>
          <Fast name='cdProduto' >
            {({ field, form }: { field: any, form: any; }) => (
              <TextField
                label={'Cód. Item'}
                inputProps={{ maxLength: 20 }}
                error={Boolean(form.touched.cdProduto && form.errors.cdProduto)}
                helperText={Boolean(form.touched.cdProduto && form.errors.cdProduto) ? form.errors.cdProduto : ''}
                {...field}
              />
            )}
          </Fast>
        </Grid>

        <Grid item xs={2} style={{ height: 76 }}>
          <Select<any>
            label={'Status'}
            variant='standard'
            name='stProduto'
            onBlur={handleBlur}
            helperText={Boolean(touched.stProduto && errors.stProduto) ? errors.stProduto : ''}
            error={Boolean(touched.stProduto && errors.stProduto)}
            errorText={Boolean(touched.stProduto && errors.stProduto) ? errors.stProduto : ''}
            value={values.stProduto}
            placeholder='Selecione...'
            options={[
              { label: 'Inativo', value: 0 },
              { label: 'Ativo', value: 1 },
              { label: 'Descontinuado', value: 2 }
            ]}
            getOptionLabel={(opt) => opt.label}
            getOptionValue={(opt) => opt.value}
            onChangeValue={(e) => setFieldValue('stProduto', e.value)}
            HelperTextProps={{ variant: 'standard' }}
          />
        </Grid>

        <Grid item xs={3} className={classes.gridCell}>
          <Fast name="dsNomeEtiqueta">
            {({ field }: { field: any, form: any; }) => (
              <TextField
                label={'Ingrediente na Etiqueta'}
                {...field}
                helperText={Boolean(touched.dsNomeEtiqueta && errors.dsNomeEtiqueta) ? errors.dsNomeEtiqueta : ''}
                error={Boolean(touched.dsNomeEtiqueta && errors.dsNomeEtiqueta)}
                errorText={Boolean(touched.dsNomeEtiqueta && errors.dsNomeEtiqueta) ? errors.dsNomeEtiqueta : ''}
              />
            )}
          </Fast>
        </Grid>

        <Grid item xs={9} >
          <Fast name='dsProduto'>
            {({ field }: { field: any, form: any; }) => (
              <TextField
                label={'Descrição Técnica do Item'}
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 200 }}
                {...field}
              />
            )}
          </Fast>
        </Grid>

        <Grid item xs={3} className={classes.gridCell}>
          <AutoComplete<Marca>
            label={'Marca'}
            key={key}
            variant='standard'
            name='idMarca'
            disabled={values.tpItem === TipoItemEnum.SERVICOS}
            onBlur={handleBlur}
            helperText={Boolean(touched.idMarca && errors.idMarca) ? errors.idMarca : ''}
            error={Boolean(touched.idMarca && errors.idMarca)}
            errorText={Boolean(touched.idMarca && errors.idMarca) ? errors.idMarca : ''}
            value={values.marca}
            genericApi={marcaApi}
            searchField='nmMarca'
            orderField='nmMarca'
            staticSearchParams=',stMarca:1'
            getLabel={opt => opt.nmMarca}
            getValue={(opt) => opt.idMarca}
            onChangeValue={(selected) => handleChangeField('marca', 'idMarca', selected)}
          />
        </Grid>
        <Grid item xs={3} className={classes.gridCell}>
          <Fast name='dsModelo'>
            {({ field }: { field: any; }) => (
              <TextField
                label={'Modelo'}
                {...field}
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 30 }}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={3} className={classes.gridCell}>
          <Fast name='nrTamanho'>
            {({ field }: { field: any; }) => (
              <TextField
                label={'Tamanho'}
                variant='standard'
                inputProps={{ maxLength: 3 }}
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                {...field}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={3} style={{ height: 76 }}>
          <AutoComplete<CorProduto>
            label={'Cor'}
            key={key}
            variant='standard'
            name='idCorProduto'
            disabled={values.tpItem === TipoItemEnum.SERVICOS}
            onBlur={handleBlur}
            helperText={Boolean(touched.idCorProduto && errors.idCorProduto) ? `${errors.idCorProduto}` : ''}
            error={Boolean(touched.idCorProduto && errors.idCorProduto)}
            errorText={Boolean(touched.idCorProduto && errors.idCorProduto) ? `${errors.idCorProduto}` : ''}
            value={values.corProduto}
            genericApi={corProdutoApi}
            searchField='nmCorProduto'
            orderField='nmCorProduto'
            staticSearchParams=',stCorProduto:1'
            getLabel={opt => opt.nmCorProduto}
            getValue={(opt) => opt.idCorProduto}
            onChangeValue={(selected) => handleChangeField('corProduto', 'idCorProduto', selected)}
          />
        </Grid>
        <Grid item xs={1} className={classes.gridCell}>
          <AutoComplete<UnidadeMedida>
            label={'Un. de Venda'}
            key={key}
            variant='standard'
            name='idUnidadeMedidaVenda'
            onBlur={handleBlur}
            helperText={Boolean(touched.idUnidadeMedidaVenda && errors.idUnidadeMedidaVenda) ? errors.idUnidadeMedidaVenda : ''}
            error={Boolean(touched.idUnidadeMedidaVenda && errors.idUnidadeMedidaVenda)}
            errorText={Boolean(touched.idUnidadeMedidaVenda && errors.idUnidadeMedidaVenda) ? errors.idUnidadeMedidaVenda : ''}
            value={values.unidadeMedidaVenda}
            orderField='dsUnidadeMedida'
            searchField='dsUnidadeMedida'
            genericApi={unidadeMedidaApi}
            staticSearchParams=',stUnidadeMedida:1'
            getLabel={opt => opt.dsUnidadeMedida}
            getValue={opt => opt.idUnidadeMedida}
            onChangeValue={(selected) => {
              setFieldValue('unidadeMedidaVenda', selected);
              setFieldValue('idUnidadeMedidaVenda', selected?.idUnidadeMedida);
            }}
            PaperSuggestionsProps={{
              className: 'suggestions-xs-1'
            }}
          />
        </Grid>
        <Grid item xs={1} className={classes.gridCell}>
          <AutoComplete<UnidadeMedida>
            label={'Un. de Composição'}
            key={key}
            variant='standard'
            name='idUnidadeMedidaComposicao'
            onBlur={handleBlur}
            helperText={Boolean(touched.idUnidadeMedidaComposicao && errors.idUnidadeMedidaComposicao) ? errors.idUnidadeMedidaComposicao : ''}
            error={Boolean(touched.idUnidadeMedidaComposicao && errors.idUnidadeMedidaComposicao)}
            errorText={Boolean(touched.idUnidadeMedidaComposicao && errors.idUnidadeMedidaComposicao) ? errors.idUnidadeMedidaComposicao : ''}
            value={values.unidadeMedidaComposicao}
            orderField='dsUnidadeMedida'
            searchField='dsUnidadeMedida'
            genericApi={unidadeMedidaApi}
            staticSearchParams=',stUnidadeMedida:1'
            getLabel={opt => opt.dsUnidadeMedida}
            getValue={opt => opt.idUnidadeMedida}
            onChangeValue={(selected) => handleChangeField('unidadeMedidaComposicao', 'idUnidadeMedidaComposicao', selected)}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrReferenciaComposicao'>
            {({ field, form }: { field: any, form: any; }) => (
              <MaskedTextField
                label={'Qtde. de Composição'}
                {...field}
                decimalScale={3}
                typeMask={MaskTypeEnum.MONEY}
                inputProps={{ maxLength: 8 }}
                value={form.values.nrReferenciaComposicao ?? ''}
                onChange={(e) => {
                  form.setFieldValue(e.target.name, e.target.value);
                }}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={1} className={classes.gridCell}>
          <AutoComplete<UnidadeMedida>
            label={'Un. de Embalamento'}
            key={key}
            variant='standard'
            name='idUnidadeMedidaEmbalamento'
            onBlur={handleBlur}
            helperText={Boolean(touched.idUnidadeMedidaEmbalamento && errors.idUnidadeMedidaEmbalamento) ? errors.idUnidadeMedidaEmbalamento : ''}
            error={Boolean(touched.idUnidadeMedidaEmbalamento && errors.idUnidadeMedidaEmbalamento)}
            errorText={Boolean(touched.idUnidadeMedidaEmbalamento && errors.idUnidadeMedidaEmbalamento) ? errors.idUnidadeMedidaEmbalamento : ''}
            value={values.unidadeMedidaEmbalamento}
            orderField='dsUnidadeMedida'
            searchField='dsUnidadeMedida'
            genericApi={unidadeMedidaApi}
            staticSearchParams=',stUnidadeMedida:1'
            getLabel={opt => opt.dsUnidadeMedida}
            getValue={opt => opt.dsUnidadeMedida}
            onChangeValue={(selected) => handleChangeField('unidadeMedidaEmbalamento', 'idUnidadeMedidaEmbalamento', selected)}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrReferenciaEmbalamento' >
            {({ field, form }: { field: any, form: any; }) => (
              <MaskedTextField
                label={'Qtde. de Embalamento'}
                {...field}
                decimalScale={3}
                typeMask={MaskTypeEnum.MONEY}
                inputProps={{ maxLength: 8 }}
                value={form.values.nrReferenciaEmbalamento ?? ''}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={1} className={classes.gridCell}>
          <AutoComplete<UnidadeMedida>
            label={'Un. de Entrega'}
            key={key}
            variant='standard'
            name='idUnidadeMedidaEntrega'
            onBlur={handleBlur}
            helperText={Boolean(touched.idUnidadeMedidaEntrega && errors.idUnidadeMedidaEntrega) ? errors.idUnidadeMedidaEntrega : ''}
            error={Boolean(touched.idUnidadeMedidaEntrega && errors.idUnidadeMedidaEntrega)}
            errorText={Boolean(touched.idUnidadeMedidaEntrega && errors.idUnidadeMedidaEntrega) ? errors.idUnidadeMedidaEntrega : ''}
            value={values.unidadeMedidaEntrega}
            orderField='dsUnidadeMedida'
            searchField='dsUnidadeMedida'
            genericApi={unidadeMedidaApi}
            staticSearchParams=',stUnidadeMedida:1'
            getLabel={opt => opt.dsUnidadeMedida}
            getValue={opt => opt.dsUnidadeMedida}
            onChangeValue={(selected) => handleChangeField('unidadeMedidaEntrega', 'idUnidadeMedidaEntrega', selected)}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrReferenciaEntrega'>
            {({ field, form }: { field: any, form: any; }) => (
              <MaskedTextField
                label={'Qtde. de Entrega'}
                {...field}
                decimalScale={3}
                typeMask={MaskTypeEnum.MONEY}
                inputProps={{ maxLength: 8 }}
                value={form.values.nrReferenciaEntrega ?? ''}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} style={{ height: 76 }}>
          <Fast name='nrQtdeMinimaPrd'>
            {({ field, form }: { field: any, form: any; }) => (
              <MaskedTextField
                label={'Qtde. de Produção'}
                {...field}
                decimalScale={3}
                typeMask={MaskTypeEnum.MONEY}
                inputProps={{ maxLength: 8 }}
                value={form.values.nrQtdeMinimaPrd ?? ''}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
              />
            )}
          </Fast>
        </Grid>

        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrPesoBruto'>
            {({ field, form }: { field: any, form: any; }) => (
              <MaskedTextField
                label={'Peso Bruto'}
                {...field}
                decimalScale={3}
                typeMask={MaskTypeEnum.MONEY}
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 8 }}
                value={form.values.nrPesoBruto ?? ''}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrPesoLiquido'>
            {({ field, form }: { field: any, form: any; }) => (
              <MaskedTextField
                label={'Peso Líquido'}
                {...field}
                decimalScale={3}
                typeMask={MaskTypeEnum.MONEY}
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 8 }}
                value={form.values.nrPesoLiquido ?? ''}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrAltura'>
            {({ field, form }: { field: any, form: any; }) => (
              <TextField
                label={'Altura (mm)'}
                type='number'
                {...field}
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrLargura' >
            {({ field, form }: { field: any, form: any; }) => (
              <TextField
                label={'Largura (mm)'}
                {...field}
                type='number'
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} className={classes.gridCell}>
          <Fast name='nrComprimento'>
            {({ field, form }: { field: any, form: any; }) => (
              <TextField
                label={'Comprimento (mm)'}
                {...field}
                type='number'
                disabled={values.tpItem === TipoItemEnum.SERVICOS}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))}
              />
            )}
          </Fast>
        </Grid>
        <Grid item xs={2} style={{ height: 76 }}>
          <TextField
            name='dsVolume'
            label={'Volume (m³)'}
            value={values?.dsVolume ? parseFloat(values.dsVolume).toFixed(5) : ''}
            disabled={values.tpItem === TipoItemEnum.SERVICOS}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
          />
        </Grid>

        <Grid item xs={12} style={{ height: 131 }}>
          <Fast name='dsObservacoes'>
            {({ field, form }: { field: any, form: any; }) => (
              <TextField
                label={'Observações'}
                multiline
                inputProps={{ maxLength: 80 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography variant='subtitle1'>{form.values.dsObservacoes?.length ?? '0'}/80</Typography>
                    </InputAdornment>
                  )
                }}
                {...field}
              />
            )}
          </Fast>
        </Grid>

        <Grid item xs={2} style={{ height: 76 }}>
          <Switch
            label={'Movimenta estoque?'}
            name='blMovimentaEstoque'
            disabled={values.tpItem === TipoItemEnum.SERVICOS}
            checked={values.blMovimentaEstoque}
            onBlur={handleBlur}
            onChange={() => setFieldValue('blMovimentaEstoque', !values.blMovimentaEstoque)}
          />
        </Grid>

        <Grid item xs={2} style={{ height: 76 }}>
          <Switch
            label={'Habilitar nota fiscal?'}
            name='blNotaFiscal'
            checked={values.blNotaFiscal}
            onBlur={handleBlur}
            onChange={() => { setFieldValue('blNotaFiscal', !values.blNotaFiscal); }}
          />
        </Grid>

      </Grid>
    </section>
  );
}

export default EditarDadosProduto;
