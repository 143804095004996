
/**
 * Mapa de telas do sistema para redirect através do autocomplete
 * 
 * @author Marcos Davi
 */
const ScreensMap = {
  'cdProduto': '/cadastros/produto',
  'nmProduto': '/cadastros/produto',
  'nmEtapa': '/cadastros/etapa',
  'sgUnidadeMedida': '/cadastros/unidade-medida',
  'dsUnidadeMedida': '/cadastros/unidade-medida',
}

export default ScreensMap;