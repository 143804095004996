import { useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import ContextActions from 'root-states/actions/context-actions';
import { ContextStateType } from 'root-states/reducers/context-reducer';

interface KeyControl {
  keys: string[];
  previousKeys?: string[];
  onPress: () => void;
  childForm?: () => void;
}

/**
 * Hook para controlar teclado do usuário
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {KeyControl} controls Lista de teclas e funções relacionadas
 * @param {Boolean} active Ativa ou desativa os comandos (Padrão: true)
 * @param {Boolean} nextTab Formulário possui mais uma aba
 * @param {Boolean} previousTab Formulário possui uma aba anterior
 * @param childForm Aba possui uma aba interna com formulário
 */
export function useKeyboardControls(
  controls: KeyControl[], 
  active: boolean = true, 
  nextTab: boolean = false, 
  previousTab: boolean = false,
  childForm?: () => void,
) {
  const lastKeyPressed = useRef<string>('');

  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {    
      if (childForm && lastKeyPressed.current === 'Shift' && event.key === 'Enter') {
        childForm();
        return;
      }

      if (nextTab && lastKeyPressed.current === 'Shift' && event.key === 'Tab') {
        event.preventDefault();
        contextActions.tryChangeTab(contextProps.selectedTab + 1);
        return;
      }

      if (previousTab && event.key === 'Escape') {
        event.preventDefault();
        contextActions.tryChangeTab(contextProps.selectedTab - 1);
        return;
      }

      controls.forEach(currentControl => {
        if (currentControl.previousKeys?.includes(lastKeyPressed.current) && currentControl.keys.includes(lastKeyPressed.current)) {
          event.preventDefault();
          currentControl.onPress();
        }

        if (currentControl.keys.includes(event.key)) {
          event.preventDefault();
          currentControl.onPress();
        }
      })

      lastKeyPressed.current = event.key;
    }

    active && document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, [active, controls, nextTab, previousTab])
}