
import GenericApi from 'root-resources/generic-api';
import UnidadeMedida from '../model/unidade-medida';


/**
 * API de unidades de medida
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @extends {RootApi}
 */
class UnidadeMedidaAPI extends GenericApi<UnidadeMedida> {
  /**
   * Injeta a instância do axios com o endpoint base referente as Unidades de Medida
   */
  public constructor() {
    super({ baseEndpoint: 'unidades-medidas' })
  }
}

export default UnidadeMedidaAPI;