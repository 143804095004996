import React, { useState } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { Form } from 'root-components';
import { useStyles } from 'root-views/app.styles';
import { SelectTable } from '@bubotech/sumora-react-components';
import { FormFieldTypeEnum } from 'root-components/form/form';
import { SelectTableTypes } from '@bubotech/sumora-react-components/lib/selecttable';

// Models
import Etapa from '../../produto/model/etapa';
import FichaTecnica from '../models/fichatecnica';
import ItemComposicao from '../models/itemcomposicao';
import EtapaFichaTecnica from '../models/etapafichatecnica';

interface DetalhesVersaoPropType {
  versao?: FichaTecnica;
}

/**
 * View de detalhes de uma versão da ficha técnica
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {DetalhesVersaoPropType} props
 */
function DetalhesVersao(props: DetalhesVersaoPropType): JSX.Element {
  const classes = useStyles();
  const { versao } = props;

  const [selectedComposicao, setSelectedComposicao] = useState<ItemComposicao>();
  
  const composicoes = versao?.produtoEtapaList.map(etapa => ({...etapa, selected: false, data: etapa.produtoComposicoes}));
  const etapas = versao?.produtoEtapaList.map(composicao => ({...composicao, selected: false, data: composicao.produtoEtapasPredecessoras as EtapaFichaTecnica[]}));

  return (
    <section className={classes.tabContent}>
      <Form 
        form={{ values: versao } as FormikProps<FichaTecnica>}
        fields={[
          {
            fieldName: 'nrVersao',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 2,
            label: 'Versão',
          },
          {
            fieldName: 'dtInicio',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 2,
            label: 'Data Início',
            value: moment(versao?.dtInicio).parseZone().format('DD/MM/YYYY'),
          },
          {
            fieldName: 'dtTermino',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 2,
            label: 'Data Término',
            value: moment(versao?.dtTermino).parseZone().format('DD/MM/YYYY'),
          }
        ]}
      />
      <SelectTable<EtapaFichaTecnica, EtapaFichaTecnica>
        type={SelectTableTypes.EXPANDABLE}
        data={etapas ?? []}
        cells={[
          {
            header: 'Etapa do Processo',
            xs: 2,
            field: 'etapa.nmEtapa',
            valueGetter: item => item?.etapa?.nmEtapa ?? '-',
          },
          {
            header: 'Descrição',
            xs: 4,
            field: 'dsEtapa'
          },
          {
            header: 'Tipo Etapa',
            xs: 1,
            field: 'tpEtapa',
            valueGetter: item => ['Inicial', 'Sequencial', 'Concorrente', 'Final'][item.tpEtapa ?? 0]
          },
          {
            header: 'Etapa Anterior',
            xs: 2,
            field: '',
            valueGetter: () => '-'
          },
          {
            header: 'Descrição',
            xs: 3,
            field: '',
            valueGetter: () => '-'
          },
        ]}
        expandCells={[
          {
            field: '',
            xs: 7
          },
          {
            field: '',
            xs: 2,
            valueGetter: item => item.etapa?.nmEtapa ?? '-'
          },
          {
            field: '',
            xs: 3,
            valueGetter: item => item.dsEtapa ?? '-'
          },
        ]}
      />
      <Grid item xs={12} className={classes.container}>
        Composição
      </Grid>
      <Form 
        form={{ values: selectedComposicao } as FormikProps<ItemComposicao>}
        fields={[
          {
            fieldName: 'produtoEtapa.etapa.nmEtapa',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 3,
            label: 'Etapa do Processo',
          },
          {
            fieldName: 'produto.cdProduto',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 2,
            label: 'Cód. Produto'
          },
          {
            fieldName: 'produto.nmProduto',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 5,
            label: 'Produto'
          },
          {
            fieldName: 'nrQuantidade',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 1,
            label: 'Quantidade',
            value: selectedComposicao?.nrQuantidade ? new Intl.NumberFormat('pt-br', { minimumFractionDigits: 8 }).format(selectedComposicao?.nrQuantidade as number) : '-'
          },
          {
            fieldName: 'unidadeMedida.sgUnidadeMedida',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 1,
            label: 'Un. Medida'
          },
          {
            fieldName: 'dsProdutoComposicao',
            fieldType: FormFieldTypeEnum.READ_ONLY,
            fieldSize: 12,
            label: 'Descrição'
          }
        ]}
      />
      <SelectTable<EtapaFichaTecnica, ItemComposicao>
        type={SelectTableTypes.EXPANDABLE}
        data={composicoes ?? []}
        onSelectRow={() => setSelectedComposicao(undefined)}
        onSelectRowItem={(rowItem, rowIndex) => setSelectedComposicao({
          ...rowItem,
          produtoEtapa: etapas ? 
            { 
              etapa: { nmEtapa: etapas[rowIndex].etapa?.nmEtapa }, 
              idProdutoEtapa: etapas[rowIndex].idProdutoEtapa 
            } as Etapa : 
            rowItem.produtoEtapa,
        })}
        cells={[
          {
            header: 'Etapa do Processo',
            xs: 2,
            field: 'etapa.nmEtapa',
            valueGetter: item => item?.etapa?.nmEtapa ?? '-',
          },
          {
            header: 'Produto',
            xs: 4,
            field: '',
            valueGetter: () => '-'
          },
          {
            header: 'Qtde.',
            xs: 1,
            field: '',
            valueGetter: () => '-'
          },
          {
            header: 'Un. Medida',
            xs: 1,
            field: '',
            valueGetter: () => '-'
          },
          {
            header: 'Descrição',
            xs: 4,
            field: '',
            valueGetter: () => '-'
          },
        ]}
        expandCells={[
          {
            field: '',
            xs: 2,
          },
          {
            field: '',
            xs: 4,
            valueGetter: item => item?.produto?.nmProduto ?? '-'
          },
          {
            field: '',
            xs: 1,
            valueGetter: item => new Intl.NumberFormat('pt-br', { minimumFractionDigits: 8 }).format(item?.nrQuantidade as number) ?? '-'
          },
          {
            field: '',
            xs: 1,
            valueGetter: item => item?.unidadeMedida?.sgUnidadeMedida ?? '-'
          },
          {
            field: '',
            xs: 4,
            valueGetter: item => item?.dsProdutoComposicao ?? '-'
          },
        ]}
      />
    </section>
  )
}

export default DetalhesVersao;