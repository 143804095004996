import GenericApi from 'root-resources/generic-api';
import Alergenicos from '../model/alergenos';

/**
 * API de Alergênicos/Alérgenos
 *
 * @author Carlos Bageston <carlos.bageston@kepha.com.br>
 * @extends {GenericApi}
 */
class AlergenicosAPI extends GenericApi<Alergenicos> {
  /**
  * Injeta a instância do axios com o endpoint base referente a Alergênicos/Alérgenos
  */
  public constructor() {
    super({ baseEndpoint: 'alergenico' });
  }
}

export default AlergenicosAPI;